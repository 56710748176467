<div class="insurance-expandcards insurance-expandcards-bg insurance-expand-wireframe">
  <div class="entitytemplates row">
    <div class="col-12" *ngIf="expandType == 74">
      <div class="insurance-expand-card-1 insurance-expand expandAttribut-7">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 75">
      <div class="insurance-expand-card-2 insurance-expand expandAttribut-5">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle-bold">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 76">
      <div class="insurance-expand-card-3 insurance-expand expandAttribut-8">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard">
          <ul>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="expandcard row">
            <div class="card-img col-8">
              <ul>
                <li class="card-logo">
                  <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                </li>
              </ul>
            </div>
            <div class="card-data col-4">
              <ul>
                <li class="card-subtitle-bold">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 77">
      <div class="insurance-expand-card-4 insurance-expand expandAttribut-21">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-title-black">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-medium">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="ul-steps">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 78">
      <div class="insurance-expand-card-5 insurance-expand expandAttribut-4">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-6">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>

              <li class="card-subtitle-bold">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-6">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 79">
      <div class="insurance-expand-card-6 insurance-expand expandAttribut-6">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-6">
            <ul>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 80">
      <div class="insurance-expand-card-7 insurance-expand expandAttribut-17">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="card-logo">
          <ul>
            <li class="card-icon">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-label">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-4">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="lisrgroup">
              <li class="listgroup-title">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-title">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data-box col-4">
            <ul>
              <li class="box-title">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-subtitle">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[5], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-subtitle">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-title-small">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 81">
      <div class="insurance-expand-card-8 insurance-expand expandAttribut-8">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps2">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps3">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps4">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="row-ul">
              <li class="card-steps5">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 82">
      <div class="insurance-expand-card-9 insurance-expand expandAttribut-19">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="ul-labels">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[12] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-9">
            <ul class="ul-labels">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <li class="card-title-small">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 83">
      <div class="insurance-expand-card-10 insurance-expand expandAttribut-16">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-5">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle clrblack">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 84">
      <div class="insurance-expand-card-11 insurance-expand expandAttribut-11">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-medium">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-medium">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large1">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 85">
      <div class="insurance-expand-card-12 insurance-expand expandAttribut-6">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>

              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle-bold">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title-large">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 86">
      <div class="insurance-expand-card-13 insurance-expand expandAttribut-12">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard">
          <ul>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="expandcard row">
            <div class="card-img col-8">
              <ul>
                <li class="card-logo">
                  <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                </li>
                <li class="card-label">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-data col-4">
              <ul>
                <li class="card-subtitle-bold">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-steps">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ul>
            <li class="linebar"></li>
          </ul>
          <div class="expandcard row align-items-end">
            <div class="card-img col-6">
              <ul>
                <li class="card-subtitle-bold">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="linebar linebaroverlay"></li>
                <li class="card-title-large">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-data col-6">
              <ul>
                <li class="card-title-large">
                  <ng-container
                    *ngIf="attributes[12]"
                    dynamicField
                    [formData]="attributes[12][attributes[12].attributeName]"
                    [group]="attributes[12][attributes[12].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 87">
      <div class="insurance-expand-card-14 insurance-expand expandAttribut-9">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-6">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>

              <li class="card-subtitle-bold">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-6">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <li class="card-title-medium">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard">
          <div class="card-steps row">
            <div class="col-4">
              <ul>
                <li class="card-title-steps">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4 linebaroverlay">
              <ul>
                <li class="card-title-steps">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li class="card-title-steps">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <li class="card-title-large">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 88">
      <div class="insurance-expand-card-15 insurance-expand expandAttribut-8">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-6">
            <ul>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-label-large">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 89">
      <div class="insurance-expand-card-16 insurance-expand expandAttribut-23">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="card-logo">
          <ul>
            <li class="card-icon">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-label">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-4">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="lisrgroup">
              <li class="listgroup-title">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-title">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data-box col-4">
            <ul>
              <li class="box-title">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-subtitle">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-subtitle">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="box-title-small">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row expandcard-space">
          <div class="card-data col-md-8 offset-md-4">
            <ul>
              <li class="card-title-bold">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="card-title-bold">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="lisrgroup">
              <li class="listgroup-title-bold">
                <ng-container
                  *ngIf="attributes[22]"
                  dynamicField
                  [formData]="attributes[22][attributes[22].attributeName]"
                  [group]="attributes[22][attributes[22].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle-large">
                <ng-container
                  *ngIf="attributes[23]"
                  dynamicField
                  [formData]="attributes[23][attributes[23].attributeName]"
                  [group]="attributes[23][attributes[23].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="listgroup-subtitle-large">
                <ng-container
                  *ngIf="attributes[24]"
                  dynamicField
                  [formData]="attributes[24][attributes[24].attributeName]"
                  [group]="attributes[24][attributes[24].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 90">
      <div class="insurance-expand-card-17 insurance-expand expandAttribut-24">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-title-black">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-medium">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="ul-steps">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="ul-space">
              <li class="linebar"></li>
              <li class="card-subtitle-large1">
                <ng-container
                  *ngIf="attributes[22]"
                  dynamicField
                  [formData]="attributes[22][attributes[22].attributeName]"
                  [group]="attributes[22][attributes[22].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large2">
                <ng-container
                  *ngIf="attributes[23]"
                  dynamicField
                  [formData]="attributes[23][attributes[23].attributeName]"
                  [group]="attributes[23][attributes[23].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large3">
                <ng-container
                  *ngIf="attributes[24]"
                  dynamicField
                  [formData]="attributes[24][attributes[24].attributeName]"
                  [group]="attributes[24][attributes[24].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 91">
      <div class="insurance-expand-card-18 insurance-expand expandAttribut-16">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps2">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps3">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps4">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="row-ul">
              <li class="card-steps5">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="row-ul-step">
              <li class="card-step75">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-step25">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="row-ul-step">
              <li class="card-step75">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-step25">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="row-ul-step">
              <li class="card-step75">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-step25">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="card-subtitle-para">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textarea">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 92">
      <div class="insurance-expand-card-19 insurance-expand expandAttribut-21">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="ul-labels">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-large">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[12] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-9">
            <ul class="ul-labels">
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <li class="card-title-small">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textarea">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 93">
      <div class="insurance-expand-card-20 insurance-expand expandAttribut-20">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-5">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle clrblack">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-steps1">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtite-bold">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textarea">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtite-bold">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textarea">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
