<ng-container *ngIf="cardDetails?.templateType == 330">
  <app-dashboard-ambulance-tracking
    [cardsData]="cardsData"
    (emitTransEvent)="recieveTransEmit($event)"
  ></app-dashboard-ambulance-tracking>
</ng-container>
<ng-container *ngIf="cardDetails?.templateType == '331'">
  <app-circular-template
    [circularData]="cardsData"
  ></app-circular-template>
</ng-container>
<ng-container *ngIf="isShowCardDetails">
  <div class="solution-box sol-blog">
    <div class="entity-wrap">
      <div class="entity-card" *ngFor="let ent of entityList" (click)="openEntity(ent)">
        <div class="ent-display-card">
          <img class="ent-img" src="../../../../assets/img/mylibrary/entity-default.png" alt="" />
        </div>
        <div class="ent-card-title">
          <h3 class="title" pTooltip="{{ ent.name }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
            <strong>{{ ent.name }}</strong>
          </h3>
          <!-- <h5 class="title">Records:</h5> -->
        </div>
        <div class="bookmark-dd-list modalPopupRight">
          <ul>
            <li (click)="publishtonsl(ent)">
              <span class="material-icons"> publish</span>
              <p>{{ labels?.Publish_to_NSL }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isShowCardDetails && cardDetails?.templateType != '331'">
  <span class="w-100" [ngClass]="{ 'spe-accordian': mccTemplateType.length > 0 }">
    <div class="tbl_conf tbl_conf_template1" [ngClass]="{ tbl_conf_collapse: tbl_conf_collapsebtn }">
      <aside
        class="sideBar"
        [ngClass]="{ sideBar_transition: tbl_conf_collapsebtn }"
        *ngIf="
          sortConfigurations?.position == 'side' ||
          searchConfigurations?.position == 'side' ||
          horizontalLayout?.length > 0
        "
      >
        <div class="header d-flex justify-content-between align-items-center">
          <div class="name d-flex align-items-center">
           
            <h3 class="text-truncate">
              Filter
            </h3>
            <!-- <span class="count">06</span> -->
          </div>
          <h5 class="clear cursor-pointer" *ngIf="selectedSearchFieldIds?.length>0 || attributeValue || previousFilter || (sortOption && sortOptionAttr)" (click)="clearAllFeatures()">CLEAR ALL</h5>
        </div>

        <div class="sidebar-sort" OutsideClick (appOutsideClick)="focusInputField($event)">
          <span class="search-filter" *ngIf="searchConfigurations?.position == 'side' && showSearch">
            <input
              type="text"
              [placeholder]="searchConfigurations?.placeHolderText"
              [(ngModel)]="attributeValue"
              (keyup)="applyFilterAndSort(true)"
            />
            <em class="nh-icon nh-icon-search-line" *ngIf="attributeValue?.length == 0"></em>
            <em class="nh-icon nh-icon-x-lg" (click)="cancelSearch()" *ngIf="attributeValue?.length != 0"></em>
            <ng-container [ngTemplateOutlet]="savedSearchPanel"></ng-container>
          </span>
          <ng-container *ngIf="searchConfigurations?.showSavedSearch" [ngTemplateOutlet]="savedSearch"></ng-container>
        </div>
        <div class="filters">
          <p-accordion [activeIndex]="0">
            <p-accordionTab *ngIf="sortConfigurations?.position == 'side'" (click)="sidesort.toggle($event)">
              <ng-template pTemplate="header">
                <div class="d-flex align-items-center">
                  <span class="vertical-align-middle">Sort</span>
                </div>
              </ng-template>
            </p-accordionTab>
            <p-overlayPanel styleClass="filterOverlay sidesort" #sidesort>
              <div class="attr-list1" *ngIf="sort && individual && individual.length > 0">
                <mat-radio-group
                  class="order"
                  aria-label="Select sort option"
                  [(ngModel)]="sortOption"
                  (ngModelChange)="applyFilterAndSort(true,true)"
                >
                  <mat-radio-button [value]="1">Ascending</mat-radio-button>
                  <mat-radio-button [value]="2">Descending</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group
                  class="order-option"
                  aria-label="Select sort option"
                  [(ngModel)]="sortOptionAttr"
                  (ngModelChange)="applyFilterAndSort(true,true)"
                >
                  <mat-radio-button [value]="attr?.attributeName" *ngFor="let attr of individual; let i = index">{{
                    attr?.attributeDisplayName ? attr?.attributeDisplayName : attr?.attributeName
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </p-overlayPanel>
          </p-accordion>
          <p-accordion [activeIndex]="activeIndex" [multiple]="true">
            <p-accordionTab *ngFor="let layout of horizontalLayout">
              <ng-template pTemplate="header">
                <div class="d-flex align-items-center">
                  <span class="vertical-align-middle">{{ layout.displayName }}</span>
                  <span *ngIf="filterConfigurations?.showAggregation && layout?.count" class="count">{{
                    layout?.count
                  }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <app-filter-templates
                  [id]="layout?.id"
                  [individual]="individual"
                  [data]="layout?.data"
                  [propertyName]="layout?.name"
                  [_clearAllFlag]="clearAllFlag"
                  (emitData)="setDataToAttr($event, layout)"
                  (emitSortData)="setDataToSort($event)"
                  (emitPropertyName)="setPropertyName($event)"
                ></app-filter-templates>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </aside>
      <button
        *ngIf="
          filterConfigurations?.toggleView &&
          (sortConfigurations?.position == 'side' ||
          searchConfigurations?.position == 'side' ||
          horizontalLayout?.length > 0)
        "
        (click)="tbl_conf_collapsebtn = !tbl_conf_collapsebtn"
        role="button"
        class="toggle rounded-circle text-center cursor-pointer"
      >
        <em class="nh-icon nh-icon-chevron-left"></em>
      </button>

      <div
        class="innerContent"
        [ngClass]="{
          'w-100': isMccLayout,
          'paginator-padding': length && pageSize && (length > 10 || cardDetails?.templateType == 'table'),
          'nh-ml-24 nh-mr-24': cardDetails?.templateType == 'table' || isExtended,
          'nh-mt-24': cardDetails?.templateType == 'table' && !matTabMccTemplate,
          'nh-ml-40 nh-mr-40': tenantName?.includes('defisolutions'),
          'dynamicmcctemplates': isDynamicTemplate
        }"
      >
        <ng-container *ngIf="locationFilter?.length > 0">
          <div class="location-search-container ml-auto nh-mb-10" *ngFor="let attr of locationFilter">
            <input
              class="location-search-input"
              [placeholder]="labels?.Enter + ' ' + labels?.Location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              #search
            />
            <button
              (click)="getCurrentLocation(true)"
              class="current-location"
              pTooltip="Current Location"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
            >
              <img [src]="'../../../../assets/img/general-entity/location.png' | CDNUrlRewritePipe" />
            </button>
            <em class="nh-icon nh-icon-x-lg" (click)="removeLocationFilter()"></em>
          </div>
        </ng-container>
        <div class="popupfilter d-none" *ngIf="!searchFieldsConfigurations?.accordionView">
          <h3 class="Filters cursor-pointer" (click)="openFilters()">Filter</h3>
        </div>
        <div
          class="search-accordian d-flex"
          *ngIf="searchFieldsConfigurations?.accordionView"
          [ngClass]="{ 'search-accordian-opened': accordianfilters }"
        >
          <span class="search-filter" OutsideClick (appOutsideClick)="focusInputField($event)">
            <input
              type="text"
              [placeholder]="searchConfigurations?.placeHolderText ? searchConfigurations?.placeHolderText : 'Search'"
              [(ngModel)]="attributeValue"
              (keyup)="applyFilterAndSort(true)"
            />
            <em class="nh-icon nh-icon-search-line" *ngIf="attributeValue?.length == 0"></em>
            <em class="nh-icon nh-icon-x-lg" (click)="cancelSearch()" *ngIf="attributeValue?.length != 0"></em>
            <em class="nh-icon nh-icon-chevron-down" (click)="searchaccordian()" *ngIf="!accordianfilters"></em>
            <em class="nh-icon nh-icon-chevron-up" (click)="searchaccordian()" *ngIf="accordianfilters"></em>
            <ng-container [ngTemplateOutlet]="savedSearchPanel"></ng-container>
          </span>
          <ng-container *ngIf="accordianfilters">
            <div class="search-accordian-data">
              <ng-container [ngTemplateOutlet]="filters"></ng-container>
              <ng-container [ngTemplateOutlet]="actionbtns"></ng-container>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!searchFieldsConfigurations?.accordionView">
          <div
            class="clear-top d-flex justify-content-end nh-mb-10"
            [ngClass]="{ 'nh-mr-24': cardDetails?.templateType !== 'table' }"
            *ngIf="
              !(
                sortConfigurations?.position == 'side' ||
                searchConfigurations?.position == 'side' ||
                horizontalLayout?.length > 0
              ) &&
              (searchConfigurations?.position == 'top' ||
                sortConfigurations?.position == 'top' ||
                individual?.length > 0 ||
                entitySearchFields?.length > 0) && (selectedSearchFieldIds?.length>0 || attributeValue || previousFilter || (sortOption && sortOptionAttr))
            "
          >
            <button class="clear cursor-pointer"  *ngIf="selectedSearchFieldIds?.length>0 || attributeValue || previousFilter || (sortOption && sortOptionAttr)"(click)="clearAllFeatures()">
              <ng-container *ngIf="!tenantName?.includes('defisolutions')">CLEAR ALL</ng-container>
              <ng-container *ngIf="tenantName?.includes('defisolutions')">CLEAR</ng-container>
            </button>
          </div>
          <ng-container [ngTemplateOutlet]="filters"></ng-container>
        </ng-container>
        <div
          class="card-view"
          [ngClass]="{
            cardSpaceAround: hideAllOptions,
            'entity-left-MCC-view': !isMccLayout && showfromMcc && tempIsMCC,
            'swiper-class-for-width': (isMccLayout || chatbot) && cardsData && cardsData.length > 0,
            'nh-mb-15':
              isDynamicTemplate && length && pageSize && (length > pageSize || cardDetails?.templateType == 'table')
          }"
        >
          <div class="entity-left" *ngIf="filterDatatoFilter && !chatbot && !hideAllOptions">
            <div
              class="entity-editfields"
              *ngIf="
                filterDatatoFilter?.cardDetails?.sortBy.length > 0 ||
                filterDatatoFilter?.cardDetails?.filterBy.length > 0 ||
                filterDatatoFilter?.rangeData.length > 0
              "
            >
              <app-entity-grid-card-filter
                [filterData]="filterDatatoFilter"
                (searchDataEmitter)="getsearchDataEmitter($event)"
                (filterDataEmitter)="getFilterDataEmitter($event)"
              >
              </app-entity-grid-card-filter>
            </div>
          </div>
          <div class="choose-templates template1" *ngIf="verticalLayout?.length > 0">
            <div class="choose-template1">
              <div
                class="searchbar"
                *ngIf="mappingFields?.search?.length > 0"
                OutsideClick
                (appOutsideClick)="focusInputField($event)"
              >
                <input
                  class="input-box"
                  type="text"
                  placeholder="Search"
                  [(ngModel)]="attributeValue"
                  (keyup)="noSpancing()"
                />
                <em class="nh-icon nh-icon-search"></em>
                <div class="attrsearch-options">
                  <mat-icon
                    class="options-icon"
                    pTooltip="Saved Filters"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                    >more_vert</mat-icon
                  >
                  <mat-icon
                    (click)="openSaveSearchOrFilter(true)"
                    pTooltip="Save Search or Save Filter"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                    >{{ openSaveSearchOrFilterBox ? 'star' : 'star_border' }}</mat-icon
                  >
                  <div class="edit-save-search" *ngIf="openSaveSearchOrFilterBox">
                    <div class="ess-close" (click)="openSaveSearchOrFilter(false); openSaveFilter(false)">
                      <mat-icon>close</mat-icon>
                    </div>
                    <div class="ess-list" *ngIf="openSaveSearchOrFilterBox && !openSaveFilterBox">
                      <h6 (click)="saveSearch()">{{ labels?.Save + ' ' + labels?.Search }}</h6>
                      <h6 (click)="openSaveFilter(true)">{{ labels?.Save + ' ' + labels?.Filter }}</h6>
                    </div>
                    <div *ngIf="openSaveSearchOrFilterBox && openSaveFilterBox">
                      <h6>{{ labels?.Edit + ' ' + labels?.Save + ' ' + labels?.Filter }}</h6>
                      <label for="name">{{ labels?.name }}:</label>
                      <input type="text" name="name" [(ngModel)]="filterName" />
                      <div class="done-btn cursor-pointer">
                        <button (click)="saveFilter()">{{ labels?.Done }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="entity-history-search" *ngIf="openSavedSearch">
                  <div class="ehs-header">
                    <h6>{{ labels?.Saved + ' ' + labels?.Search }}</h6>
                    <a (click)="clearAllSearchRecords()">{{ labels?.Clear + ' ' + labels?.all }}</a>
                  </div>
                  <div class="ehs-list">
                    <div class="ehs-list-item" *ngFor="let search of searchHistoryArray">
                      <p class="text-truncate" (click)="saveSearchCall(search)">{{ search }}</p>
                      <mat-icon (click)="clearParticularSearch(search)">close</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filterheading">
                <span class="name">Filter</span>
                <span class="clear" *ngIf="selectedSearchFieldIds?.length>0 || attributeValue || previousFilter || (sortOption && sortOptionAttr)" (click)="clearAllFeatures()">CLEAR ALL</span>
              </div>
              <div class="filter-list" *ngIf="verticalLayout?.length > 0">
                <p-accordion>
                  <p-accordionTab *ngFor="let item of verticalLayout" header="{{ item?.name }}">
                    <ng-container *ngIf="item?.data || item?.id === 'sortBy' || (item?.id >= 33 && item?.id <= 39)">
                      <app-filter-templates
                        [id]="item?.id"
                        [individual]="individual"
                        [data]="item?.data"
                        [_clearAllFlag]="clearAllFlag"
                        (emitData)="setDataToAttr($event, item)"
                        (emitSortData)="setDataToSort($event)"
                        (emitPropertyName)="setPropertyName($event)"
                      ></app-filter-templates>
                    </ng-container>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
          <div
            class="entity-rightwrap multi-entity swiper-visible"
            [ngClass]="{
              'entity-rightwrap-chatbot': chatbot,
              'entity-table-wrap': isTable,
              'entity-extended': isExtended
            }"
            *ngIf="cardDetails?.templateType != 'table' && !kanbanConfiguration"
          >
            <div
              class="filter-icon"
              *ngIf="!chatbot && !hideAllOptions && showViewAllButton && cardDetails?.templateType != 'table'"
            >
              <div
                class="backtemplates cstm-backtemplates"
                [ngClass]="{
                  'nh-mt-8': hideTitle
                }"
                style="text-align: right;"
                (click)="gotoEntityDetailsPage()"
                *ngIf="isMccLayout && !hideAllOptions"
                [ngStyle]="{
                  color: titleColor
                }"
              >
                {{ labels?.View_All ? labels?.View_All : 'View All' }}
              </div>
              <div
                class="backtemplates cstm-backtemplates"
                [ngClass]="{
                  'nh-mt-8': hideTitle
                }"
                style="text-align: right;"
                (click)="closeFilter()"
                *ngIf="!isMccLayout && !hideAllOptions"
                [ngStyle]="{
                  color: titleColor
                }"
              >
                {{ labels?.View_Less ? labels?.View_Less : 'View Less' }}
              </div>
            </div>
            <ng-container
            *ngIf="cardDetails?.templateType != 'table'"
          >
              <div
                class="entity-borad-swiper"
                [ngClass]="{ 'swiper-outer-chatbot': chatbot }"
                id="carousel_{{ cardDataId }}"
                *ngIf="(isMccLayout || chatbot) && cardsData && cardsData.length > 0"
              >
                <!-- class="type-templates swiper-outer swiper-outer-1 templates-swiper" -->
                <button
                  class="btn-display swiper-pre-btn"
                  (click)="moveToThatPage(swiperPage - 1)"
                  [ngClass]="{ 'swiper-btn-disabled': swiperPage == 0 }"
                >
                  <em class="nh-icon nh-icon-chevron-left"></em>
                </button>
                <button
                  class="btn-display swiper-next-btn"
                  (click)="moveToThatPage(swiperPage + 1)"
                  [ngClass]="{ 'swiper-btn-disabled': (cardsData.length - swiperPage <= swiperCards) }"
                >
                  <em class="nh-icon nh-icon-chevron-right"></em>
                </button>
                <div
                  class="custom-swiper-class"
                  #swiperDiv
                  (mousedown)="startDragging($event, true)"
                  (mouseup)="stopDragging($event, false)"
                  (mouseleave)="stopDragging($event, false)"
                  (mousemove)="moveEvent($event)"
                >
                  <div
                    class="custom-swiper"
                    [ngClass]="
                      moveLeft
                        ? 'custom-swiper-left-' + (previousPageNumber - swiperPage)
                        : moveRight
                        ? 'custom-swiper-right-' + (swiperPage - previousPageNumber)
                        : ''
                    "
                    *ngFor="let card of cardsData; let txnDataIndex = index"
                  >
                    <!--  *ngIf="(txnDataIndex < swiperCards + swiperPage && txnDataIndex >= swiperPage) || mouseDown" -->
                    <ng-container>
                      <div class="entity-expand-wrap" *ngIf="cardDetails?.templateType == 'default'">
                        <div class="entity-expand row">
                          <div [ngClass]="{ 'col-12': !chatbot }">
                            <div
                              class="entity-expands-info cardpreview mb-3"
                              [ngClass]="{ 'entity-expands-info-chatbot cardpreview-chatbot': chatbot }"
                            >
                              <ul [ngClass]="card.isMore ? 'more-height' : 'less-height'">
                                <li class="done" *ngFor="let record of card?.recordData">
                                  <figure class="temptype-img" *ngIf="record.type == 'image'">
                                    <img height="200px" [src]="record.contentUrl | CDNUrlRewritePipe" />
                                  </figure>
                                  <span *ngIf="record.type !== 'image'">{{ record.displayName }}</span>
                                  <span *ngIf="record.type !== 'image'" [innerHTML]="record.value"></span>
                                </li>
                              </ul>
                              <div class="cardpreview-btns defaultcard-btns" *ngIf="gsiMapping[0]?.displayName">
                                <button
                                  pTooltip="{{ gsiMapping[0]?.displayName }}"
                                  tooltipPosition="top"
                                  class="btn btnaction text-truncate"
                                  [ngClass]="{ 'btnaction-chatbot': chatbot }"
                                  *ngIf="quantity[txnDataIndex] == 0"
                                  (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                >
                                  {{ gsiMapping[0]?.displayName }}
                                </button>
                                <div class="quantity-section" *ngIf="quantity[txnDataIndex] > 0">
                                  <div class="quantity-input">
                                    <button class="quantity-btn">
                                      <i
                                        class="bi bi-dash"
                                        (click)="changeQuantity('decreaseQuantity', txnDataIndex)"
                                      ></i>
                                    </button>
                                    <span class="quantity">{{ quantity[txnDataIndex] }}</span>
                                    <button class="quantity-btn">
                                      <i
                                        class="bi bi-plus"
                                        (click)="changeQuantity('increaseQuantity', txnDataIndex)"
                                      ></i>
                                    </button>
                                  </div>
                                </div>

                              </div>
                              <button
                                  pTooltip=" {{ labels?.View_More }}"
                                  tooltipPosition="top"
                                  *ngIf="!card.isMore "
                                  class="btn btnaction text-truncate"
                                  [ngClass]="{ 'btnaction-chatbot': chatbot }"
                                  (click)="card.isMore = true"
                                >
                                  {{ labels?.View_More }}
                                </button>
                                <button
                                  *ngIf="card.isMore "
                                  class="btn btnaction"
                                  [ngClass]="{ 'btnaction-chatbot': chatbot }"
                                  (click)="card.isMore = false"
                                >
                                  {{ labels?.View_Less }}
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!isDynamicTemplate">
                        <div
                          class="entitytemplates-img"
                          [ngClass]="{ 'no-data': totalLength == 0 , 'ramco-tenat-no-data': isRamcoTenant }"
                          *ngIf="cardDetails?.templateType !== 'default'"
                        >
                          <!-- <div class="backtemplates" (click)="backToEntityPage();">Back</div> -->
                          <div class="entitytemplates-col w-100" #childHeight>
                            <div>
                              <div>
                                <div *ngIf="cardDetails?.cardName == 'child'">
                                  <div class="entitytemplatesgroup" *ngIf="cardDetails?.templateType == '1'">
                                    <div class="entitygroup-info">
                                      <div class="w-50">
                                        <ul>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[0]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[0]?.attributeName,
                                                      0,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[0]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[1]?.attributeName,
                                                      1,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[1]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[2]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[2]?.attributeName,
                                                      2,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[2]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="w-50">
                                        <figure class="temptype-img">
                                          <img [src]="(card?.recordData)[4]?.contentUrl | CDNUrlRewritePipe" />
                                        </figure>
                                        <li>
                                          <span class="passcolor">{{ (card?.recordData)[5]?.displayName }}</span>
                                          <span
                                            class="passcolor"
                                            (click)="
                                              gsiAttrMap[(card?.recordData)[5]?.attributeName]
                                                ? gotoTxnPage(gsiAttrMap[(card?.recordData)[5]?.attributeName], card)
                                                : openLink(
                                                    $event,
                                                    (card?.recordData)[5]?.attributeName,
                                                    5,
                                                    hyperlinkAttribute,
                                                    txnDataIndex
                                                  )
                                            "
                                            >{{ (card?.recordData)[5]?.value }}</span
                                          >
                                        </li>
                                      </div>
                                    </div>
                                    <div class="entitygroup-info linebar">
                                      <div class="w-50">
                                        <ul>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[3]?.attributeName,
                                                      3,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[3]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="w-50 text-right">
                                        <button
                                          *ngIf="gsiMapping[0]?.displayName"
                                          class="btn btn-viewdetails"
                                          (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                        >
                                          {{ gsiMapping[0]?.displayName }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="entitytemplatesgroup entitytemplatesgroup2"
                                    *ngIf="cardDetails?.templateType == '2'"
                                  >
                                    <div class="entitygroup-info row">
                                      <div class="col-6">
                                        <figure class="temptype-img">
                                          <img [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe" />
                                        </figure>
                                        <ul>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[1]?.attributeName,
                                                      1,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[1]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-6">
                                        <ul>
                                          <li>
                                            <span
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[2]?.attributeName,
                                                      2,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[2]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div class="entitygroup-info linebar">
                                      <div class="w-50">
                                        <button
                                          *ngIf="gsiMapping[0]?.displayName"
                                          class="btn btn-viewdetails"
                                          (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                        >
                                          {{ gsiMapping[0]?.displayName }}
                                        </button>
                                      </div>
                                      <div class="w-50">
                                        <ul>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[5]?.displayName }}</span>
                                            <span
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[2]?.attributeName,
                                                      2,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[5]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="entitytemplatesgroup entitytemplatesgroup4"
                                    *ngIf="cardDetails?.templateType == '4'"
                                  >
                                    <div class="entitygroup-info row">
                                      <div class="col-12">
                                        <ul>
                                          <li>
                                            <span
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[0]?.attributeName,
                                                      0,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[0]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                openLink(
                                                  $event,
                                                  (card?.recordData)[1]?.attributeName,
                                                  1,
                                                  hyperlinkAttribute,
                                                  txnDataIndex
                                                )
                                              "
                                              >{{ (card?.recordData)[1]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[2]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[2]?.attributeName,
                                                      2,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[2]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[3]?.attributeName,
                                                      3,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[3]?.value }}</span
                                            >
                                          </li>
                                          <li>
                                            <span class="passcolor">{{ (card?.recordData)[4]?.displayName }}</span>

                                            <span
                                              class="passcolor"
                                              (click)="
                                                gsiAttrMap[(card?.recordData)[4]?.attributeName]
                                                  ? gotoTxnPage(gsiAttrMap[(card?.recordData)[4]?.attributeName], card)
                                                  : openLink(
                                                      $event,
                                                      (card?.recordData)[4]?.attributeName,
                                                      4,
                                                      hyperlinkAttribute,
                                                      txnDataIndex
                                                    )
                                              "
                                              >{{ (card?.recordData)[4]?.value }}</span
                                            >
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div class="entitygroup-info linebar templatebtns">
                                      <button
                                        *ngIf="gsiMapping[0]?.displayName"
                                        class="btn btn-viewdetails"
                                        (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                      >
                                        {{ gsiMapping[0]?.displayName }}
                                      </button>
                                      <button
                                        *ngIf="gsiMapping[1]?.displayName"
                                        class="btn btn-viewdetails btn-next-clr"
                                        (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                                      >
                                        {{ gsiMapping[1]?.displayName }}
                                      </button>
                                    </div>
                                  </div>
                                  <div class="templates-type">
                                    <app-core-templates
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                    >
                                    </app-core-templates>
                                    <app-attribute-templates
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      (emitQuantity)="changeQuantity($event?.event, $event?.txnDataIndex)"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                    >
                                    </app-attribute-templates>
                                    <!-- check  -->
                                    <div
                                      class="entity-template-child-view entity-template-type"
                                      *ngIf="cardDetails?.templateType == '242'"
                                      (click)="expandCard(card, $event, txnDataIndex)"
                                    >
                                      <section
                                        class="row align-items-center"
                                        [ngStyle]="{ 'background-color': cardColor }"
                                        entityCardColor
                                        [attrHoveredColors]="attrHoveredColors"
                                        [cardHoveredColor]="cardHoveredColor"
                                        [cardColor]="cardColor"
                                        [cardBorderSize]="cardBorderSize"
                                        [cardBorderRadius]="cardBorderRadius"
                                        [cardBorderColor]="cardBorderColor"
                                        [cardShadow]="cardShadow"
                                        [attributeColors]="colors"
                                        [_otherStyle]="otherStyles"
                                        [conditionArray]="conditionArray"
                                        [card]="card"
                                      >
                                        <div class="col">
                                          <div class="row">
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[0].attributeName }}
                                                </strong>
                                                <span>{{ card?.recordData?.[0].value }}</span>
                                              </li>
                                            </ul>
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[1].attributeName }}0
                                                </strong>
                                                <span>{{ card?.recordData?.[1].value }}</span>
                                              </li>
                                            </ul>
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[2].attributeName }}
                                                </strong>
                                                <span>{{ card?.recordData?.[2].value }}</span>
                                              </li>
                                            </ul>
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[3].attributeName }}
                                                </strong>
                                                <span>{{ card?.recordData?.[3].value }}</span>
                                              </li>
                                            </ul>
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[4].attributeName }}
                                                </strong>
                                                <span>{{ card?.recordData?.[4].value }}</span>
                                              </li>
                                            </ul>
                                            <ul class="col-xl-2">
                                              <li class="list-attribute" id="">
                                                <strong class="title passcolor"
                                                  >{{ card?.recordData?.[5].attributeName }}
                                                </strong>
                                                <span>{{ card?.recordData?.[5].value }}</span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div class="col-auto">
                                          <ul class="d-flex flex-wrap btn-list">
                                            <li>
                                              <button
                                                class="btn btn-primary"
                                                (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                                pTooltip=" {{ gsiMapping[0]?.displayName }}"
                                                tooltipPosition="top"
                                                *ngIf="gsiMapping[0]?.displayName"
                                                tooltipStyleClass="transcustomtolltipbook"
                                                [ngStyle]="{ background: btnColors[0] }"
                                              >
                                                {{ gsiMapping[0]?.displayName }}
                                              </button>
                                            </li>
                                            <button class="expand-btn">
                                              <img
                                                src="../../../../../assets/img/general-entity/view-more.svg"
                                                alt=""
                                              />
                                            </button>
                                          </ul>
                                        </div>
                                      </section>
                                    </div>
                                    <app-features-templates
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardsData]="cardsData"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                    ></app-features-templates>
                                    <app-banners-template
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardsData]="cardsData"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                      [cardSelected]="expandTxnDataIndex == txnDataIndex"
                                    ></app-banners-template>
                                    <app-insurance-templates
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                    ></app-insurance-templates>
                                    <app-e-commerce-template
                                      [cardDisplay]="'view'"
                                      [txnDataIndex]="txnDataIndex"
                                      [gsiMapping]="gsiMapping"
                                      (emitExecuteCard)="executeCardEmit($event)"
                                      [cardDetails]="cardDetails"
                                      [card]="card"
                                      [cardsData]="cardsData"
                                      [cardColor]="cardColor"
                                      [attributeColors]="colors"
                                      [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                      [_otherStyle]="otherStyles"
                                      [conditionArray]="conditionArray"
                                      [cardShadow]="cardShadow"
                                      [cardBorderSize]="cardBorderSize"
                                      [cardBorderRadius]="cardBorderRadius"
                                      [cardBorderColor]="cardBorderColor"
                                      [attrHoveredColors]="attrHoveredColors"
                                      [cardHoveredColor]="cardHoveredColor"
                                      [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                                    ></app-e-commerce-template>
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="cardDetails?.cardName == 'expand'">
                                <div class="card-expand-e1" *ngIf="cardDetails?.templateType == '1'">
                                  <div class="entity-template-t2 entity-template-type" #dropEntityData>
                                    <div class="row">
                                      <div class="col-4">
                                        <div>
                                          <figure class="temptype-img">
                                            <img
                                              class="swiper-image"
                                              [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                            />
                                          </figure>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div>
                                          <ul>
                                            <li>
                                              <span
                                                class="passcolor text-truncate"
                                                pTooltip=" {{ (card?.recordData)[1]?.displayName }}"
                                                tooltipPosition="top"
                                                >{{ (card?.recordData)[1]?.displayName }}</span
                                              >
                                              <span
                                                class="text-truncate"
                                                pTooltip="{{ (card?.recordData)[1]?.value }}"
                                                tooltipPosition="top"
                                                (click)="
                                                  gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                                    ? gotoTxnPage(
                                                        gsiAttrMap[(card?.recordData)[1]?.attributeName],
                                                        card
                                                      )
                                                    : openLink(
                                                        $event,
                                                        (card?.recordData)[1]?.attributeName,
                                                        1,
                                                        hyperlinkAttribute,
                                                        txnDataIndex
                                                      )
                                                "
                                                >{{ (card?.recordData)[1]?.value }}</span
                                              >
                                            </li>
                                            <li>
                                              <span
                                                class="passcolor text-truncate"
                                                pTooltip=" {{ (card?.recordData)[2]?.displayName }}"
                                                tooltipPosition="top"
                                                >{{ (card?.recordData)[2]?.displayName }}</span
                                              >
                                              <span
                                                class="text-truncate"
                                                pTooltip="{{ (card?.recordData)[2]?.value }}"
                                                tooltipPosition="top"
                                                (click)="
                                                  gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                                    ? gotoTxnPage(
                                                        gsiAttrMap[(card?.recordData)[2]?.attributeName],
                                                        card
                                                      )
                                                    : openLink(
                                                        $event,
                                                        (card?.recordData)[2]?.attributeName,
                                                        2,
                                                        hyperlinkAttribute,
                                                        txnDataIndex
                                                      )
                                                "
                                                >{{ (card?.recordData)[2]?.value }}</span
                                              >
                                            </li>
                                            <li>
                                              <span
                                                class="passcolor text-truncate"
                                                pTooltip=" {{ (card?.recordData)[3]?.displayName }}"
                                                tooltipPosition="top"
                                                >{{ (card?.recordData)[3]?.displayName }}</span
                                              >
                                              <span
                                                class="text-truncate"
                                                pTooltip="{{ (card?.recordData)[3]?.value }}"
                                                tooltipPosition="top"
                                                (click)="
                                                  gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                                    ? gotoTxnPage(
                                                        gsiAttrMap[(card?.recordData)[3]?.attributeName],
                                                        card
                                                      )
                                                    : openLink(
                                                        $event,
                                                        (card?.recordData)[3]?.attributeName,
                                                        3,
                                                        hyperlinkAttribute,
                                                        txnDataIndex
                                                      )
                                                "
                                                >{{ (card?.recordData)[3]?.value }}</span
                                              >
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div>
                                          <ul>
                                            <li>
                                              <span
                                                class="passcolor text-truncate"
                                                pTooltip=" {{ (card?.recordData)[4]?.displayName }}"
                                                tooltipPosition="top"
                                                >{{ (card?.recordData)[4]?.displayName }}</span
                                              >
                                              <span
                                                class="text-truncate"
                                                pTooltip="{{ (card?.recordData)[4]?.value }}"
                                                tooltipPosition="top"
                                                (click)="
                                                  gsiAttrMap[(card?.recordData)[4]?.attributeName]
                                                    ? gotoTxnPage(
                                                        gsiAttrMap[(card?.recordData)[4]?.attributeName],
                                                        card
                                                      )
                                                    : openLink(
                                                        $event,
                                                        (card?.recordData)[4]?.attributeName,
                                                        4,
                                                        hyperlinkAttribute,
                                                        txnDataIndex
                                                      )
                                                "
                                                >{{ (card?.recordData)[4]?.value }}</span
                                              >
                                            </li>
                                            <li>
                                              <span
                                                class="passcolor text-truncate"
                                                pTooltip=" {{ (card?.recordData)[5]?.displayName }}"
                                                tooltipPosition="top"
                                                >{{ (card?.recordData)[5]?.displayName }}</span
                                              >
                                              <span
                                                class="text-truncate"
                                                pTooltip="{{ (card?.recordData)[5]?.value }}"
                                                tooltipPosition="top"
                                                (click)="
                                                  gsiAttrMap[(card?.recordData)[5]?.attributeName]
                                                    ? gotoTxnPage(
                                                        gsiAttrMap[(card?.recordData)[5]?.attributeName],
                                                        card
                                                      )
                                                    : openLink(
                                                        $event,
                                                        (card?.recordData)[5]?.attributeName,
                                                        5,
                                                        hyperlinkAttribute,
                                                        txnDataIndex
                                                      )
                                                "
                                                >{{ (card?.recordData)[5]?.value }}</span
                                              >
                                            </li>
                                          </ul>
                                          <div class="entitygroup-info linebar">
                                            <ul>
                                              <li class="pb-0">
                                                <span
                                                  class="text-truncate"
                                                  pTooltip=" {{ (card?.recordData)[6]?.displayName }}"
                                                  tooltipPosition="top"
                                                  >{{ (card?.recordData)[6]?.displayName }}</span
                                                >
                                                <span
                                                  class="text-truncate"
                                                  pTooltip="{{ (card?.recordData)[6]?.value }}"
                                                  tooltipPosition="top"
                                                  (click)="
                                                    gsiAttrMap[(card?.recordData)[6]?.attributeName]
                                                      ? gotoTxnPage(
                                                          gsiAttrMap[(card?.recordData)[6]?.attributeName],
                                                          card
                                                        )
                                                      : openLink(
                                                          $event,
                                                          (card?.recordData)[6]?.attributeName,
                                                          6,
                                                          hyperlinkAttribute,
                                                          txnDataIndex
                                                        )
                                                  "
                                                  >{{ (card?.recordData)[6]?.value }}</span
                                                >
                                              </li>
                                            </ul>
                                            <button
                                              *ngIf="gsiMapping[0]?.displayName"
                                              class="btn btn-viewdetails"
                                              (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                            >
                                              {{ gsiMapping[0]?.displayName }}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isDynamicTemplate">
                        <div class="card_{{ txnDataIndex }}"></div>
                        {{ getDynamicCardTemplate(card, txnDataIndex) }}
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <div class="entity-board-pager">
                  <ng-container *ngFor="let card of cardsData; let txnDataIndex = index">
                    <span
                      class="cursor-pointer"
                      (click)="moveToThatPage(txnDataIndex)"
                      [ngClass]="{ active: swiperPage === txnDataIndex }"
                      *ngIf="cardsData.length - txnDataIndex >= swiperCards"
                    ></span>
                  </ng-container>
                </div>
              </div>
              <div
                class="entity-expand-wrap w-100 cstm-entity-expand-wrap-padding"
                *ngIf="cardDetails?.templateType == 'default' && !isMccLayout && !isTable && !chatbot && showCards"
              >
                <div class="entity-expand row">
                  <div
                    [ngClass]="mappingFields.defaultViewType == 'expand' ? 'col-12' : 'col-6'"
                    *ngFor="let card of cardsData; let txnDataIndex = index"
                  >
                    <div class="entity-expands-info cardpreview mb-3">
                      <ul [ngClass]="card.isMore ? 'more-height' : 'less-height'">
                        <li class="done" *ngFor="let record of card?.recordData; index as i">
                          <figure class="temptype-img" *ngIf="record.type == 'image'">
                            <img height="200px" [src]="record.contentUrl | CDNUrlRewritePipe" />
                          </figure>
                          <span *ngIf="record.type !== 'image'">{{ record.displayName }}</span>
                          <span
                            *ngIf="record.type !== 'image'"
                            [innerHTML]="record.value"
                            (click)="
                              gsiAttrMap[record?.attributeName]
                                ? gotoTxnPage(gsiAttrMap[record?.attributeName], card)
                                : openLink($event, record?.attributeName, i, hyperlinkAttribute, txnDataIndex)
                            "
                          ></span>
                        </li>
                      </ul>
                      <div class="cardpreview-btns defaultcard-btns" *ngIf="gsiMapping[0]?.displayName">
                        <button
                          pTooltip="{{ gsiMapping[0]?.displayName }}"
                          tooltipPosition="top"
                          *ngIf="gsiMapping[0]?.displayName && quantity[txnDataIndex] == 0"
                          class="btn btnaction text-truncate"
                          (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                        >
                          {{ gsiMapping[0]?.displayName }}
                        </button>
                        <div class="quantity-section" *ngIf="quantity[txnDataIndex] > 0">
                          <div class="quantity-input">
                            <button class="quantity-btn">
                              <i class="bi bi-dash" (click)="changeQuantity('decreaseQuantity', txnDataIndex)"></i>
                            </button>
                            <span class="quantity">{{ quantity[txnDataIndex] }}</span>
                            <button class="quantity-btn">
                              <i class="bi bi-plus" (click)="changeQuantity('increaseQuantity', txnDataIndex)"></i>
                            </button>
                          </div>
                        </div>

                      </div>
                      <button
                      pTooltip=" {{ labels?.View_More }}"
                      tooltipPosition="top"
                      *ngIf="!card.isMore"
                      class="btn btnaction text-truncate"
                      (click)="card.isMore = true"
                    >
                      {{ labels?.View_More }}
                    </button>
                    <button
                      *ngIf="card.isMore && !hideAllOptions"
                      class="btn btnaction"
                      (click)="card.isMore = false"
                    >
                      {{ labels?.View_Less }}
                    </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="entitytemplates-img"
                #noSwiperDiv
                [ngClass]="{
                  'no-data': totalLength == 0,
                  'ramco-tenat-no-data': isRamcoTenant,
                  'd-block': isDynamicTemplate,
                  'entity-expand-shelf': isExpandedVersion
                }"
                *ngIf="cardDetails?.templateType !== 'default' && !isMccLayout && !isTable && !chatbot"
              >
                <ng-container *ngIf="!isDynamicTemplate">
                  <ng-container *ngIf="!isExpandPopup" [ngTemplateOutlet]="expandTemplate"></ng-container>
                  <div
                    class="entitytemplates-col row w-100"
                    #childHeight
                    [ngClass]="{
                      'cardSpaceAround-1': hideAllOptions,
                      'mcc-entity-card-template': mcc_entity_card_template.has(cardDetails?.templateType)
                    }"
                    *ngIf="showCards"
                  >
                    <!-- cardSpaceAround: !hideAllOptions, -->

                    <div
                      class="mb-4 entity-template"
                      [ngClass]="
                        numberOfRows === 3
                          ? 'col-md-4'
                          : numberOfRows === 4
                          ? 'col-md-3'
                          : isCardMultiExpand
                          ? 'col-md-12'
                          : (cardDetails?.cardName == 'child' && cardDetails?.templateType == '1') ||
                            cardDetails?.templateType == '2' ||
                            cardDetails?.templateType == '4'
                          ? 'col-6'
                          : ((cardDetails?.cardName == 'child' && cardDetails?.templateType !== '1') ||
                              cardDetails?.templateType !== '2' ||
                              cardDetails?.templateType !== '4') &&
                            cardDetails?.templateType !== '141' &&
                            cardDetails?.templateType !== '142' &&
                            cardDetails?.templateType !== '143' &&
                            cardDetails?.templateType !== '144' &&
                            cardDetails?.templateType !== '145' &&
                            cardDetails?.templateType !== '146' &&
                            cardDetails?.templateType !== '147'
                          ? 'col-md-4'
                          : cardDetails?.templateType === '141' ||
                            cardDetails?.templateType === '142' ||
                            cardDetails?.templateType === '143' ||
                            cardDetails?.templateType === '144' ||
                            cardDetails?.templateType === '145' ||
                            cardDetails?.templateType === '146' ||
                            cardDetails?.templateType === '147'
                          ? 'col-6'
                          : 'w-100'
                      "
                      *ngFor="let card of cardsData; let txnDataIndex = index"
                    >
                      <ng-container *ngIf="cardDetails?.cardName == 'child'">
                        <div class="entitytemplatesgroup" *ngIf="cardDetails?.templateType == '1'">
                          <div class="entitygroup-info">
                            <div class="w-50">
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[0]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[0]?.attributeName,
                                            0,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[0]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[1]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[2]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[2]?.attributeName,
                                            2,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[2]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                            <div class="w-50">
                              <figure class="temptype-img">
                                <img [src]="(card?.recordData)[4]?.contentUrl | CDNUrlRewritePipe" />
                              </figure>
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[5]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[5]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[5]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[5]?.attributeName,
                                            5,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[5]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="entitygroup-info linebar">
                            <div class="w-50">
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[3]?.attributeName,
                                            3,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[3]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                            <div class="w-50 text-right">
                              <button
                                *ngIf="gsiMapping[0]?.displayName"
                                class="btn btn-viewdetails"
                                (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                              >
                                {{ gsiMapping[0]?.displayName }}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          class="entitytemplatesgroup entitytemplatesgroup2"
                          *ngIf="cardDetails?.templateType == '2'"
                        >
                          <div class="entitygroup-info row">
                            <div class="col-6">
                              <figure class="temptype-img">
                                <img [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe" />
                              </figure>
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[1]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                            <div class="col-6">
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[2]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[2]?.attributeName,
                                            2,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[2]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[3]?.attributeName,
                                            3,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[3]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[4]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[4]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[4]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[4]?.attributeName,
                                            4,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[4]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="entitygroup-info linebar">
                            <div class="w-50">
                              <button
                                *ngIf="gsiMapping[0]?.displayName"
                                class="btn btn-viewdetails"
                                (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                              >
                                {{ gsiMapping[0]?.displayName }}
                              </button>
                            </div>
                            <div class="w-50">
                              <ul>
                                <li>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[5]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[5]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[5]?.attributeName,
                                            5,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[5]?.value }}</span
                                  >
                                </li>
                                `
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="templates-type">
                          <div class="mcc-entity-template" *ngIf="!isExpanded || isExpandPopup || isExpandedVersion">
                            <!-- <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-hor templateType5"
                                *ngIf="cardDetails?.templateType == '5'"
                              >
                                <div
                                  class="template-card cardoption1"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                  [ngClass]="{ 'flex-row-reverse': entityCardFlip }"
                                >
                                  <figure>
                                    <img
                                      class="template-img"
                                      [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                      alt="general-entity"
                                    />
                                  </figure>
                                  <h3
                                    class="template-title"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </h3>
                                </div>
                              </div>
                              <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-hor templateType6"
                                *ngIf="cardDetails?.templateType == '6'"
                              >
                                <div
                                  class="template-card cardoption2"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <h3
                                    class="template-title"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[0]?.attributeName,
                                            0,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[0]?.value }}
                                  </h3>
                                  <p
                                    class="template-para"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </p>
                                </div>
                              </div> -->
                            <!-- <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-hor templateType7"
                                *ngIf="cardDetails?.templateType == '7'"
                              >
                                <div
                                  class="template-card cardoption3"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <h3
                                    class="template-title"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[0]?.attributeName,
                                            0,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[0]?.value }}
                                  </h3>
                                  <p
                                    class="template-para"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </p>
                                  <div class="template-btns">
                                    <button
                                      *ngIf="gsiMapping[0]?.displayName"
                                      class="btn btn-viewdetails"
                                      (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[0]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                      [ngStyle]="{ background: btnColors[0] }"
                                    >
                                      {{ gsiMapping[0]?.displayName }}
                                    </button>
                                    <button
                                      *ngIf="gsiMapping[1]?.displayName"
                                      class="btn btn-viewdetails btn-next-clr"
                                      (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[1]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                      [ngStyle]="{ background: btnColors[1] }"
                                    >
                                      {{ gsiMapping[1]?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </div> -->
                            <!-- <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-ver templateType8"
                                *ngIf="cardDetails?.templateType == '8'"
                              >
                                <div
                                  class="template-card cardoption4"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <figure class="template-figure">
                                    <img
                                      class="template-img"
                                      [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                      alt="general-entity"
                                    />
                                  </figure>
                                  <h3
                                    class="template-title text-truncate"
                                    pTooltip="{{ (card?.recordData)[1]?.value }}"
                                    tooltipPosition="top"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </h3>
                                </div>
                              </div>
                              <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-ver templateType9"
                                *ngIf="cardDetails?.templateType == '9'"
                              >
                                <div
                                  class="template-card cardoption5"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <figure class="template-figure">
                                    <img
                                      class="template-img"
                                      [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                      alt="general-entity"
                                    />
                                  </figure>
                                  <h3
                                    class="template-title"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </h3>
                                  <div class="template-btns">
                                    <button
                                      *ngIf="gsiMapping[0]?.displayName"
                                      class="btn btn-viewdetails"
                                      (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                      [ngStyle]="{ background: btnColors[0] }"
                                      pTooltip=" {{ gsiMapping[0]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[0]?.displayName }}
                                    </button>
                                    <button
                                      *ngIf="gsiMapping[1]?.displayName"
                                      class="btn btn-viewdetails btn-next-clr"
                                      (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                                      [ngStyle]="{ background: btnColors[1] }"
                                      pTooltip=" {{ gsiMapping[1]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[1]?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-ver templateType8"
                                *ngIf="cardDetails?.templateType == '121'"
                              >
                                <div
                                  class="template-card cardoption4"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <figure class="template-figure">
                                    <img
                                      class="template-img"
                                      [src]="(card?.recordData)[0].contentUrl | CDNUrlRewritePipe"
                                      alt="general-entity"
                                    />
                                  </figure>
                                  <h3
                                    class="template-title text-truncate"
                                    pTooltip="{{ (card?.recordData)[1]?.value }}"
                                    tooltipPosition="top"
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                  >
                                    {{ (card?.recordData)[1]?.value }}
                                  </h3>
                                  <div class="template-btns">
                                    <button
                                      *ngIf="gsiMapping[0]?.displayName"
                                      class="btn btn-viewdetails"
                                      (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[0]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[0]?.displayName }}
                                    </button>
                                    <button
                                      *ngIf="gsiMapping[1]?.displayName"
                                      class="btn btn-viewdetails btn-next-clr"
                                      (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[1]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[1]?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-hor templateType10"
                                *ngIf="cardDetails?.templateType == '10'"
                              >
                                <div
                                  class="template-card cardoption6"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                  [ngClass]="{ 'flex-row-reverse': entityCardFlip }"
                                >
                                  <figure>
                                    <img
                                      class="template-img"
                                      [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                      alt="general-entity"
                                    />
                                  </figure>
                                  <div>
                                    <h3
                                      class="template-title"
                                      (click)="
                                        gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                          ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                          : openLink(
                                              $event,
                                              (card?.recordData)[1]?.attributeName,
                                              1,
                                              hyperlinkAttribute,
                                              txnDataIndex
                                            )
                                      "
                                    >
                                      {{ (card?.recordData)[1]?.value }}
                                    </h3>
                                    <p
                                      class="template-para"
                                      (click)="
                                        gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                          ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                          : openLink(
                                              $event,
                                              (card?.recordData)[2]?.attributeName,
                                              2,
                                              hyperlinkAttribute,
                                              txnDataIndex
                                            )
                                      "
                                    >
                                      {{ (card?.recordData)[2]?.value }}
                                    </p>
                                  </div>
                                </div>
                              </div> -->
                            <!-- <div
                                (click)="expandCard(card, $event, txnDataIndex)"
                                class="col-md-12 templates-type-hor templateType11"
                                *ngIf="cardDetails?.templateType == '11'"
                              >
                                <div
                                  class="template-card cardoption7"
                                  [ngStyle]="{ 'background-color': cardColor }"
                                  entityCardColor
                                  [attrHoveredColors]="attrHoveredColors"
                                  [cardHoveredColor]="cardHoveredColor"
                                  [cardColor]="cardColor"
                                  [cardBorderSize]="cardBorderSize"
                                  [cardBorderRadius]="cardBorderRadius"
                                  [cardBorderColor]="cardBorderColor"
                                  [cardShadow]="cardShadow"
                                  [attributeColors]="colors"
                                  [_otherStyle]="otherStyles"
                                  [conditionArray]="conditionArray"
                                  [card]="card"
                                >
                                  <div class="cardoption7-flex">
                                    <figure>
                                      <img
                                        class="template-img"
                                        [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe"
                                        alt="general-entity"
                                      />
                                    </figure>
                                    <div>
                                      <h3
                                        class="template-title"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[1]?.attributeName,
                                                1,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                      >
                                        {{ (card?.recordData)[1]?.value }}
                                      </h3>
                                      <p
                                        class="template-para"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[2]?.attributeName,
                                                2,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                      >
                                        {{ (card?.recordData)[2]?.value }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="template-btns">
                                    <button
                                      *ngIf="gsiMapping[0]?.displayName"
                                      class="btn btn-viewdetails"
                                      (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[0]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[0]?.displayName }}
                                    </button>
                                    <button
                                      *ngIf="gsiMapping[1]?.displayName"
                                      class="btn btn-viewdetails btn-next-clr"
                                      (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                                      pTooltip=" {{ gsiMapping[1]?.displayName }}"
                                      tooltipPosition="top"
                                      tooltipStyleClass="transcustomtolltipbook"
                                    >
                                      {{ gsiMapping[1]?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </div> -->
                            <app-core-templates
                              [cardDisplay]="'view'"
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                            >
                            </app-core-templates>
                            <app-attribute-templates
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [cardDisplay]="'view'"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              (emitQuantity)="changeQuantity($event?.event, $event?.txnDataIndex)"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                              [cardSelected]="expandTxnDataIndex == txnDataIndex"
                            >
                            </app-attribute-templates>
                            <!-- <app-ninteenattribute-view-templates
                                [txnDataIndex]="txnDataIndex"
                                [gsiMapping]="gsiMapping"
                                (emitExecuteCard)="executeCardEmit($event)"
                                (click)="expandCard(card, $event, txnDataIndex)"
                                [btnColors]="btnColors"
                                      [btnHoverColors]="btnHoverColors"
                                [cardDetails]="cardDetails"
                                [card]="card"
                                [cardColor]="cardColor"
                                [attributeColors]="colors"
                                [_otherStyle]="otherStyles"
                                [conditionArray]="conditionArray"
                                [cardShadow]="cardShadow"
                                [cardBorderSize]="cardBorderSize"
                                [cardBorderRadius]="cardBorderRadius"
                                [cardBorderColor]="cardBorderColor"
                                [attrHoveredColors]="attrHoveredColors"
                                [cardHoveredColor]="cardHoveredColor"
                                [gsiAttrMap]="gsiAttrMap"
                                (openLink1)="openLinkEmit($event, hyperlinkAttribute, txnDataIndex)"
                                (emitQuantity)="changeQuantity($event,txnDataIndex)"
                                [quantity]="quantity"
                                      (addOnClick)="addOnClick($event)"
                              ></app-ninteenattribute-view-templates> -->
                            <app-insurance-templates
                              [cardDisplay]="'view'"
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              (emitQuantity)="changeQuantity($event?.event, $event?.txnDataIndex)"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                            ></app-insurance-templates>
                            <app-e-commerce-template
                              [cardDisplay]="'view'"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardsData]="cardsData"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              (expand)="expandCard(card, $event, txnDataIndex)"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              (emitQuantity)="changeQuantity($event?.event, $event?.txnDataIndex)"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                            ></app-e-commerce-template>
                            <app-features-templates
                              [cardDisplay]="'view'"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardsData]="cardsData"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              (expand)="expandCard(card, $event, txnDataIndex)"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              (emitQuantity)="changeQuantity($event?.event, $event?.txnDataIndex)"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                            ></app-features-templates>
                            <app-banners-template
                              [cardDisplay]="'view'"
                              [txnDataIndex]="txnDataIndex"
                              [gsiMapping]="gsiMapping"
                              (emitExecuteCard)="executeCardEmit($event)"
                              (click)="expandCard(card, $event, txnDataIndex)"
                              [cardDetails]="cardDetails"
                              [card]="card"
                              [cardsData]="cardsData"
                              [cardColor]="cardColor"
                              [attributeColors]="colors"
                              [btnColors]="btnColors"
                              [btnHoverColors]="btnHoverColors"
                              [_otherStyle]="otherStyles"
                              [conditionArray]="conditionArray"
                              (expand)="expandCard(card, $event, txnDataIndex)"
                              [cardShadow]="cardShadow"
                              [cardBorderSize]="cardBorderSize"
                              [cardBorderRadius]="cardBorderRadius"
                              [cardBorderColor]="cardBorderColor"
                              [attrHoveredColors]="attrHoveredColors"
                              [cardHoveredColor]="cardHoveredColor"
                              [quantity]="quantity"
                              (addOnClick)="addOnClick($event)"
                            ></app-banners-template>
                          </div>
                        </div>

                        <div
                          class="entitytemplatesgroup entitytemplatesgroup4"
                          *ngIf="cardDetails?.templateType == '4'"
                        >
                          <div class="entitygroup-info row">
                            <div class="col-12">
                              <ul>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[0]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[0]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[0]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[0]?.attributeName,
                                            0,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[0]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[1]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[1]?.attributeName,
                                            1,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[1]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[2]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[2]?.attributeName,
                                            2,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[2]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[3]?.attributeName,
                                            3,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[3]?.value }}</span
                                  >
                                </li>
                                <li>
                                  <span class="passcolor">{{ (card?.recordData)[4]?.displayName }}</span>
                                  <span
                                    (click)="
                                      gsiAttrMap[(card?.recordData)[4]?.attributeName]
                                        ? gotoTxnPage(gsiAttrMap[(card?.recordData)[4]?.attributeName], card)
                                        : openLink(
                                            $event,
                                            (card?.recordData)[4]?.attributeName,
                                            4,
                                            hyperlinkAttribute,
                                            txnDataIndex
                                          )
                                    "
                                    >{{ (card?.recordData)[4]?.value }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="entitygroup-info linebar templatebtns">
                            <button
                              *ngIf="gsiMapping[0]?.displayName"
                              class="btn btn-viewdetails"
                              (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                            >
                              {{ gsiMapping[0]?.displayName }}
                            </button>
                            <button
                              *ngIf="gsiMapping[1]?.displayName"
                              class="btn btn-viewdetails btn-next-clr"
                              (click)="executeCard(gsiMapping[1], card, txnDataIndex, $event)"
                            >
                              {{ gsiMapping[1]?.displayName }}
                            </button>
                          </div>
                        </div>
                      </ng-container>

                      <div *ngIf="cardDetails?.cardName == 'expand'">
                        <div class="card-expand-e1" *ngIf="cardDetails?.templateType == '1'">
                          <div class="entity-template-t2 entity-template-type" #dropEntityData>
                            <div class="row">
                              <div class="col-4">
                                <div>
                                  <figure class="temptype-img">
                                    <img [src]="(card?.recordData)[0]?.contentUrl | CDNUrlRewritePipe" />
                                  </figure>
                                </div>
                              </div>
                              <div class="col-4">
                                <div>
                                  <ul>
                                    <li>
                                      <span
                                        class="passcolor text-truncate"
                                        pTooltip=" {{ (card?.recordData)[1]?.displayName }}"
                                        tooltipPosition="top"
                                        >{{ (card?.recordData)[1]?.displayName }}</span
                                      >
                                      <span
                                        class="text-truncate"
                                        pTooltip="{{ (card?.recordData)[1]?.value }}"
                                        tooltipPosition="top"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[1]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[1]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[1]?.attributeName,
                                                1,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                        >{{ (card?.recordData)[1]?.value }}</span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        class="passcolor text-truncate"
                                        pTooltip=" {{ (card?.recordData)[2]?.displayName }}"
                                        tooltipPosition="top"
                                        >{{ (card?.recordData)[2]?.displayName }}</span
                                      >
                                      <span
                                        class="text-truncate"
                                        pTooltip="{{ (card?.recordData)[2]?.value }}"
                                        tooltipPosition="top"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[2]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[2]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[2]?.attributeName,
                                                2,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                        >{{ (card?.recordData)[2]?.value }}</span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        class="passcolor text-truncate"
                                        pTooltip=" {{ (card?.recordData)[3]?.displayName }}"
                                        tooltipPosition="top"
                                        >{{ (card?.recordData)[3]?.displayName }}</span
                                      >
                                      <span
                                        class="text-truncate"
                                        pTooltip="{{ (card?.recordData)[3]?.value }}"
                                        tooltipPosition="top"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[3]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[3]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[3]?.attributeName,
                                                3,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                        >{{ (card?.recordData)[3]?.value }}</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col-4">
                                <div>
                                  <ul>
                                    <li>
                                      <span
                                        class="passcolor text-truncate"
                                        pTooltip=" {{ (card?.recordData)[4]?.displayName }}"
                                        tooltipPosition="top"
                                        >{{ (card?.recordData)[4]?.displayName }}</span
                                      >
                                      <span
                                        class="text-truncate"
                                        pTooltip="{{ (card?.recordData)[4]?.value }}"
                                        tooltipPosition="top"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[4]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[4]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[4]?.attributeName,
                                                4,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                        >{{ (card?.recordData)[4]?.value }}</span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        class="passcolor text-truncate"
                                        pTooltip=" {{ (card?.recordData)[5]?.displayName }}"
                                        tooltipPosition="top"
                                        >{{ (card?.recordData)[5]?.displayName }}</span
                                      >
                                      <span
                                        class="text-truncate"
                                        pTooltip="{{ (card?.recordData)[5]?.value }}"
                                        tooltipPosition="top"
                                        (click)="
                                          gsiAttrMap[(card?.recordData)[5]?.attributeName]
                                            ? gotoTxnPage(gsiAttrMap[(card?.recordData)[5]?.attributeName], card)
                                            : openLink(
                                                $event,
                                                (card?.recordData)[5]?.attributeName,
                                                5,
                                                hyperlinkAttribute,
                                                txnDataIndex
                                              )
                                        "
                                        >{{ (card?.recordData)[5]?.value }}</span
                                      >
                                    </li>
                                  </ul>
                                  <div class="entitygroup-info linebar">
                                    <ul>
                                      <li class="pb-0">
                                        <span
                                          class="text-truncate"
                                          pTooltip=" {{ (card?.recordData)[6]?.displayName }}"
                                          tooltipPosition="top"
                                          >{{ (card?.recordData)[6]?.displayName }}</span
                                        >
                                        <span
                                          class="text-truncate"
                                          pTooltip="{{ (card?.recordData)[6]?.value }}"
                                          tooltipPosition="top"
                                          (click)="
                                            gsiAttrMap[(card?.recordData)[6]?.attributeName]
                                              ? gotoTxnPage(gsiAttrMap[(card?.recordData)[6]?.attributeName], card)
                                              : openLink(
                                                  $event,
                                                  (card?.recordData)[6]?.attributeName,
                                                  6,
                                                  hyperlinkAttribute,
                                                  txnDataIndex
                                                )
                                          "
                                          >{{ (card?.recordData)[6]?.value }}</span
                                        >
                                      </li>
                                    </ul>
                                    <button
                                      *ngIf="gsiMapping[0]?.displayName"
                                      class="btn btn-viewdetails"
                                      (click)="executeCard(gsiMapping[0], card, txnDataIndex, $event)"
                                    >
                                      {{ gsiMapping[0]?.displayName }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isDynamicTemplate">
                  <div class="entitytemplates-img dynamic-template-placeholder"
                  [ngClass]="{
                    'no-data': totalLength == 0,
                    'ramco-tenat-no-data': isRamcoTenant,
                    'd-none': isExpanded,
                    'nh-ml-24':
                      sortConfigurations?.position == 'side' ||
                      searchConfigurations?.position == 'side' ||
                      horizontalLayout?.length > 0
                  }"
                  [ngStyle]="{
                    'justify-content': dynamicCardsAlignment
                  }"></div>

                </ng-container>
                <ng-container *ngIf="isExpandDynamicTemplate">   <div class="entitytemplates-img dynamic-template-placeholder"
                  style="display: flex; cursor: pointer; flex-direction: row-reverse;" [ngClass]="{ 'd-none': !isExpanded }"></div></ng-container>
              </div>
              <div class="table-in-card" *ngIf="isTable && !isMccLayout && !chatbot">
                <p-table [value]="tableData">
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="btn-tooltip"
                        pTooltip="{{ col.displayName }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        *ngFor="let col of tableData?.[0]?.recordData"
                        class="table-row"
                      >
                        {{ col.displayName }}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-cols>
                    <tr>
                      <td *ngFor="let col of cols?.recordData" class="table-row">
                        <img
                          [src]="col.contentUrl | CDNUrlRewritePipe"
                          *ngIf="col.contentUrl != null && col.type == 'attribute'"
                          width="40"
                          height="50"
                        />
                        <p
                          class="table-value btn-tooltip"
                          pTooltip="{{ col.value }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                          *ngIf="col.contentUrl === null && col.type == 'attribute'"
                        >
                          {{ col.value }}
                        </p>
                        <div class="table-btns">
                          <button
                            class="table-btn btn-tooltip"
                            pTooltip="{{ col.value[0].value.name }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                            (click)="executeCard(col.value[0].value, cols)"
                            *ngIf="col.type == 'button' && col.value[0]"
                          >
                            {{ col.value[0].value.name }}
                          </button>
                          <button
                            class="table-btn btn-tooltip"
                            pTooltip="{{ col.value[1].value.name }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                            (click)="executeCard(col.value[1].value, cols)"
                            *ngIf="col.type == 'button' && col.value[1]"
                          >
                            {{ col.value[1].value.name }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-container>

            <!-- <mat-paginator
        *ngIf="!chatbot && !isMccLayout"
        class="entity-grid-card-paginator"
        (page)="handlePageEvent($event)"
        [length]="length"
        (page)="fetchCurrentPageDetails($event)"
      >
      </mat-paginator> -->
          </div>
        </div>
        <div class="entity-table" *ngIf="cardDetails?.templateType == 'table'">
          <app-entity-grid-table
          [columnStyles]="columnStyles"
            [headerGsi]="headerGsi"
            [attributeUIElementMap]="attributeUIElementMap"
            [exportConfigurations]="exportConfigurations"
            *ngIf="headerArray.length > 0"
            [longestRecordPos]="longestRecordPos"
            [tableData]="cardsData"
            [nestedRowTableData]="nestedRowTableData"
            [isNestedRow2]="isNestedRow1"
            [enableAnalytics]="enableAnalytics"
            [legendSelected]="legendSelected"
            [enableCompleteRecord]="enableCompleteRecord"
            [legendAlignment]="legendAlignment"
            [oddRowColor]="oddRowColor"
            [evenRowColor]="evenRowColor"
            [widthArray]="widthArray"
            [headerBackground]="headerBackground"
            [nestedRowHeaderBackground]="nestedRowHeaderBackground"
            [nestedRowHeader]="nestedRowHeader"
            [gsiConfigurations]="gsiConfigurations"
            [headerFreeze]="headerFreeze"
            [pagination]="pagination"
            [description]="description"
            [gridLines]="gridLines"
            [tableActionButtons]="tableActionButtons"
            [primaryEntityName]="cardDetails?.primaryEntity?.name"
            [searchConfigurationData]="searchConfigurationData"
            [header]="header"
            [evenRow]="evenRow"
            [oddRow]="oddRow"
            [advanceTableFeatures]="advanceTableFeatures"
            [searchConfigurations]="searchConfigurations"
            [sortConfigurations]="sortConfigurations"
            [filterConfigurations]="filterConfigurations"
            [conditionArray]="conditionArray"
            [paginatorNumber]="paginatorNumber"
            (_updateTableData)="updateTableData($event)"
            (_keyUpSearchField)="keyUpSearchField($event.attr)"
            (_downloadData)="downloadData($event.type, $event.total)"
            (applyFilterAndSortEmit)="applyFilterAndSortEmitFromTable($event)"
            (sortEmit)="sortEmitFilter($event)"
            [headerArray]="headerArray"
            [urlBindingDataConfiguration]="urlBindingDataConfiguration"
            [configurationName]="cardDetails?.cardConfigurationName"
          >
          </app-entity-grid-table>
        </div>

        <diV class="entity-table" *ngIf="kanbanConfiguration && cardsData?.length > 0">
          <app-kanban-view-template
            [kanbanConfiguration]="kanbanConfiguration"
            [cardsData]="cardsData"
            [entityDataRaw]="entityDataRaw"
            [cardDisplay]="'view'"
            [gsiMapping]="gsiMapping"
            (emitExecuteCard)="executeCardEmit($event)"
            [btnColors]="btnColors"
            [cardDetails]="cardDetails"
            [cardColor]="cardColor"
            [attributeColors]="colors"
            [_otherStyle]="otherStyles"
            [conditionArray]="conditionArray"
            [cardShadow]="cardShadow"
            [cardBorderSize]="cardBorderSize"
            [cardBorderRadius]="cardBorderRadius"
            [cardBorderColor]="cardBorderColor"
            [attrHoveredColors]="attrHoveredColors"
            [cardHoveredColor]="cardHoveredColor"
            [quantity]="quantity"
            (recordUpdateEmitter)="applyFilterAndSort(false, false, false, '', cardDetails.id, 0)"
          >
          </app-kanban-view-template>
        </diV>

        <div  class="table-pagination"
        [ngStyle]="{ 'max-width': paginatorTableWidth }"
        [ngClass]="{ 'mw-1296': !isExtended && !myLibrary && !searchFieldsConfigurations?.accordionView && !hideAllOptions }">
          <app-entity-grid-card-paginator
          *ngIf="
          length &&
          pageSize &&
          !isMccLayout &&
          ((cardDetails?.templateType == 'table' && pagination?.showPagination) ||
            ((pagination?.rows ? pagination?.showPagination  :length > 10) && cardDetails?.templateType != 'table'))
        "
            [length]="length"
            [pageSize]="pageSize"
            [paginationStyle]="pagination?.style"
            [paginationJustify]="pagination?.alignment"
            (page)="fetchCurrentPageDetails($event)"
            (pageSizeEmit)="fetchPageSizeDetails($event)"
          >
          </app-entity-grid-card-paginator>
        </div>
      </div>
    </div>
  </span>
</ng-container>
<!-- p-overlay starts -->
  <p-overlayPanel styleClass="filterOverlay" #sort>
    <div class="attr-list1" *ngIf="sort && individual && individual.length > 0">
      <mat-radio-group
        class="order"
        aria-label="Select sort option"
        [(ngModel)]="sortOption"
        (ngModelChange)="applyFilterAndSort(true,true)"
      >
        <mat-radio-button [value]="1">Ascending</mat-radio-button>
        <mat-radio-button [value]="2">Descending</mat-radio-button>
      </mat-radio-group>
      <mat-radio-group
        class="order-option"
        aria-label="Select sort option"
        [(ngModel)]="sortOptionAttr"
        (ngModelChange)="applyFilterAndSort(true,true)"
      >
        <mat-radio-button [value]="attr?.attributeName" *ngFor="let attr of individual; let i = index">{{
          displayNameMap.has(attr?.attributeName) ? displayNameMap.get(attr?.attributeName) : attr?.attributeName
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </p-overlayPanel>
  <p-overlayPanel styleClass="filterOverlay" #savedFilter>
    <span class="search-filter">
      <input type="text" placeholder="Search" />
      <em class="nh-icon nh-icon-search-line"></em>
      <em class="nh-icon nh-icon-x-lg d-none"></em>
    </span>
    <div class="saved-filter-list checkboxes">
      <!-- <button class="clear-btn cursor-pointer" (click)="deleteAllFilters()">
        {{ labels?.Clear + ' ' + labels?.all }}
      </button> -->
      <span class="saved-filter-list-item" *ngFor="let filter of savedFilters; let i = index">
        <p-option
          class="cursor-pointer"
          value="New York"
          inputId="ny"
          [ngClass]="selectedIndex == i ? 'active-filter' : ''"
          (click)="clickFilter(cardDetails?.id, filter, i); closeSavedFilterPanel($event, savedFilter)"
          >{{ filter?.name }}</p-option
        >
        <div class="d-flex nh-column-gap-4">
          <mat-icon class="cursor-pointer" (click)="editFilter(cardDetails?.id, filter)">edit</mat-icon>
          <mat-icon class="cursor-pointer" (click)="shareFilter(filter)">share</mat-icon>
          <mat-icon class="cursor-pointer" (click)="deleteFilter(cardDetails?.id, filter?.id)"
            >close</mat-icon
          >
        </div>
        <!-- <label for="" class="text-truncate">New York3</label> -->
      </span>
    </div>
  </p-overlayPanel>
  <p-overlayPanel styleClass="filterOverlay" #more>
    <span class="search-filter">
      <input type="text" placeholder="Search" [(ngModel)]="attributeVal" (keyup)="searchAttributes()" />
      <em class="nh-icon nh-icon-search-line"></em>
      <em class="nh-icon nh-icon-x-lg d-none"></em>
    </span>
    <ul class="checkboxes">
      <ng-container *ngFor="let attr of cardDetails?.childView?.attributeList; let i = index">
        <li class="checckbox" *ngIf="attr?.canBecomeSearchField && attr?.isSearched">
          <mat-checkbox
            disableRipple
            class="menu-checkbox-option"
            (click)="$event.stopPropagation()"
            [(ngModel)]="attr.isSearchField"
            (change)="addSearchField(i)"
          >
            <div
              class="attr-name text-truncate"
              pTooltip="{{ attr?.attributeDisplayName }}"
              tooltipPosition="top"
              tooltipStyleClass="index-tooltip"
            >
              {{ attr?.attributeDisplayName ? attr?.attributeDisplayName : attr?.attributeName }}
            </div>
          </mat-checkbox>
        </li>
      </ng-container>
    </ul>
  </p-overlayPanel>
<!-- p-overlays end -->

<!-- ng-templates start -->
<ng-template #savedFilterName>
  <div class="title">Saved Filters</div>
  <div class="content">
    <p class="name">Enter Name</p>
    <input placeholder="Write Filter Name" type="text" name="name" [(ngModel)]="filterName" />
  </div>
  <div class="btns" *ngIf="!filteractions">
    <button (click)="openSaveFilter(true); closeDialog()">{{ labels?.Cancel }}</button>
    <button (click)="saveFilter(); closeDialog(); openSaveFilter(true)">{{ labels?.Save }}</button>
  </div>
  <div class="btns" *ngIf="filteractions">
    <button (click)="openSaveFilter(true); openFilters()">{{ labels?.Cancel }}</button>
    <button (click)="saveFilter(); openSaveFilter(true); openFilters()">{{ labels?.Save }}</button>
  </div>
</ng-template>
<ng-template #expandTemplate>
  <app-expand-templates-base
    *ngIf="isExpanded || isExpandedVersion"
    [cardDisplay]="'view'"
    (emitExecuteCard)="executeCardEmit($event)"
    [txnDataIndex]="expandTxnDataIndex"
    (closeCardEmitter)="closeCard($event)"
    [gsiExpanddata]="gsiExpanddata"
    [expandCardDetails]="expandCardDetails"
    [expandBackground]="expandBackground"
    [expandColors]="expandColors"
    [btnColors]="expandBtnColors"
    [btnHoverColors]="expandBtnHoverColors"
    [conditionArrayExpand]="conditionArrayExpand"
    [otherStyles]="stylesExpand"
    [_expandType]="expandType"
    [expandMap]="mappingFields?.expandTemplateTypeData"
    [_recordId]="expandRecordId"
    [attributeUIElementMap]="attributeUIElementMap"
    [expandBoxShadow]="expandBoxShadow"
    [attrhoveredColors]="expandHoveredColors"
    [cardHoveredColor]="expandCardHoveredColor"
    [displayNameMap]="displayNameMap"
  >
  </app-expand-templates-base>
  <app-expand-insurance-templates
    [cardDisplay]="'view'"
    *ngIf="isExpanded || isExpandedVersion"
    (emitExecuteCard)="executeCardEmit($event)"
    [txnDataIndex]="expandTxnDataIndex"
    (closeCardEmitter)="closeCard($event)"
    [gsiExpanddata]="gsiExpanddata"
    [expandCardDetails]="expandCardDetails"
    [expandBackground]="expandBackground"
    [expandColors]="expandColors"
    [conditionArrayExpand]="conditionArrayExpand"
    [expandType]="expandType"
    [otherStyles]="stylesExpand"
    [btnColors]="expandBtnColors"
    [btnHoverColors]="expandBtnHoverColors"
    [expandMap]="mappingFields?.expandTemplateTypeData"
    [_recordId]="expandRecordId"
    [expandBoxShadow]="expandBoxShadow"
    [attrhoveredColors]="expandHoveredColors"
    [cardHoveredColor]="expandCardHoveredColor"
  ></app-expand-insurance-templates>
  <app-expand-ecommerce-templates
    [cardDisplay]="'view'"
    *ngIf="isExpanded || isExpandedVersion"
    (emitExecuteCard)="executeCardEmit($event)"
    [txnDataIndex]="expandTxnDataIndex"
    (closeCardEmitter)="closeCard($event)"
    [gsiExpanddata]="gsiExpanddata"
    [expandCardDetails]="expandCardDetails"
    [expandBackground]="expandBackground"
    [expandColors]="expandColors"
    [conditionArrayExpand]="conditionArrayExpand"
    [expandType]="expandType"
    [otherStyles]="stylesExpand"
    [btnColors]="expandBtnColors"
    [btnHoverColors]="expandBtnHoverColors"
    [expandMap]="mappingFields?.expandTemplateTypeData"
    [_recordId]="expandRecordId"
    [expandBoxShadow]="expandBoxShadow"
    [attrhoveredColors]="expandHoveredColors"
    [cardHoveredColor]="expandCardHoveredColor"
  ></app-expand-ecommerce-templates>
</ng-template>
<ng-template #savedSearch>
  <button class="save-all cursor-pointer" (click)="saveSearch()">
    <em class="nh-icon nh-icon-save1"></em>
  </button>
</ng-template>
<ng-template #savedSearchPanel>
  <div class="entity-history-search" *ngIf="openSavedSearch && searchConfigurations?.showSavedSearch">
    <div class="ehs-header">
      <h6>{{ labels?.Saved + ' ' + labels?.Search }}</h6>
      <a (click)="clearAllSearchRecords()">{{ labels?.Clear + ' ' + labels?.all }}</a>
    </div>
    <div class="ehs-list">
      <div class="ehs-list-item" *ngFor="let search of searchHistoryArray">
        <p class="text-truncate" (click)="saveSearchCall(search)">{{ search }}</p>
        <mat-icon class="cursor-pointer" (click)="clearParticularSearch(search)">close</mat-icon>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #searchFieldsTemplate let-data="data">
  <div class="templates-swiper-side-menu filter-swiper">
    <swiper [config]="sideMenu_swiper_config">
      <ng-template swiperSlide *ngFor="let searchField of data.searchFields" aria-hidden="true">
        <ng-container
          *ngIf="
            searchField?.uiElement != 'date' &&
            searchField.uiElement != 'datetime' &&
            searchField?.uiElement != 'dateonly'
          "
        >
          <span class="count" *ngIf="searchFieldsConfigurations?.showAggregation">{{
            searchField?.value?.length
          }}</span>
          <div [id]="'searchField_' + searchField?.attributeName" class="tbl-accordion cursor-pointer">
            <span
              class="filter-name text-truncate"
              [matMenuTriggerFor]="attributeMenu"
              [ngClass]="{ active: false, 'custom-filter-name': searchField?.filterTemplate == 44 }"
              (click)="keyUpSearchField(searchField)"
            >
              {{
                displayNameMap?.has(searchField?.attributeName)
                  ? displayNameMap.get(searchField?.attributeName)
                  : searchField?.attributeDisplayName
                  ? searchField?.attributeDisplayName
                  : searchField?.attributeName
              }}
            </span>
            <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
              <ng-container
                [ngTemplateOutlet]="stringType"
                [ngTemplateOutletContext]="{ data: { searchField: searchField } }"
              ></ng-container>
            </mat-menu>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            searchField?.uiElement == 'date' ||
            searchField?.uiElement == 'datetime' ||
            searchField?.uiElement == 'dateonly'
          "
          [ngTemplateOutlet]="dateUiControl"
          [ngTemplateOutletContext]="{ data: { searchField: searchField } }"
        ></ng-container>
      </ng-template>
    </swiper>
    <!-- <div class="swiper-pager"></div> -->
    <button class="swiper-btn swiper-prev"><em class="nh-icon nh-icon-chevron-left"></em></button>
    <button class="swiper-btn swiper-next"><em class="nh-icon nh-icon-chevron-right"></em></button>
  </div>
</ng-template>
<ng-template #searchFieldsTemplateWrap let-data="data">
  <ng-container *ngFor="let searchField of data.searchFields" aria-hidden="true">
    <div
      class="tbl-accordion cursor-pointer"
      *ngIf="
        searchField?.uiElement != 'date' &&
        searchField?.uiElement != 'datetime' &&
        searchField?.uiElement != 'dateonly'
      "
      [id]="'searchField_' + searchField?.attributeName"
    >
      <span
        class="filter-name text-truncate"
        [matMenuTriggerFor]="attributeMenu"
        [ngClass]="{ active: false, 'custom-filter-name': searchField?.filterTemplate == 44 }"
        (click)="keyUpSearchField(searchField)"
      >
        {{
          displayNameMap?.has(searchField?.attributeName)
            ? displayNameMap.get(searchField?.attributeName)
            : searchField?.attributeDisplayName
            ? searchField?.attributeDisplayName
            : searchField?.attributeName
        }}
      </span>
      <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
        <ng-container
          *ngIf="
            searchField?.uiElement != 'date' &&
            searchField?.uiElement != 'datetime' &&
            searchField?.uiElement != 'dateonly'
          "
          [ngTemplateOutlet]="stringType"
          [ngTemplateOutletContext]="{ data: { searchField: searchField } }"
        ></ng-container>
      </mat-menu>
    </div>
    <ng-container
      *ngIf="
        searchField?.uiElement == 'date' ||
        searchField?.uiElement == 'datetime' ||
        searchField?.uiElement == 'dateonly'
      "
      [ngTemplateOutlet]="dateUiControl"
      [ngTemplateOutletContext]="{ data: { searchField: searchField } }"
    ></ng-container>
  </ng-container>
</ng-template>
<ng-template #dateUiControl let-data="data">
  <div class="tbl-accordion cursor-pointer">
    <p-calendar
      [name]="data?.searchField?.attributeName"
      [id]="'calendar_' + data?.searchField?.attributeName"
      [placeholder]="
        data?.searchField?.attributeDisplayName
          ? data?.searchField?.attributeDisplayName
          : data?.searchField?.attributeName
      "
      panelStyleClass="search-field-date"
      [readonlyInput]="true"
      appendTo="body"
      (onSelect)="applyDateFilter([$event], data?.searchField)"
      (onClearClick)="applyDateFilter([], data?.searchField)"
      (onTodayClick)="applyDateFilter([$event], data?.searchField)"
      [showButtonBar]="true"
      [showIcon]="true"
      [(ngModel)]="dateSearchFieldValue[data?.searchField?.attributeName]"
    >
      <ng-template pTemplate="date" let-date>
        <div class="day-div">{{ date?.day }}</div>
        <div class="price" *ngIf="data?.searchField?.selectedNestedForDate">
          {{
            data?.searchField?.dateData?.has(date?.day + '/' + date?.month + '/' + date?.year)
              ? data?.searchField?.dateData?.get(date?.day + '/' + date?.month + '/' + date?.year)
              : data?.searchField?.selectedNestedForDate?.selectedNestedAttributeNoSuffixValue
          }}
        </div>
      </ng-template>
    </p-calendar>
  </div>
</ng-template>
<ng-template #stringType let-data="data">
  <span class="search-filter">
    <input
      matInput
      (click)="$event?.stopPropagation()"
      (keyup)="inputSearchFilter.next(data?.searchField)"
      [(ngModel)]="data.searchField.searchValue"
      type="text"
      placeholder="Search"
    />
    <em class="nh-icon nh-icon-search-line"></em>
    <em class="nh-icon nh-icon-x-lg d-none"></em>
  </span>
  <div (scroll)="onScrollSearchField($event, data?.searchField)" class="checkboxes">
    <ng-container *ngFor="let option of data?.searchField?.selectedSearchValues">
      <mat-checkbox
        disableRipple
        class="menu-checkbox-option"
        [checked]="option?.selected"
        (change)="updateSelectedSearchField($event, option, data?.searchField)"
        (click)="$event.stopPropagation()"
      >
        <div
          [id]="'option_' + option?.value"
          class="attr-name text-truncate"
          pTooltip="{{ option?.value }}"
          tooltipPosition="top"
          tooltipStyleClass="index-tooltip"
        >
          {{ option?.value }}
        </div>
      </mat-checkbox>
    </ng-container>
  </div>
</ng-template>
<ng-template #filters>
  <div class="filter-header"
  [ngClass]="{
  'mw-1296': !isExtended && !myLibrary && !searchFieldsConfigurations?.accordionView && !hideAllOptions,
  'nh-mb-24':
    !(
      (searchFieldsConfigurations?.showFilters && selectedSearchFieldIds?.length > 0) ||
      appliedSavedFilters
    ) &&
    hideAllOptions &&
    cardDetails?.templateType !== 'table',
    'nh-mb-8':
        !((searchFieldsConfigurations?.showFilters && selectedSearchFieldIds?.length > 0) || appliedSavedFilters) &&
        !hideAllOptions &&
        cardDetails?.templateType !== 'table',
    'nh-mt-16':
    (sortConfigurations?.position == 'side' ||
      searchConfigurations?.position == 'side' ||
      horizontalLayout?.length > 0) &&
    !myLibrary
  }"
  *ngIf="
    (entitySearchFields?.length > 0 && showSearchFields) ||
    searchFieldsConfigurations?.saveUserFilters ||
    searchFieldsConfigurations?.additionalSearchFields ||
    sortConfigurations?.position == 'top' ||
    searchConfigurations?.position == 'top' ||
    (!searchConfigurations && mappingFields?.search?.length > 0 && showSearch)
  "
>
  <div
    class="filter-header-left filter-header-top"
    [ngClass]="{'with_search': (searchConfigurations?.position == 'top' ||
    (!searchConfigurations && mappingFields?.search?.length > 0 && showSearch)) && !searchFieldsConfigurations?.accordionView,
    'with_sort': sortConfigurations?.position == 'top'
    }"
    *ngIf="
      (entitySearchFields?.length > 0 && showSearchFields) ||
      searchFieldsConfigurations?.saveUserFilters ||
      searchFieldsConfigurations?.additionalSearchFields
    "
  >
    <!-- Search Fields that come from configuration -->
    <ng-container *ngIf="entitySearchFields?.length > 0 && showSearchFields">
      <ng-container
        *ngIf="!searchFieldsConfigurations?.fieldsWrap"
        [ngTemplateOutlet]="searchFieldsTemplate"
        [ngTemplateOutletContext]="{ data: { searchFields: showEntitySearchFields } }"
      ></ng-container>
      <ng-container
        *ngIf="searchFieldsConfigurations?.fieldsWrap"
        [ngTemplateOutlet]="searchFieldsTemplateWrap"
        [ngTemplateOutletContext]="{ data: { searchFields: showEntitySearchFields } }"
      ></ng-container>
    </ng-container>

    <div
      class="tbl-accordion cursor-pointer"
      (click)="savedFilter.toggle($event)"
      *ngIf="searchFieldsConfigurations?.saveUserFilters"
    >
      <span class="filter-name text-truncate saved" [ngClass]="{ active: false }">Saved Filters</span>
    </div>
    <button
      *ngIf="searchFieldsConfigurations?.saveUserFilters"
      class="selected-filters save-all cursor-pointer"
      (click)="openSavedFiltersDialog(savedFilterName)"
    >
      <em class="nh-icon nh-icon-save1"></em>
    </button>
    <div class="tbl-accordion" *ngIf="searchFieldsConfigurations?.saveSharableFilters">
      <button
        class="filter-name sharable-filter text-truncate cursor-pointer"
        (click)="openCreateEqlModal()"
      >
        Create Sharable Filters
      </button>
    </div>

    <div
      class="tbl-accordion cursor-pointer"
      (click)="more.toggle($event)"
      *ngIf="searchFieldsConfigurations?.additionalSearchFields"
    >
      <span class="filter-name text-truncate saved-menu" [ngClass]="{ active: false }">More</span>

      <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
        <ng-container *ngFor="let attr of cardDetails?.childView?.attributeList; let i = index">
          <span class="search-filter">
            <input type="text" placeholder="Search" />
            <em class="nh-icon nh-icon-search-line"></em>
            <em class="nh-icon nh-icon-x-lg d-none"></em>
          </span>
          <div class="checkboxes">
            <mat-checkbox
              disableRipple
              *ngIf="attr?.canBecomeSearchField"
              class="menu-checkbox-option"
              [(ngModel)]="attr.isSearchField"
              (change)="addSearchField(i)"
              (click)="$event.stopPropagation()"
            >
              <div
                class="attr-name text-truncate"
                pTooltip="{{ attr?.attributeDisplayName }}"
                tooltipPosition="top"
                tooltipStyleClass="index-tooltip"
              >
                {{ attr?.attributeDisplayName ? attr?.attributeDisplayName : attr?.attributeName }}
              </div>
            </mat-checkbox>
          </div>
        </ng-container>
      </mat-menu>
    </div>
  </div>
  <div
    class="filter-header-right filter-header-top"
    *ngIf="
      sortConfigurations?.position == 'top' ||
      searchConfigurations?.position == 'top' ||
      (!searchConfigurations && mappingFields?.search?.length > 0 && showSearch)
    "
  >
    <div
      class="tbl-accordion cursor-pointer"
      (click)="sort.toggle($event)"
      *ngIf="sortConfigurations?.position == 'top'"
    >
      <span class="filter-name text-truncate sort" [ngClass]="{ active: false }">{{ labels?.Sort_By || 'Sort By'}}</span>
    </div>
    <ng-container
      *ngIf="
        (searchConfigurations?.position == 'top' ||
        (!searchConfigurations && mappingFields?.search?.length > 0 && showSearch)) && !searchFieldsConfigurations?.accordionView
      "
    >
      <span class="search-filter" [ngClass]="{'search-filter1': searchConfigurations?.isSearching || tenantName?.includes('defisolutions')}" OutsideClick (appOutsideClick)="focusInputField($event)">
        <input
          type="text"
          [placeholder]="
            searchConfigurations?.placeHolderText ? searchConfigurations?.placeHolderText : 'Search'
          "
          [(ngModel)]="attributeValue"
          (keyup)="applyFilterAndSort(true)"
        />
        <em class="nh-icon nh-icon-search-line" *ngIf="attributeValue?.length == 0"></em>
        <em class="nh-icon nh-icon-x-lg" (click)="cancelSearch()" *ngIf="attributeValue?.length != 0"></em>
        <ng-container [ngTemplateOutlet]="savedSearchPanel"></ng-container>
      </span>
      <ng-container
        *ngIf="searchConfigurations?.showSavedSearch"
        [ngTemplateOutlet]="savedSearch"
      ></ng-container>
    </ng-container>
  </div>
  <div class="filtericon d-none" (click)="tbl_conf_collapsebtn = !tbl_conf_collapsebtn">
    <button class="cursor-pointer">
      <em class="nh-icon nh-icon-filter01"></em>
    </button>
  </div>
</div>
<div
  class="selected-filters"
  *ngIf="(searchFieldsConfigurations?.showFilters && selectedSearchFieldIds?.length > 0) || appliedSavedFilters"
  [ngStyle]="{ 'justify-content': searchFieldsConfigurations?.alignment == 'right' ? 'flex-end' : '' }"
  [ngClass]="{
    'mw-1296': !isExtended && !myLibrary && !searchFieldsConfigurations?.accordionView && !hideAllOptions
  }"
>
  <ng-container *ngIf="searchFieldsConfigurations?.showFilters && selectedSearchFieldIds?.length > 0">
    <ng-container *ngFor="let searchFieldId of selectedSearchFieldIds">
      <span
        class="filter-name text-truncate cancel"
        [ngClass]="{ active: false }"
        *ngFor="let search of entitySearchFieldMap.get(searchFieldId)"
        >{{ search }}
        <button>
          <em class="nh-icon nh-icon-x-lg" (click)="clearThisSearchField(searchFieldId, search)"></em></button
      ></span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="appliedSavedFilters">
    <span class="filter-name text-truncate cancel nh-bg-55">
      {{ appliedSavedFilters }}
      <button>
        <em class="nh-icon nh-icon-x-lg" (click)="removeAppliedSavedFilter()"></em></button
    ></span>
  </ng-container>
</div>
</ng-template>
<ng-template #actionbtns>
  <div
    mat-dialog-actions
    class="filterPopUp-actions d-flex nh-column-gap-10 justify-content-end"
    *ngIf="filteractions || accordianfilters"
  >
    <button class="cursor-pointer" (click)="clearAllFeatures()">Clear all</button>
    <button *ngIf="!accordianfilters" mat-dialog-close class="save cursor-pointer">Save</button>
    <button *ngIf="accordianfilters" (click)="searchaccordian()" class="save cursor-pointer">Save</button>
  </div>
</ng-template>
<ng-template #filterPopUp>
  <ng-container [ngTemplateOutlet]="filters"></ng-container>
  <ng-container [ngTemplateOutlet]="actionbtns"></ng-container>
</ng-template>
<!-- ng-templates end -->
<app-loader></app-loader>
<app-toaster></app-toaster>
