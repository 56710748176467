import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DeveloperLessDevelopmentEndpointService } from '../developer-less-development/developer-less-development-endpoint.service';
import { getHostUrl } from '../../generic-folder/generic-functions';
import { dldGSIFullListMapper } from '../mappers/dld/dld-gsi-full-list.mapper';
import { gsiDeMapper } from '../mappers/gsi/gsi-demapper';
import { gsiMapper } from '../mappers/gsi/gsi-mapper';

@Injectable({
  providedIn: 'root',
})
export class SuggestionFacadeService {
  public tabChange = new Subject();
  tabChange$ = this.tabChange.asObservable();

  public changeUnits = new Subject();
  changeUnits$ = this.changeUnits.asObservable();

  //subject for dld entites
  public entites = new Subject();
  entites$ = this.entites.asObservable();

  //subject for dld attributes
  public attributes = new Subject();
  attributes$ = this.attributes.asObservable();

  public agents = new Subject();
  agents$ = this.agents.asObservable();

  private dldEntity = new Subject();
  dldEntity$ = this.dldEntity.asObservable();

  private addAttribute = new Subject();
  addAttribute$ = this.addAttribute.asObservable();

  private dldAgent = new Subject();
  dldAgent$ = this.dldAgent.asObservable();

  public gsiRecommendationForNestedCU = new Subject();
  gsiRecommendationForNestedCU$ = this.gsiRecommendationForNestedCU.asObservable();

  public saveGSICompositeForNestedCU = new Subject();
  saveGSICompositeForNestedCU$ = this.saveGSICompositeForNestedCU.asObservable();

  constructor(private dldEndpointService: DeveloperLessDevelopmentEndpointService) {}

  //switch the tab to DLD Suggestions
  switchTab(tabId: any) {
    this.tabChange.next(tabId);
  }

  //Cu suggestions
  getSuggestions(type?: any, baseObject?: any) {
    /*istanbul ignore else*/
    if (type == 'cu') {
      this.dldEndpointService.getCuRecommendation(baseObject).subscribe((res: any) => {
        this.changeUnits.next(res);
      });
    }
  }

  //Entity Suggestions
  getEntitySuggestion(Gsi?: any, Cu?: any, selectedEntityList?: any[]) {
    this.dldEndpointService.getEntityRecommendation(Gsi, Cu, selectedEntityList).subscribe((res: any) => {
      this.entites.next(res);
    });
  }

  //Attribute Suggestions
  getAttributeSugggestion(Gsi?: any, Cu?: any, Entity?: any, AttributeList?: any) {
    this.dldEndpointService.getAttributeRecommendation(Gsi, Cu, Entity, AttributeList).subscribe((res: any) => {
      this.attributes.next(res);
    });
  }
  //get Agent Suggestions
  getAgentSuggestion(bookName?: any, Gsi?: any, SelectedRoleList?: any) {
    this.dldEndpointService
      .getRolesRecommendations(bookName, Gsi, getHostUrl(), SelectedRoleList)
      .subscribe((res: any) => {
        this.agents.next(res);
      });
  }
  //get Agent suggestions at Cu level
  getAgentSuggestionForCu(cuData?: any) {
    cuData = { ...cuData, hostPortURL: getHostUrl() };
    this.dldEndpointService.getDLDAgentRecommendationsForCU(cuData).subscribe((res: any) => {
      this.agents.next(res);
    });
  }
  // get Agent suggestions at Entity Level
  getAgentSuggestionsForEntity(entityData?: any) {
    this.dldEndpointService.getDLDAgentRecommendationsForEntity(entityData);
  }
  // get Agent suggestions at Attribute Level

  getAgentRecommendationsForAttribute(attrData: any) {
    return this.dldEndpointService.getDLDAgentRecommendationsForAttribute(attrData);
  }
  // saving the DLD imported Entity
  saveDldEntity(entity?: any) {
    this.dldEntity.next(entity);
  }

  //Adding Attribute to the Canvas
  addAttributeToCanvas(attribute?: any) {
    this.addAttribute.next(attribute);
  }

  //adding agent to canvas
  addAgentToCanvas(agent?: any) {
    this.dldAgent.next(agent);
  }

  sendDldFeedbackData(data: any) {
    this.dldEndpointService.sendDldFeedbackData(data).subscribe((res: any) => {});
  }
  getCuRecommendation(baseObject: any, isNested?: boolean, entities?: any) {
    if (isNested) {
      this.dldEndpointService
        .getNestedCuRecommendations({ ...baseObject, hostPortURL: getHostUrl() }, isNested, entities)
        .pipe()
        .subscribe((res: any) => {
          if (res) {
            res = dldGSIFullListMapper(res);
            this.gsiRecommendationForNestedCU.next(res);
          }
        });
    }
  }
  saveGSICompositeAPI(data: any) {
    this.dldEndpointService
      .saveGSICompositeAPI(gsiDeMapper(data))
      .pipe()
      .subscribe((res: any) => {
        if (res) {
          let d = gsiMapper(res);
          this.saveGSICompositeForNestedCU.next(res);
        }
      });
  }
  //Unit test of agent to canvas
  callGetAlldldagent(agent: any) {
    this.dldAgent.next(agent);
  }

  detectAllAttribute(attr: any) {
    this.addAttribute.next(attr);
  }
}
