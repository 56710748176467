import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { TimeComponent } from './time.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import { CalendarModule as PCalendarModule } from 'primeng/calendar';
@NgModule({
  declarations: [TimeComponent],
  imports: [
    ControlsMaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    DateTimePickerModule,
    PCalendarModule,
  ],
  exports: [TimeComponent],
})
export class TimeModule {
  static entry = {
    time: TimeComponent,
  };
}
