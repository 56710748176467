import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-node-expand-entity',
  templateUrl: './node-expand-entity.component.html',
  styleUrls: ['./node-expand-entity.component.scss'],
})
export class NodeExpandEntityComponent implements OnInit, OnDestroy {
  @Input() entity: any;
  entityList: any = {
    data: [],
  };
  constructor() {}

  ngOnInit(): void {
    this.entityList.data.push(this.entity);
  }
  ngOnDestroy(): void {}
}
