import { NgModule } from '@angular/core';
import { AutoIdentifierComponent } from './auto-identifier.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [AutoIdentifierComponent],
  imports: [ControlsMaterialModule],
  exports: [AutoIdentifierComponent],
})
export class AutoIdentifierModule {
  static entry = {
    autoidentifier: AutoIdentifierComponent,
  };
}
