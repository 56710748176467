<ng-template #datetimeValue let-data="data">
  <ng-container [ngSwitch]="attributes[data.index]?.inputType">
    <div class="amst-label" matTooltip="{{ attributes[data.index]?.attributeValue | date }}" . *ngSwitchCase="'date'">
      {{ attributes[data.index]?.attributeValue | date }}
    </div>
    <div
      class="amst-label"
      matTooltip="{{ attributes[data.index]?.attributeValue | date: 'medium' }}"
      *ngSwitchCase="'datetime'"
    >
      {{ attributes[data.index]?.attributeValue | date: 'medium' }}
    </div>
    <!-- <div class="amst-label" *ngSwitchCase="'time'">{{ attributes[data.index]?.attributeValue  |date:'mediumTime'  }}</div> -->
    <div class="amst-label" matTooltip="{{ attributes[data.index]?.attributeValue }}" *ngSwitchDefault>
      {{ attributes[data.index]?.attributeValue }}
    </div>
  </ng-container>
</ng-template>
<ng-template #templateHeader>
  <div class="entity-card-header">
    <div
      class="btn-corner deletee"
      *ngIf="listValues.length > 1 && !settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi"
      (click)="deleteCard($event)"
    >
      <div class="delete-images" *ngIf="settings?.customizeCardconfigData?.isDeleteButton">
        <i class="bi bi-trash3 cursor-pointer"></i>
      </div>
    </div>

    <div
      class="btn-corner"
      *ngIf="listValues.length > 1"
      (click)="onSelectDelete(settings?.customizeCardconfigData?.onSelectGsiConfig?.selectedOnSelectGsi)"
    >
      <div class="delete-images" *ngIf="settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi">
        <i class="bi bi-trash3 cursor-pointer" (click)="deleteCard($event)"></i>
      </div>
    </div>
    <div class="btn-corner" [ngClass]="dCard.isChecked ? 'showbtncheck' : ''">
      <div
        class="uikit-radio"
        *ngIf="
          settings?.selectionType == 'radiobutton' || settings?.customizeCardconfigData?.selectionType == 'radiobutton'
        "
      >
        <label class="radio">
          <input
            type="radio"
            [(checked)]="dCard.isChecked"
            [(value)]="dCard.isChecked"
            (change)="selectedCards($event, 'radiobutton')"
          />
          <div class="box"></div>
        </label>
      </div>
      <div
        class="uikit-radio option3"
        *ngIf="settings?.selectionType == 'checkbox' || settings?.customizeCardconfigData?.selectionType == 'checkbox'"
      >
        <label class="radio">
          <input [(ngModel)]="dCard.isChecked" type="checkbox" (change)="selectedCards($event)" />
          <div class="box"></div>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!showExpand">
  <div class="dynamic-templates" *ngIf="isDynamicTemplate">
    <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
    <div class="entitytemplates-img dynamic-template-placeholder" *ngIf="settings?.selectionType === undefined && settings?.customizeCardconfigData?.selectionType == undefined"></div>
    <div
      class="entitytemplates-img dynamic-template-placeholder"
      [ngClass]="{ 'card-checked': dCard.isChecked }"
      *ngIf="settings?.selectionType !== undefined || settings?.customizeCardconfigData?.selectionType != undefined"
      (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    ></div>
  </div>

  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 'default'"
  >
    <div class="col-12">
      <div class="entity-template-default entity-template-type d-block" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-text title" [ngClass]="{ hide: i?.isHidden }" *ngFor="let i of attributes | keyvalue">
            <ng-container
              *ngIf="i.key >= 0"
              dynamicField
              [formData]="i.value[i.value.attributeName]"
              [group]="i.value[i.value.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 5"
  >
    <div class="col-12">
      <div
        class="entity-template-t5 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="template-text title" [ngClass]="{ hide: attributes[1]?.isHidden }">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 6"
  >
    <div class="col-12">
      <div
        class="entity-template-t6 attributes-font entity-template-t33-2-1 entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title" [ngClass]="{ hide: attributes[0]?.isHidden }">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle" [ngClass]="{ hide: attributes[1]?.isHidden }">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            ></ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 7">
    <div class="col-12">
      <div
        class="entity-template-t33 attributes-font entity-template-t33-2-1 entity-template-7 entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 8"
  >
    <div class="col-12">
      <div
        class="entity-template-t8 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 9">
    <div class="col-12">
      <div
        class="entity-template-t9 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 121">
    <div class="col-12">
      <div
        class="entity-template-t9 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 10"
  >
    <div class="col-12">
      <div
        class="entity-template-t10 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul>
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 11">
    <div class="col-12">
      <div
        class="entity-template-t11 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul>
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 12"
  >
    <div class="col-12">
      <div class="entity-template-t12 entity-template-type d-block" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="template-heading">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="template-text">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 13">
    <div class="col-12">
      <div class="entity-template-t13 entity-template-type d-block" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="template-heading">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="template-text">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="cu-btn-ctn more-info-gap" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 14"
  >
    <div class="col-12">
      <div
        class="entity-template-t14 entity-template-type d-block attributes-font attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 15">
    <div class="col-12">
      <div
        class="entity-template-t15 entity-template-type d-block attributes-font attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="row cu-btn-ctn more-info-gap"
          *ngIf="hasExpand"
          (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
        >
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 16"
  >
    <div class="col-12">
      <div
        class="entity-template-t16 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <ul>
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-12">
            <li class="entity-label top-padding">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 17">
    <div class="col-12">
      <div
        class="entity-template-t17 entity-template-type cu-entity-templates attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list row">
          <ul class="col-6 p-0">
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul class="col-6 p-0">
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-12 p-0">
            <ul>
              <li class="entity-label top-padding">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 18"
  >
    <div class="col-12">
      <div
        class="entity-template-t18 entity-template-type attributes-font cu-entity-templates"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 19">
    <div class="col-12">
      <div
        class="entity-template-t19 entity-template-type attributes-font cu-entity-templates"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="row cu-btn-ctn more-info-gap"
          *ngIf="hasExpand"
          (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
        >
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 20"
  >
    <div class="col-12">
      <div
        class="entity-template-t20 entity-template-type cu-entity-templates attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 21">
    <div class="col-12">
      <div
        class="entity-template-t21 entity-template-type cu-entity-templates attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="row cu-btn-ctn more-info-gap"
          *ngIf="hasExpand"
          (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
        >
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 22"
  >
    <div class="col-12">
      <div class="entity-template-t22 entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list row">
          <ul class="col-6 p-0">
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul class="col-6 p-0 m-auto">
            <li class="text-heading">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="text-content">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="row members">
            <div class="col-6 p-0">
              <ul>
                <li class="member1 pr-8">
                  <ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-6 p-0">
              <ul>
                <li class="member1 pl-8">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 23">
    <div class="col-12">
      <div
        class="entity-template-t23 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list row">
          <ul class="col-6 p-0">
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul class="col-6 p-0 m-auto">
            <li class="title title-gap">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle subtitle-gap">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 24"
  >
    <div class="col-12">
      <div
        class="entity-template-t24 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label entity-label-gap">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 25">
    <div class="col-12">
      <div
        class="entity-template-t25 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label entity-label-gap">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="row cu-btn-ctn more-info-gap top-gap"
          *ngIf="hasExpand"
          (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
        >
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 26"
  >
    <div class="col-12">
      <div
        class="entity-template-t26 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>

          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label entity-label-gap">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 27">
    <div class="col-12">
      <div
        class="entity-template-t27 entity-template-type attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li class="entity-label entity-label-gap">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn more-info-gap top-gap" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 122">
    <div class="col-12">
      <div class="entity-template-t27 entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <ul>
          <li class="template-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li>
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li>
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 28"
  >
    <div class="col-12">
      <div
        class="entity-template-t28 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul class="template-img">
            <li>
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul class="overflow-auto">
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row top-gap">
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-12 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 29">
    <div class="col-12">
      <div
        class="entity-template-t29 entity-template-t28 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul class="template-img">
            <li>
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
          <ul class="overflow-auto">
            <li class="title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row top-gap">
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-12 p-0">
            <ul>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 30"
  >
    <div class="col-12">
      <div
        class="entity-template-t30 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <ul class="content">
              <li class="title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 31">
    <div class="col-12">
      <div
        class="entity-template-t31 entity-template-t30 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <ul class="content">
              <li class="title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
          <div class="top-gap cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <button class="btn btn-tooltip">
              More info
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 32"
  >
    <div class="col-12">
      <div
        class="entity-template-t30 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <ul class="content">
              <li class="title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 33">
    <div class="col-12">
      <div
        class="entity-template-t32 entity-template-t30 attributes-font entity-template-type"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="ul-list">
          <ul>
            <li class="template-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <ul class="content">
              <li class="title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="entity-label">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
          <div class="top-gap cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <button class="btn btn-tooltip">
              More info
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 130"
  >
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-t33-2-1 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 131">
    <div class="col-12">
      <div
        class="entity-template-t33 attributes-font entity-template-t33-2-1 entity-template-131 entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <button class="btn btn-tooltip">
              More info
            </button>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 132"
  >
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-type d-block attributes-font entity-template-t33-2-1 template-type-132"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="card-title title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-content subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-title title">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 133">
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-type d-block attributes-font entity-template-t33-2-1 template-type-132"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="card-title title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-content subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-title title">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 134"
  >
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-t33-4-1 entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="card-title" [ngClass]="{ hide: attributes[0]?.isHidden }">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-content" [ngClass]="{ hide: attributes[1]?.isHidden }">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-title" [ngClass]="{ hide: attributes[2]?.isHidden }">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-content1" [ngClass]="{ hide: attributes[3]?.isHidden }">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 135">
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-type d-block cu-entity-templates attributes-font template-type-135"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="card-title title title-gap">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-content subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="card-title title title-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates attributes-font type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 136"
  >
    <div class="col-12">
      <div
        class="entity-template-t33 template-type-136 entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="title">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <ul class="row justify-content-between entity-labels">
            <li class="col-lg-6 entity-label">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="col-lg-6 entity-label">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 137">
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-type d-block template-type-137 attributes-font"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle subtitle-gap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="title title-gap">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label entity-label-gap">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn more-info-gap" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates row"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 138"
  >
    <div class="col-12">
      <div
        class="entity-template-t33 template-type-138 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-lg-6">
            <ul>
              <li class="title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul>
              <li class="title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ul class="row members">
          <li class="entity-label col-lg-6">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label col-lg-6">
            <ng-container
              *ngIf="attributes[5]"
              dynamicField
              [formData]="attributes[5][attributes[5].attributeName]"
              [group]="attributes[5][attributes[5].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
              <div class="onselect-gsi btnz-view" *ngFor="let currEvent of currentEventDataArray[5]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                  [matTooltipPosition]="'above'"
                  [matTooltipClass]="{'cust-toolTipPop':true}"
                  matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 139">
    <div class="col-12">
      <div
        class="entity-template-t33 template-type-138 template-type-139 attributes-font entity-template-type d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <ul>
          <li class="title">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="subtitle">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-lg-6 title">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 title">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ul class="row">
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="entity-label">
            <ng-container
              *ngIf="attributes[5]"
              dynamicField
              [formData]="attributes[5][attributes[5].attributeName]"
              [group]="attributes[5][attributes[5].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="row cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <button class="btn btn-tooltip">
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 181">
    <div class="col-12">
      <div
        class="entity-template-t33 entity-template-type mh-100 d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <div class="entity-template-t181">
          <section class="row expand-head">
            <div class="col-lg-6">
              <div class="row primary-list">
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[0]"
                        dynamicField
                        [formData]="attributes[0][attributes[0].attributeName]"
                        [group]="attributes[0][attributes[0].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[1]"
                        dynamicField
                        [formData]="attributes[1][attributes[1].attributeName]"
                        [group]="attributes[1][attributes[1].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[2]"
                        dynamicField
                        [formData]="attributes[2][attributes[2].attributeName]"
                        [group]="attributes[2][attributes[2].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[3]"
                        dynamicField
                        [formData]="attributes[3][attributes[3].attributeName]"
                        [group]="attributes[3][attributes[3].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[4]"
                        dynamicField
                        [formData]="attributes[4][attributes[4].attributeName]"
                        [group]="attributes[4][attributes[4].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[5]"
                        dynamicField
                        [formData]="attributes[5][attributes[5].attributeName]"
                        [group]="attributes[5][attributes[5].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[6]"
                        dynamicField
                        [formData]="attributes[6][attributes[6].attributeName]"
                        [group]="attributes[6][attributes[6].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[7]"
                        dynamicField
                        [formData]="attributes[7][attributes[7].attributeName]"
                        [group]="attributes[7][attributes[7].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-xl-4">
                  <ul>
                    <li>
                      <ng-container
                        *ngIf="attributes[8]"
                        dynamicField
                        [formData]="attributes[8][attributes[8].attributeName]"
                        [group]="attributes[8][attributes[8].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName], $event)"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <ul class="note">
                <li>
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="secondary-list">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[10]"
                      dynamicField
                      [formData]="attributes[10][attributes[10].attributeName]"
                      [group]="attributes[10][attributes[10].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[11]"
                      dynamicField
                      [formData]="attributes[11][attributes[11].attributeName]"
                      [group]="attributes[11][attributes[11].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[12]"
                      dynamicField
                      [formData]="attributes[12][attributes[12].attributeName]"
                      [group]="attributes[12][attributes[12].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[13]"
                      dynamicField
                      [formData]="attributes[13][attributes[13].attributeName]"
                      [group]="attributes[13][attributes[13].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[14]"
                      dynamicField
                      [formData]="attributes[14][attributes[14].attributeName]"
                      [group]="attributes[14][attributes[14].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[15]"
                      dynamicField
                      [formData]="attributes[15][attributes[15].attributeName]"
                      [group]="attributes[15][attributes[15].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div class="tertiary-list">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-xl-3">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[16]"
                      dynamicField
                      [formData]="attributes[16][attributes[16].attributeName]"
                      [group]="attributes[16][attributes[16].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[17]"
                      dynamicField
                      [formData]="attributes[17][attributes[17].attributeName]"
                      [group]="attributes[17][attributes[17].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[18]"
                      dynamicField
                      [formData]="attributes[18][attributes[18].attributeName]"
                      [group]="attributes[18][attributes[18].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="col-md-6 col-lg-4 col-xl-3 cu-btn-ctn"
                *ngIf="hasExpand"
                (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
              >
                <button class="btn btn-tooltip">View More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == 182">
    <div class="col-12">
      <div
        class="entity-template-t182 entity-template-t33 entity-template-type mh-100 d-block"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <section class="expand-head">
          <ul class="primary-list">
            <li>
              <ng-container
                *ngIf="attributes[0]"
                dynamicField
                [formData]="attributes[0][attributes[0].attributeName]"
                [group]="attributes[0][attributes[0].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="secondary-list">
            <li>
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="tertiary-list">
            <li>
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <ng-container
                *ngIf="attributes[6]"
                dynamicField
                [formData]="attributes[6][attributes[6].attributeName]"
                [group]="attributes[6][attributes[6].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="col-12 cu-btn-ctn" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <button class="btn btn-tooltip">View More</button>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates tourism-view-308"  (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)" *ngIf="templateType == 308">
    <div class="tourism-card dynamic-card-block">
      <div class="tourism-card-row entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

        <div class="tourism-banner-img">
          <ul>
            <li class="tourism-img card-logo">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
        </div>
        <div class="tourism-col1">
          <ul>
            <li
              class="tourism-h3 tourism-txt-truncate only-value"
              pTooltip="{{ attributes[1]?.attributeValue }}"
              tooltipPosition="top"
            >
              {{ attributes[1]?.attributeValue }}
            </li>
            <li class="tourism-h6 tourism-txt-truncate only-value">
              {{ attributes[2]?.attributeValue }}
            </li>
          </ul>

          <div class="custom-flex">
            <div class="flex-left">
              <ul>
                <span>{{ attributes[3]?.attr_displayName }}</span>
                <li class="tourism-p tourism-txt-truncate only-value">
                  {{ attributes[3]?.attributeValue }}
                </li>
                <span>{{ attributes[4]?.attr_displayName }}</span>
                <li class="tourism-p tourism-txt-truncate only-value">
                  {{ attributes[4]?.attributeValue }}
                </li>
              </ul>
            </div>
            <div class="flex-right">
              <ul>
                <span>{{ attributes[5]?.attr_displayName }}</span>
                <li class="tourism-p tourism-txt-truncate only-value">
                  {{ attributes[5]?.attributeValue }}
                </li>
                <span>{{ attributes[6]?.attr_displayName }}</span>
                <li class="tourism-p tourism-txt-truncate only-value">
                  {{ attributes[6]?.attributeValue }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tourism-col-2">
          <ul>
            <li class="attribute-value">
              {{ attributes[7]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[8]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[9]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[10]?.attributeValue }}
            </li>
            <li class="attribute-value">
              {{ attributes[11]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[12]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[13]?.attributeValue }}
            </li>

            <li class="attribute-value">
              {{ attributes[14]?.attributeValue }}
            </li>
          </ul>
        </div>
        <div class="tourism-col-3">
          <div class="ratings">
            <div class="rating-left">
              <ul>
                <li class="tourism-rating text-truncate only-value">
                  {{ attributes[15]?.attributeValue }}
                </li>
              </ul>
            </div>
            <div class="rating-right">
              <ul>
                <li class="tourism-rating-total text-truncate only-value">
                  {{ attributes[16]?.attributeValue }}
                </li>
              </ul>
            </div>
          </div>
          <ul class="final-details">
            <span class="start-text text-truncate">{{ attributes[17]?.attr_displayName }}</span>
            <li
              class="price-text attribute-value"
              pTooltip="{{ attributes[17]?.attributeValue }}"
              tooltipPosition="top"
            >
              {{ attributes[17]?.attributeValue }}
            </li>
            <li class="end-text lineclamp attribute-value">
              {{ attributes[18]?.attributeValue }}
            </li>
            <li class="note-text attribute-value">
              {{ attributes[19]?.attributeValue }}
            </li>
            <!-- <li class="tourism-txt-truncate only-value">
                value
              </li>
              <li class="tourism-txt-truncate only-value">
                value
              </li>
              <li class="tourism-txt-truncate only-value">
                value
              </li>
              <li class="tourism-txt-truncate only-value">
                value
              </li> -->
          </ul>
          <div class="d-none price-section-tab">
            <div class="price-text-section">
              <span class="start-text text-truncate">{{ attributes[17]?.attr_displayName }}</span>
              <li class="end-text lineclamp attribute-value">
                {{ attributes[18]?.attributeValue }}
              </li>
            </div>
            <li class="price-text attribute-value">
              {{ attributes[17]?.attributeValue }}
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates row"
    *ngIf="templateType == 303"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div class="col-12">
      <div
        class="entity-template-type tourism-wireframe tourism-main-303"
        [ngClass]="{ 'card-checked': dCard.isChecked }"
      >
        <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
        <div class="tourism-view-303">
          <div class="tourism-wireframe-left">
            <div class="tmwf-left-side">
              <div class="tourism-wireframe-left-top">
                <ul class="tmwf-main-img">
                  <li class="tmwf-img">
                    <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
                <div class="tmwf-details">
                  <ul>
                    <li class="tmwf-title">
                      {{ attributes[1]?.attributeValue }}
                    </li>
                  </ul>
                  <div class="tmwf-more-details">
                    <div>
                      <span>{{ attributes[2]?.attr_displayName }}</span>
                      <ul>
                        <li>
                          {{ attributes[2]?.attributeValue }}
                        </li>
                      </ul>
                    </div>

                    <div>
                      <span>{{ attributes[3]?.attr_displayName }}</span>
                      <ul>
                        <li>
                          {{ attributes[3]?.attributeValue }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span>{{ attributes[4]?.attr_displayName }}</span>
                      <ul>
                        <li>
                          {{ attributes[4]?.attributeValue }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-none tmwf-more-details tmwf-more-details-mobile">
                  <div>
                    <span>{{ attributes[2]?.attr_displayName }}</span>
                    <ul>
                      <li>
                        {{ attributes[2]?.attributeValue }}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <span>{{ attributes[3]?.attr_displayName }}</span>
                    <ul>
                      <li>
                        {{ attributes[3]?.attributeValue }}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <span>{{ attributes[4]?.attr_displayName }}</span>
                    <ul>
                      <li>
                        {{ attributes[4]?.attributeValue }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tourism-wireframe-left-bottom">
                <div class="tourism-wireframe-left-bottom-sec1">
                  <div class="twlb-btm-field">
                    <ng-container
                      *ngIf="attributes[5]"
                      dynamicField
                      [formData]="attributes[5][attributes[5].attributeName]"
                      [group]="attributes[5][attributes[5].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="twlb-btm-field">
                    <ng-container
                      *ngIf="attributes[6]"
                      dynamicField
                      [formData]="attributes[6][attributes[6].attributeName]"
                      [group]="attributes[6][attributes[6].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="twlb-btm-field">
                    <ng-container
                      *ngIf="attributes[7]"
                      dynamicField
                      [formData]="attributes[7][attributes[7].attributeName]"
                      [group]="attributes[7][attributes[7].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tourism-wireframe-left-bottom-sec2">
                  <div class="twlb-btm-field">
                    <span>{{ attributes[8]?.attr_displayName }}</span>
                    <ul>
                      <li>
                        {{ attributes[8]?.attributeValue }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tmwf-wireframe-list">
              <ul>
                <li>
                  {{ attributes[9]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[10]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[11]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[12]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[13]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[14]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[15]?.attributeValue }}
                </li>
                <li>
                  {{ attributes[16]?.attributeValue }}
                </li>
              </ul>
            </div>
          </div>
          <div class="tourism-wireframe-right">
            <div class="price-flex">
              <div class="price-left">
                <div class="tmwf-price-list">
                  <span>{{ attributes[18]?.attr_displayName }}</span>
                  <ul>
                    <li class="tmwf-price-img">
                      <img [src]="contentUrl[18] | CDNUrlRewritePipe" alt="" />
                    </li>
                    <li class="tmwf-price-title text-truncate">
                      {{ attributes[18]?.attributeValue }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="price-right">
                <div class="tmwf-price-list tmwf-price-list-2">
                  <span>{{ attributes[20]?.attr_displayName }}</span>
                  <ul>
                    <li class="tmwf-price-img">
                      <img [src]="contentUrl[20] | CDNUrlRewritePipe" alt="" />
                    </li>
                    <li class="tmwf-price-title truncated-tmwf-price-title">
                      {{ attributes[20]?.attributeValue }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="tmwf-price-list tmwf-price-list-3">
              <span>{{ attributes[22]?.attr_displayName }}</span>
              <ul>
                <li class="tmwf-price-img">
                  <img [src]="contentUrl[22] | CDNUrlRewritePipe" alt="" />
                </li>
                <li class="tmwf-price-title">
                  {{ attributes[22]?.attributeValue }}
                </li>
              </ul>
            </div>
            <div class="divider"></div>
            <div class="tmwf-btprice-list">
              <div class="btprice-left">
                <span class="tmwf-info-price">{{ attributes[23]?.attr_displayName }}</span>
              </div>
              <div class="btprice-right">
                <ul>
                  <li>
                    {{ attributes[23]?.attributeValue }}
                  </li>
                </ul>
              </div>
              <div class="btprice-left">
                <span class="tmwf-info-price">{{ attributes[24]?.attr_displayName }}</span>
              </div>
              <div class="btprice-right">
                <ul>
                  <li>
                    {{ attributes[24]?.attributeValue }}
                  </li>
                </ul>
              </div>
              <div class="btprice-left">
                <span class="tmwf-info-price tmwf-info-title">{{ attributes[25]?.attr_displayName }}</span>
              </div>
              <div class="btprice-right">
                <ul>
                  <li class="tmwf-info-title">
                    {{ attributes[25]?.attributeValue }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitys-medical type-templates entity-001"
    *ngIf="templateType == 304"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div class="entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->
      <article class="entity-card-inner">
        <ul class="cards-img">
          <li class="tmwf-img"><img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /></li>
        </ul>

        <div class="cards-list">
          <ul>
            <li class="tmwf-titless">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </div>

  <div
    class="entitys-medical type-templates row entity-002"
    *ngIf="templateType == 305"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div class="entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->

      <article class="entity-card-inner">
        <ul class="cards-img">
          <li class="tmwf-img"><img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /></li>
        </ul>

        <div class="cards-list">
          <ul>
            <li class="tmwf-titless">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </div>

  <!-- EMRI -->

  <div class="col-12 type-templates row" *ngIf="templateType == 322" [ngClass]="{ 'card-checked': dCard.isChecked }">
    <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
    <div class="amst-card amst-gmap-card nh-bg-50">
      <div class="amst-card-head">
        <ul class="">
          <li class="tmwf-img"><img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /></li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont amst-label-gmap">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li class="amst-label-cont amst-label-full">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <div class="amst-label">
              {{ attributes[2]?.attributeValue }}
            </div>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[3]?.attr_displayName }}</div>
            <div class="amst-label">{{ attributes[3]?.attributeValue }}</div>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[4]?.attr_displayName }}</div>
            <div class="amst-label">{{ attributes[4]?.attributeValue }}</div>
          </li>
          <li class="amst-label-cont amst-bold-title">
            <div class="amst-label-title">{{ attributes[5]?.attr_displayName }}</div>
            <div class="amst-label amst-danger-label">{{ attributes[5]?.attributeValue }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 type-templates" *ngIf="templateType == 323" [ngClass]="{ 'card-checked': dCard.isChecked }">
    <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
    <div class="amst-card amst-gmap-card amst-extra-card nh-bg-50">
      <div class="amst-card-head">
        <ul class="amst-title-icon">
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont amst-label-full amst-bold-title">
            <div class="amst-label-title">{{ attributes[1]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 1 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 2 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[3]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 3 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[4]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 4 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[5]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 5 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[6]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 6 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[7]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 7 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[8]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 8 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[9]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 9 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[10]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 10 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[11]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 11 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[12]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 12 } }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="col-12 type-templates row entity-template-type"
    *ngIf="templateType == 324"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div class="amst-card nh-bg-50">
      <div class="amst-card-head">
        <ul class="amst-title-icon">
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[1]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 1 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 2 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[3]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 3 } }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-12 type-templates" *ngIf="templateType == 325" [ngClass]="{ 'card-checked': dCard.isChecked }">
    <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
    <div class="amst-card amst-gmap-card amst-extra-card amst-extraV2-card nh-bg-50">
      <div class="amst-card-head">
        <ul class="amst-title-icon">
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont amst-label-full amst-bold-title">
            <div class="amst-label-title">{{ attributes[1]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 1 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 2 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[3]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 3 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[4]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 4 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[5]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 5 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[6]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 6 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[7]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 7 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[8]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 8 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[9]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 9 } }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 type-templates" *ngIf="templateType == 326" [ngClass]="{ 'card-checked': dCard.isChecked }">
    <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
    <div class="amst-card amst-gmap-card amst-extra-card amst-extraV3-card nh-bg-50">
      <div class="amst-card-head">
        <ul class="amst-title-icon">
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont amst-label-full amst-bold-title">
            <div class="amst-label-title">{{ attributes[1]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 1 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 2 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[3]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 3 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label amst-redlabel-cont">
            <div class="amst-label-title">{{ attributes[4]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 4 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[5]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 5 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[6]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 6 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[7]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 7 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[8]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 8 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[9]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 9 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[10]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 10 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[11]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 11 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont odd-label">
            <div class="amst-label-title">{{ attributes[12]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 12 } }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="col-12 type-templates row entity-template-type"
    *ngIf="templateType == 327"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div class="amst-card amst-culumn2-card nh-bg-50 amst-card">
      <div class="amst-card-head">
        <ul class="amst-title-icon">
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <div class="amst-card-title">
          {{ title }}
        </div>
      </div>
      <div class="amst-card-body">
        <ul>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[1]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 1 } }"
            ></ng-template>
          </li>
          <li class="amst-label-cont">
            <div class="amst-label-title">{{ attributes[2]?.attr_displayName }}</div>
            <ng-template
              [ngTemplateOutlet]="datetimeValue"
              [ngTemplateOutletContext]="{ data: { index: 2 } }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Ends EMRI -->

  <div
    class="entitys-medical type-templates row entity-003"
    *ngIf="templateType == 306"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div class="col-12">
      <div class="entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->

        <article class="entity-card-inner">
          <div class="card-top">
            <ul class="cards-img">
              <li class="tmwf-img">
                <img *ngIf="contentUrl?.[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
                <img *ngIf="!(contentUrl?.[1])" src="../../../../../assets/default-images/Laboratory-2.svg" alt="" />
              </li>
            </ul>
            <ul class="cards-list">
              <li class="card-heading">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subheading">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-sub">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-bottom">
            <div class="card-price">
              <ul>
                <li class="price-rate">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-price">
              <ul>
                <li class="price-rates">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
  <div
    class="entitys-medical type-templates row"
    *ngIf="templateType == 307"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div class="entity-template-type entity-004" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->

      <!-- Write your Code here-->
      <article class="card-top">
        <div class="card-tab-view">
          <ul class="cards-img">
            <li class="tmwf-img">
              <img *ngIf="contentUrl?.[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              <img *ngIf="!(contentUrl?.[1])" src="../../../../../assets/default-images/card-add.svg" alt="" />
            </li>
          </ul>
          <div class="cards-inner">
            <ul class="cards-list">
              <li class="card-heading">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subheading">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <div class="card-inner-list">
              <div class="list-inner">
                <ul>
                  <li class="list-date">
                    <ng-container
                      *ngIf="attributes[3]"
                      dynamicField
                      [formData]="attributes[3][attributes[3].attributeName]"
                      [group]="attributes[3][attributes[3].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="list-inner">
                <ul>
                  <li class="list-date">
                    <ng-container
                      *ngIf="attributes[4]"
                      dynamicField
                      [formData]="attributes[4][attributes[4].attributeName]"
                      [group]="attributes[4][attributes[4].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="list-inner">
                <ul>
                  <li class="card-add">
                    <ng-container
                      *ngIf="attributes[5]"
                      dynamicField
                      [formData]="attributes[5][attributes[5].attributeName]"
                      [group]="attributes[5][attributes[5].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="card-iner-subtext">
              <ul>
                <li class="small-text">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card-tab-view">
          <div class="card-inner-left">
            <div class="inner-top">
              <div class="inner-left-sp">
                <ul class="card-imgs">
                  <li class="tmwf-img">
                    <img *ngIf="contentUrl?.[8]" [src]="contentUrl[8] | CDNUrlRewritePipe" alt="" />
                    <img *ngIf="!(contentUrl?.[8])" src="../../../../../assets/default-images/user.png" alt="" />
                  </li>
                </ul>

                <ul class="cards-list">
                  <li class="heading-location">
                    <ng-container
                      *ngIf="attributes[8]"
                      dynamicField
                      [formData]="attributes[8][attributes[8].attributeName]"
                      [group]="attributes[8][attributes[8].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="inner-left-sp sp-top">
                <ul class="card-imgs">
                  <li class="tmwf-img">
                    <img *ngIf="contentUrl?.[10]" [src]="contentUrl[10] | CDNUrlRewritePipe" alt="" />
                    <img *ngIf="!(contentUrl?.[10])" src="../../../../../assets/default-images/location.png" alt="" />
                  </li>
                </ul>
                <ul class="cards-list">
                  <li class="heading-home">
                    <ng-container
                      *ngIf="attributes[10]"
                      dynamicField
                      [formData]="attributes[10][attributes[10].attributeName]"
                      [group]="attributes[10][attributes[10].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="inner-bottom">
              <div class="inner-left-sp">
                <span *ngIf="attributes[11]"></span>
                <ul class="cards-list">
                  <li class="card-normal-text">
                    <ng-container
                      *ngIf="attributes[11]"
                      dynamicField
                      [formData]="attributes[11][attributes[11].attributeName]"
                      [group]="attributes[11][attributes[11].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="inner-left-sp">
                <span *ngIf="attributes[12]"></span>

                <ul class="cards-list">
                  <li class="card-normal-text">
                    <ng-container
                      *ngIf="attributes[12]"
                      dynamicField
                      [formData]="attributes[12][attributes[12].attributeName]"
                      [group]="attributes[12][attributes[12].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="inner-left-sp">
                <span *ngIf="attributes[13]"></span>

                <ul class="cards-list">
                  <li class="card-normal-text">
                    <ng-container
                      *ngIf="attributes[13]"
                      dynamicField
                      [formData]="attributes[13][attributes[13].attributeName]"
                      [group]="attributes[13][attributes[13].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-inner-rate">
            <div class="inner-rate">
              <ul>
                <li class="price-main">
                  <ng-container
                    *ngIf="attributes[14]"
                    dynamicField
                    [formData]="attributes[14][attributes[14].attributeName]"
                    [group]="attributes[14][attributes[14].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="inner-rate rate-margin">
              <ul>
                <li class="price-offer">
                  <ng-container
                    *ngIf="attributes[15]"
                    dynamicField
                    [formData]="attributes[15][attributes[15].attributeName]"
                    [group]="attributes[15][attributes[15].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
  <div
    class="entitytemplates type-templates"
    *ngIf="templateType == 309"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div>
      <div class="entity-template-type parac-sq-card" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->

        <!-- Write your Code here-->
        <ul class="parac-sq-card-img">
          <li class="parac-sq-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <ul class="parac-sq-card-body">
          <li class="parac-sq-title">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-sq-sub-title">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-sq-ofr-price">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-sq-gsi-link" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <a href="javascript:;">
              Know More
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates template-310"
    *ngIf="templateType == 310"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div>
      <div class="entity-template-type parac-card" [ngClass]="{ 'card-checked': dCard.isChecked }">
        <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->

        <!-- Write your Code here-->
        <ul class="parac-card-img">
          <li class="parac-img">
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
        <ul class="parac-card-body">
          <li class="parac-title">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-sub-title text-truncate">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-info">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-price">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-ofr-price">
            <ng-container
              *ngIf="attributes[5]"
              dynamicField
              [formData]="attributes[5][attributes[5].attributeName]"
              [group]="attributes[5][attributes[5].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-quantity">
            <ng-container
              *ngIf="attributes[6]"
              dynamicField
              [formData]="attributes[6][attributes[6].attributeName]"
              [group]="attributes[6][attributes[6].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-gsi-link" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
            <a href="javascript:;">
              Know More
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates template-311"
    *ngIf="templateType == 311"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
    (click)="clickOnCard($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
  >
    <div class="entity-template-type doctor-card-template" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <!-- <ng-template [ngTemplateOutlet]="templateHeader"></ng-template> -->
      <div class="doctor-details">
        <ul class="image">
          <li class="logo">
            <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /><img
              *ngIf="!contentUrl[1]"
              src="../../../../../assets/default-images/default-image.png"
              alt=""
            />
          </li>
        </ul>
        <ul class="details">
          <li class="name">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="description text-truncate">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="qualification text-truncate">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="doctor-template-body">
        <div class="lang-loc">
          <ul class="language">
            <li class="member text-truncate">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="location">
            <li class="member text-truncate">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="fee-details">
          <ul class="fee">
            <li class="member text-truncate">
              <ng-container
                *ngIf="attributes[6]"
                dynamicField
                [formData]="attributes[6][attributes[6].attributeName]"
                [group]="attributes[6][attributes[6].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="price">
            <li class="member text-truncate">
              <ng-container
                *ngIf="attributes[7]"
                dynamicField
                [formData]="attributes[7][attributes[7].attributeName]"
                [group]="attributes[7][attributes[7].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="gsi-link" *ngIf="hasExpand" (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)">
          <a href="javascript:;">
            Know More
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates"
    *ngIf="templateType == 312"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div
      class="entity-template-type patient-card-v2 dynamic-card-block"
      [ngClass]="{ 'card-checked': dCard.isChecked }"
    >
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

      <div class="patient-card-top">
        <div class="patient-card-img">
          <ul>
            <li class="patient-card-img card-logo">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
          </ul>
        </div>
        <div class="patient-card-details">
          <div class="patient-card-heading">
            <ul>
              <li class="only-value patient-card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="online card-logo">
                <ul>
                  <li class="online-img">
                    <img *ngIf="contentUrl[3]" [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="online-txt Attribute-value">
                    <ng-container
                      *ngIf="attributes[3]"
                      dynamicField
                      [formData]="attributes[3][attributes[3].attributeName]"
                      [group]="attributes[3][attributes[3].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="patient-card-content">
            <div class="patient-info-list">
              <ul>
                <li class="patient-value Attribute-value">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="patient-info-list">
              <ul>
                <li class="patient-value Attribute-value">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="patient-info-list">
              <ul>
                <li class="patient-value Attribute-value">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="patient-info-list">
              <ul>
                <li class="patient-value Attribute-value">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="patient-bottom-content">
        <div class="p-content-sec">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[8]"
                dynamicField
                [formData]="attributes[8][attributes[8].attributeName]"
                [group]="attributes[8][attributes[8].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-content-sec">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[9]"
                dynamicField
                [formData]="attributes[9][attributes[9].attributeName]"
                [group]="attributes[9][attributes[9].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates hdfc-entity-cards"
    *ngIf="templateType == 313"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div class="entity-template-type" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

      <div class="hdfc-card">
        <div class="hdfc-card-header">
          <div class="hdfc-card-logo">
            <ul>
              <li>
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="hdfc-card-header-right">
            <div class="hdfc-card-heading">
              <ul class="hdfc-card-heading-lists">
                <li class="hdfc-card-h1">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="hdfc-card-h2">
                  <ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="online-section">
                <li class="online-img">
                  <img *ngIf="contentUrl[4]" [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                </li>
                <li class="online-txt">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-gsiBtn" *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <ul *ngFor="let currEvent of currentEventDataArray[4]">
                <li class="btn" (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)">
                  <span class="hdfc-card-event-gsi-box">{{
                    currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-gsiBtn" *ngIf="!currentEventDataArray?.[5]?.length">
              <ul>
                <li class="btn">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="hdfc-card-body">
          <div class="hdfc-card-listing">
            <div class="hdfc-card-list">
              <ul>
                <li class="hdfc-card-list-img">
                  <img
                    [src]="
                      contentUrl[7]
                        ? contentUrl[7]
                        : ('../../../../../assets/img/general-entity/templates/nineteenattributes/contact.png'
                          | CDNUrlRewritePipe)
                    "
                    alt=""
                  />
                </li>
                <li class="hdfc-card-listText">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-list">
              <ul>
                <li class="hdfc-card-list-img">
                  <img
                    [src]="
                      contentUrl[9]
                        ? contentUrl[9]
                        : ('../../../../../assets/img/general-entity/templates/nineteenattributes/reload.png'
                          | CDNUrlRewritePipe)
                    "
                    alt=""
                  />
                </li>
                <li class="hdfc-card-listText">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-list">
              <ul>
                <li class="hdfc-card-list-img">
                  <img
                    [src]="
                      contentUrl[11]
                        ? contentUrl[11]
                        : ('../../../../../assets/img/general-entity/templates/nineteenattributes/call.png'
                          | CDNUrlRewritePipe)
                    "
                    alt=""
                  />
                </li>
                <li class="hdfc-card-listText">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-list">
              <ul>
                <li class="hdfc-card-list-img">
                  <img
                    [src]="
                      contentUrl[13]
                        ? contentUrl[13]
                        : ('../../../../../assets/img/general-entity/templates/nineteenattributes/text.png'
                          | CDNUrlRewritePipe)
                    "
                    alt=""
                  />
                </li>
                <li class="hdfc-card-listText">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hdfc-card-list">
              <ul>
                <li class="hdfc-card-list-img">
                  <img
                    [src]="
                      contentUrl[15]
                        ? contentUrl[15]
                        : ('../../../../../assets/img/general-entity/templates/nineteenattributes/mail.png'
                          | CDNUrlRewritePipe)
                    "
                    alt=""
                  />
                </li>
                <li class="hdfc-card-listText">
                  <ng-container
                    *ngIf="attributes[15]"
                    dynamicField
                    [formData]="attributes[15][attributes[15].attributeName]"
                    [group]="attributes[15][attributes[15].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName], $event)"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="hdfc-card-bottom">
            <div class="hdfc-card-ft-listing">
              <div class="hdfc-card-ft-list">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[16]"
                      dynamicField
                      [formData]="attributes[16][attributes[16].attributeName]"
                      [group]="attributes[16][attributes[16].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="hdfc-card-ft-list">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[17]"
                      dynamicField
                      [formData]="attributes[17][attributes[17].attributeName]"
                      [group]="attributes[17][attributes[17].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="hdfc-card-ft-list hdfc-last-card">
                <ul>
                  <li>
                    <ng-container
                      *ngIf="attributes[18]"
                      dynamicField
                      [formData]="attributes[18][attributes[18].attributeName]"
                      [group]="attributes[18][attributes[18].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName], $event)"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="entitytemplates type-templates"
    *ngIf="templateType == 314"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div
      class="entity-template-type info-card-details dynamic-card-block"
      [ngClass]="{ 'card-checked': dCard.isChecked }"
    >
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <div class="info-cards">
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[0]"
              dynamicField
              [formData]="attributes[0][attributes[0].attributeName]"
              [group]="attributes[0][attributes[0].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[2]"
              dynamicField
              [formData]="attributes[2][attributes[2].attributeName]"
              [group]="attributes[2][attributes[2].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[3]"
              dynamicField
              [formData]="attributes[3][attributes[3].attributeName]"
              [group]="attributes[3][attributes[3].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[4]"
              dynamicField
              [formData]="attributes[4][attributes[4].attributeName]"
              [group]="attributes[4][attributes[4].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[5]"
              dynamicField
              [formData]="attributes[5][attributes[5].attributeName]"
              [group]="attributes[5][attributes[5].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[6]"
              dynamicField
              [formData]="attributes[6][attributes[6].attributeName]"
              [group]="attributes[6][attributes[6].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul class="deatails">
          <li class="info">
            <ng-container
              *ngIf="attributes[7]"
              dynamicField
              [formData]="attributes[7][attributes[7].attributeName]"
              [group]="attributes[7][attributes[7].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates" *ngIf="templateType == 316">
    <div class="entity-template-type symptoms-cards" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <ul>
        <li class="image">
          <img *ngIf="contentUrl?.[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          <img
            *ngIf="!(contentUrl?.[1])"
            src="../../../../../assets/img/general-entity/templates/nineteenattributes/virus.svg"
            alt=""
          />
        </li>
        <li class="text text-truncate">
          <ng-container
            *ngIf="attributes[1]"
            dynamicField
            [formData]="attributes[1][attributes[1].attributeName]"
            [group]="attributes[1][attributes[1].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="entitytemplates type-templates" *ngIf="templateType == 318">
    <div class="entity-template-type book-now-318" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <ul>
        <li class="image"><img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /></li>
        <li class="text text-truncate">
          <ng-container
            *ngIf="attributes[1]"
            dynamicField
            [formData]="attributes[1][attributes[1].attributeName]"
            [group]="attributes[1][attributes[1].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div
    class="entitytemplates type-templates"
    (click)="openExpand($event, settings?.selectionType ? settings?.selectionType : settings?.customizeCardconfigData?.selectionType)"
    *ngIf="templateType == 319"
  >
    <div class="entity-template-type product-info-card-319" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <ul>
        <div class="image-wrapper">
          <li class="image"><img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" /></li>
          <li class="percentage-off">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </div>
        <div class="product-details-wrapper">
          <div class="product-details">
            <li class="title">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="quantity">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </div>
          <div class="product-details">
            <li class="product-finalprice">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="product-actualprice">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </div>
          <li class="text">
            <ng-container
              *ngIf="attributes[6]"
              dynamicField
              [formData]="attributes[6][attributes[6].attributeName]"
              [group]="attributes[6][attributes[6].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>

  <div class="entitytemplates type-templates" *ngIf="templateType == 320">
    <div class="entity-template-type order-summery" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <ul>
        <li>
          <ng-container
            *ngIf="attributes[0]"
            dynamicField
            [formData]="attributes[0][attributes[0].attributeName]"
            [group]="attributes[0][attributes[0].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[1]"
            dynamicField
            [formData]="attributes[1][attributes[1].attributeName]"
            [group]="attributes[1][attributes[1].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[2]"
            dynamicField
            [formData]="attributes[2][attributes[2].attributeName]"
            [group]="attributes[2][attributes[2].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[3]"
            dynamicField
            [formData]="attributes[3][attributes[3].attributeName]"
            [group]="attributes[3][attributes[3].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[4]"
            dynamicField
            [formData]="attributes[4][attributes[4].attributeName]"
            [group]="attributes[4][attributes[4].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[5]"
            dynamicField
            [formData]="attributes[5][attributes[5].attributeName]"
            [group]="attributes[5][attributes[5].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[6]"
            dynamicField
            [formData]="attributes[6][attributes[6].attributeName]"
            [group]="attributes[6][attributes[6].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[7]"
            dynamicField
            [formData]="attributes[7][attributes[7].attributeName]"
            [group]="attributes[7][attributes[7].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[8]"
            dynamicField
            [formData]="attributes[8][attributes[8].attributeName]"
            [group]="attributes[8][attributes[8].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName], $event)"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="entitytemplates type-templates" *ngIf="templateType == 321">
    <div class="entity-template-type cart-view-card" [ngClass]="{ 'card-checked': dCard.isChecked }">
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>

      <div class="cart-view-logo">
        <ul>
          <li>
            <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
      </div>
      <div class="cart-view-right-side">
        <div class="cart-view-cont-left">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="cart-view-middle-list">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="cart-view-cont-right">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName], $event)"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div
    class="entitytemplates type-templates"
    *ngIf="templateType == 328"
    [ngClass]="{ 'card-checked': dCard.isChecked }"
  >
    <div
      class="entity-template-type entity-comment-section dynamic-card-block"
      [ngClass]="{ 'card-checked': dCard.isChecked }"
    >
      <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
      <!-- Your Code Goes Here-->
      <ul class="comment-inner">
        <li class="comment-img">
          <ng-container
            *ngIf="attributes[0]"
            dynamicField
            [formData]="attributes[0][attributes[0]?.attributeName]"
            [group]="attributes[0][attributes[0]?.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
        </li>
        <div class="content-section">
          <div class="division-section">
            <ul>
              <li class="c-name">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1]?.attributeName]"
                  [group]="attributes[1][attributes[1]?.attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
              </li>
              <li class="c-date">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2]?.attributeName]"
                  [group]="attributes[2][attributes[2]?.attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
              </li>
            </ul>
          </div>
          <div class="c-comment-dis">
            <ul>
              <li class="c-comments">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3]?.attributeName]"
                  [group]="attributes[3][attributes[3]?.attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>
  </div>

  <div class="studioverse_cu_card dynamic-card-block" *ngIf="templateType == 334">
    <div class="nhentitycard nh-position-relative">
      <div class="nhentitycard_top nh-position-relative nh-mb-12">
        <ul class="nhentitycard_image nh-display-flex nh-flex-wrap">
          <li class="nh-wp-50">
            <img
              [src]="contentUrl[1] | CDNUrlRewritePipe"
              alt=""
              class="card-logo nh-wp-100 nh-hp-100 nh-fit-cover nh-radius-8"
            />
          </li>
          <li class="nh-wp-50">
            <img
              [src]="contentUrl[2] | CDNUrlRewritePipe"
              alt=""
              class="card-logo nh-wp-100 nh-hp-100 nh-fit-cover nh-radius-8"
            />
          </li>
          <li class="nh-wp-50">
            <img
              [src]="contentUrl[3] | CDNUrlRewritePipe"
              alt=""
              class="card-logo nh-wp-100 nh-hp-100 nh-fit-cover nh-radius-8"
            />
          </li>
          <li class="nh-wp-50">
            <img
              [src]="contentUrl[4] | CDNUrlRewritePipe"
              alt=""
              class="card-logo nh-wp-100 nh-hp-100 nh-fit-cover nh-radius-8"
            />
          </li>
        </ul>
        <ul
          class="nhentitycard_tiles nh-position-absolute nh-display-flex nh-align-center"
          *ngIf="attributes?.[4]?.attributeValue"
        >
          <em class="nh-icon nh-icon-play-circle nh-fs-16 nh-mr-8"></em>
          <li class="nh-fs-12 nh-mr-3">{{attributes?.[4]?.attributeValue}}</li>
          <span class="statictext nh-fs-12">Titles</span>
        </ul>
        <div class="learnmore nh-position-absolute nh-hp-100 nh-wp-100">
          <ul class="nh-position-absolute">
            <li
              class="only-value nh-display-inline-block cursor-pointer nh-radius-18"
              *ngIf="checkEventGSI(attributes[5], 5)"
              (click)="saveEventGSI(currentEventDataArray?.[5]?.[0], attributes[5][attributes[5]?.attributeName])"
            >
              {{ currentEventDataArray?.[5]?.[0]?.gsiDisplayName ? currentEventDataArray?.[5]?.[0]?.gsiDisplayName : currentEventDataArray?.[5]?.[0]?.gsiName }}
            </li>
          </ul>
        </div>
      </div>
      <ul class="nhentitycard_title nh-position-relative nh-mb-8">
        <li class="only-value" *ngIf="attributes?.[6]?.attributeValue">{{attributes?.[6]?.attributeValue}}</li>
      </ul>
      <ul class="nhentitycard_tags nh-display-flex nh-column-gap-4 nh-position-relative">
        <li class="only-value nh-radius-24" *ngIf="attributes?.[7]?.attributeValue">
          {{attributes?.[7]?.attributeValue}}
        </li>
        <li class="only-value nh-radius-24" *ngIf="attributes?.[8]?.attributeValue">
          {{attributes?.[8]?.attributeValue}}
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<app-dynamic-ui-card-expand-templates
  *ngIf="showExpand && currentIndex == expandIndex"
  [attributes]="attributes_expand"
  (closeExpandEmitter)="closeExpand($event)"
  [expandType]="expandType"
  [eventCUList]="eventCUList"
></app-dynamic-ui-card-expand-templates>
