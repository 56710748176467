import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancesearchFilterComponent } from './advancesearch-filter.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
@NgModule({
    declarations: [AdvancesearchFilterComponent],
    imports: [CommonModule, FormsModule, MatRadioModule, MatIconModule, MatCheckboxModule, ApplicationPipesModule],
    exports: [AdvancesearchFilterComponent],
})
export class AdvancesearchFilterModule {}
