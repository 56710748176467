import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { TransactionViewComponent } from '../transaction-view.component';

@Component({
  selector: 'app-asset-transaction-view',
  templateUrl: './asset-transaction-view.component.html',
  styleUrls: ['./asset-transaction-view.component-style.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetTransactionViewComponent extends TransactionViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() transactionCU: any;
  @Input() gsi: any;
  @Input() selectedCu: any;
  @Input() mode: string;
  @Input() txnData: any;
  @Input() bgStyleContent: any;
  @Input() solutionInfo: any;
  @Output() inividualGsiData: any = new EventEmitter();
  @Output() setInividualGsiData: any = new EventEmitter();
  @Input() isReadOnly: any;
  @Input() isDebugMode: boolean;
  @Input() isCarnival: boolean;
  @Input() isOnboarding: boolean;
  @Input() layoutConfigurations: any;
  @Input() isTransactionActive: boolean;
  @Input() gsiDesign: any;

  @Output() getNextCUEmitter = new EventEmitter();
  @Output() formValueChange = new EventEmitter();
  @Output() tempEntityListEmit = new EventEmitter();
  @Output() tempInfoEntityListEmit = new EventEmitter();
  @ViewChild('thankumodal') thankumodal: TemplateRef<any>;
  @ViewChild('cuMatTab', { static: false }) cuMatTab: any;
  constructor(private injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
