import { SPACE } from "@angular/cdk/keycodes";
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { RuleSetCondition, PrimaryEntity, TranslatorService, EntityEndpointService, EventsFacadeService, EntityBoardEndpointService, AlertService, ViewAndEditPayload, RuleSet, addUserData, setConditionMap, addHiddenFilter, addValueToAlreadyAddedSearchQuery, updateHiddenFilterRuleSet } from "@common-services";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { EQLUser, encodeString } from "../../../constants/customize-filter-templates.constants";
import { Clipboard } from '@angular/cdk/clipboard';
import {Attribute} from '@common-services';

@Component({
  selector: 'app-create-eql',
  templateUrl: './create-eql.component.html',
  styleUrls: ['./create-eql.component.scss'],
})
export class CreateEqlComponent implements OnInit {
  selectedEntityData: any;
  hiddenRuleSet: RuleSetCondition = {
    ruleSet: [],
    ruleSetCondition: 'AND',
  };
  @Input() set _searchQuery(value: any) {
    this.primaryEntity = value?.primaryEntity;
    this.getEntityDetails(value?.primaryEntity, true, value?.searchQuery);
  }
  operators: string[] = ['==', '>', '<', '!=', '>=', '<='];
  userData: any;
  showUserData: boolean;
  labels: any;
  ngUnsubscribe = new Subject();
  defaultUser: [EQLUser, EQLUser] = [
    {
      name: 'Any One In The Organisation',
      value: 'MyOrganisation',
    },
    {
      name: 'Private',
      value: 'Private',
    },
  ];
  users: EQLUser[] = [...this.defaultUser];
  selectedRights: 'Viewer' | 'Editor' | 'Both';
  selectedUsers: string[];
  rights: ['Viewer', 'Editor', 'Both'] = ['Viewer', 'Editor', 'Both'];

  @Input() filterName: string;

  @Input() onlyEditRuleSet: boolean = false;
  usersPageNumber: number = 0;

  isFavorite: boolean = false;
  screen: number = 0;
  primaryEntity: PrimaryEntity;
  scrollTopBeforeSelection: any;
  usersScroll: boolean = true;
  searchUserText: string = '';
  searchUserText$: Subject<string> = new Subject<string>();
  @Input() set _filterIds(value: string[]) {
    this.filterIds = value;
    this.goToNextScreen();
    this.goToShareScreen();
  }
  filterIds: string[];
  @Input() set _primaryEntity(value: PrimaryEntity) {
    this.primaryEntity = value;
    this.getEntityDetails(value);
  }
  @Input() cardId: string;
  @ViewChild('userMultiple') userMultiple: MatSelect;
  description: string;
  category: string;
  @Input() sendDataToOutput: boolean = false;
  @Output() sendDatToOutputEmit: EventEmitter<boolean> = new EventEmitter();
  constructor(
    protected translator: TranslatorService,
    protected entityEndpointService: EntityEndpointService,
    private eventService: EventsFacadeService,
    private entityBoardEndpointService: EntityBoardEndpointService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) {
    this.detectUsers();
    this.searchUserObservable();
  }

  private getEntityDetails(entity: PrimaryEntity, setSearchQuery?: boolean, searchQuery?: any) {
    /*istanbul ignore next*/
    this.entityEndpointService.loadAttributesByEntId(entity.id, false, false).subscribe((data: any) => {
      this.selectedEntityData = data.result;
      if (setSearchQuery) {
        updateHiddenFilterRuleSet(searchQuery, this.hiddenRuleSet, this.selectedEntityData);
      }
    });
  }

  private searchUserObservable() {
    this.searchUserText$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.usersPageNumber = 0;
      this.eventService.getUsers(value, this.usersPageNumber);
    });
  }

  private checkScrollEvent() {
    this.userMultiple.openedChange.subscribe((open: boolean) => {
      if (open) {
        this.userMultiple.panel.nativeElement.addEventListener('scroll', (event: any) => {
          this.scrollTopBeforeSelection = event.target.scrollTop;
          this.onScrollEnd(event);
          event.preventDefault();
        });
        this.userMultiple.optionSelectionChanges.subscribe(() => {
          if (this.userMultiple?.panel?.nativeElement?.scrollTop >= 0) {
            this.userMultiple.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
          }
        });
        this.userMultiple._handleKeydown = (event: KeyboardEvent) => {
          if (event.keyCode == SPACE) return;
          if (!this.userMultiple.disabled) {
            this.userMultiple.panelOpen
              ? // @ts-ignore
                this.userMultiple._handleOpenKeydown(event)
              : // @ts-ignore
                this.userMultiple._handleClosedKeydown(event);
          }
        };
      }
    });
  }

  private onScrollEnd(event: any) {
    if (this.usersScroll == true && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.usersScroll = false;
      this.usersPageNumber++;
      this.eventService.getUsers(this.searchUserText, this.usersPageNumber);
    }
  }

  ngOnInit(): void {
    this.detectLanguageChange();
    this.eventService.getUsers(this.searchUserText, this.usersPageNumber);
  }

  private detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  checkIfFilterNameIsGiven() {
    if (this.filterName?.length > 0) {
      this.goToNextScreen();
    }
  }

  goToNextScreen() {
    this.screen += 1;
  }

  private detectUsers(): void {
    this.eventService.usersData$.subscribe(
      (data: any) => {
        /*istanbul ignore next*/
        if (data && data?.content) {
          this.usersScroll = true;
          data.content.forEach((user: any, i: number) => {
            this.convertUser(user, i == 0);
          });
        } else if (data) {
          this.usersScroll = true;
          data.forEach((user: any, i: number) => {
            this.convertUser(user, i == 0);
          });
        }
      },
      (error: any) => {
        this.usersScroll = false;
        this.users = [...this.defaultUser];
        console.log(error);
      }
    );
  }

  submitToApi() {
    const req: any = {
      groupBy: [],
      searchQuery: addValueToAlreadyAddedSearchQuery(this.hiddenRuleSet, this.primaryEntity),
      entityCardID: this.cardId,
      entitiesList: [this.primaryEntity?.name],
      entityIdsList: [this.primaryEntity?.id],
    };
    if (!this.onlyEditRuleSet) {
      this.entityBoardEndpointService.saveSharableFilter(req, this.filterName, this.isFavorite).subscribe(
        (res: any) => {
          if (res && res.result && res.status == 200) {
            this.alertService.showToaster(res.message, '', 'success');
            this.filterIds = [res.result.filterId];
            this.goToShareScreen();
          } else {
            this.alertService.showToaster(res.message, '', 'error');
          }
        },
        (error: any) => {
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
    } else {
      const request: any = {
        query: req,
      };
      this.saveEditAccess(this.cardId, this.filterIds, request);
    }
  }

  private goToShareScreen() {
    this.goToNextScreen();
    setTimeout(() => {
      this.checkScrollEvent();
    }, 100);
  }

  submitEditApi() {
    const payload: ViewAndEditPayload = {
      viewers: {
        users: this.giveViewersAndEditors('Viewer'),
      },
      editors: {
        users: this.giveViewersAndEditors('Editor'),
      },
      category: this.category,
      description: this.description,
    };
    this.saveEditAccess(this.cardId, this.filterIds, payload);
  }

  private giveViewersAndEditors(label: 'Viewer' | 'Editor'): string[] {
    return this.selectedRights === label || this.selectedRights === 'Both'
      ? this.selectedUsers?.length === 0
        ? ['Private']
        : this.selectedUsers
      : [];
  }

  private saveEditAccess(cardId: string, filterIds: string[], payload: ViewAndEditPayload) {
    filterIds.forEach((filterId: string) => {
      this.entityBoardEndpointService.saveEditAccess(cardId, filterId, payload).subscribe(
        (res: any) => {
          if (res && res.status === 200) {
            this.alertService.showToaster(res.message, '', 'success');
            this.closeDialog();
          } else {
            this.alertService.showToaster(res.message, '', 'error');
          }
        },
        (error: any) => {
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
    });
  }

  private convertUser(user: any, flag: boolean) {
    const eqlUser: EQLUser = {
      name: user.name,
      value: user.email,
    };
    if (this.usersPageNumber == 0 && flag) {
      this.users = [...this.defaultUser, eqlUser];
    } else {
      this.users = [...this.users, eqlUser];
    }
  }

  closeDialog() {
    this.dialog.closeAll();
    this.sendDatToOutputEmit.emit(this.sendDataToOutput);
  }

  getLinkOfTheFilter() {
    var url: string = window.location.origin;
    var filterString: string = JSON.stringify(this.filterIds);
    url =
      url +
      `/filter/${encodeURIComponent(encodeString(filterString))}/${encodeURIComponent(encodeString(this.cardId))}`;
    this.clipboard.copy(url);
  }
}
