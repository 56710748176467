<div class="container text-center my-5 cstm-align">
  <!-- The Modal -->
  <div class="modal c" id="myModal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <input matInput type="text" placeholder="{{ title }}" [(ngModel)]="clonedName" />
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="saveClonedCu()">
            {{ labels?.DONE }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
