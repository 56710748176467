import { I } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SelfOnboardingSignupComponent, LoginDesign, AuthenticationService, AlertService, LoaderService, RolesFacadeService, UsersFacadeService, OnboardingFacadeService, TranslatorService, CredentialsService, ManageConfigurationFacadeService, setPropertyInDivLayout, ManageConfigurationEndpointService } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-signup-template-seventeen',
  templateUrl: './signup-template-seventeen.component.html',
  styleUrls: ['./signup-template-seventeen.component.scss'],
})
export class SignupTemplateSeventeenComponent extends SelfOnboardingSignupComponent implements OnInit{
  @Input() loginDesign: LoginDesign;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  isEditable: boolean = false;
  isFirst: boolean = false;
  isSubmit: boolean = true;
  login1Next: boolean = false;
  rolesDesignData: any;
  selectedRoleIdx: number;
  stepIdx: number = -1;
  showCreatePassword: boolean = false;
  skipRoleSeletionPage:boolean = false;
  isMinLengthMet: boolean = false;
  isMinUppercaseMet: boolean = false;
  isMinDigitsMet: boolean = false;
  isMinSpecialCharsMet: boolean = false;
  constructor(
    authenticationService: AuthenticationService,
    alertService: AlertService,
    router: Router,
    loader: LoaderService,
    rolesFacade: RolesFacadeService,
    userFacade: UsersFacadeService,
    onboardingFacadeService: OnboardingFacadeService,
    translator: TranslatorService,
    credentialsService: CredentialsService,
    manageConfigurationFacade: ManageConfigurationFacadeService,
    sanitizer: DomSanitizer,
    dialog: MatDialog,
    public elRef: ElementRef,
    public manageConfigurationEndpoint: ManageConfigurationEndpointService,
  ) {
    super(
      authenticationService,
      alertService,
      router,
      loader,
      rolesFacade,
      userFacade,
      onboardingFacadeService,
      translator,
      credentialsService,
      manageConfigurationFacade,
      sanitizer,
      dialog,
      manageConfigurationEndpoint
    );
  }
  signupConfig: SwiperOptions = {
    slidesPerView: 2,
    autoHeight: false,
    loop: false,
    spaceBetween: 40,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      1700: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      414: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      375: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  };
  ngOnInit(): void {
    setPropertyInDivLayout(this.elRef, '--main-background-color', this.loginDesign?.backGround?.color);
    setPropertyInDivLayout(this.elRef, '--chip-color', this.loginDesign?.chip?.color);
    setPropertyInDivLayout(this.elRef, '--main-background-color', this.loginDesign?.backGround?.color);
    setPropertyInDivLayout(this.elRef, '--chip-color', this.loginDesign?.chip?.color);
    if (this.loginDesign?.headingText?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--heading-font', this.loginDesign?.headingText?.font);
    }
    if (this.loginDesign?.caption?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-font', this.loginDesign?.caption?.font);
    }
    if (this.loginDesign?.actionButton?.fontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-font-color', this.loginDesign?.actionButton?.fontColor);
    }
    if (this.loginDesign?.actionButton?.buttonColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-button-color', this.loginDesign?.actionButton?.buttonColor);
    }
    if (this.loginDesign?.caption?.headingFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-heading-color', this.loginDesign?.caption?.headingFontColor);
    }
    if (this.loginDesign?.caption?.textFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-description-color', this.loginDesign?.caption?.textFontColor);
    }
    this.rolesDesignData = new Map();
    this.loginDesign?.roleDesign?.forEach((role: any) => {
      this.rolesDesignData.set(role.roleName, role);
    });
    // this.changeHeadingText();
  }
  change(selectedRole: any, index?: number) {
    this.loginDesign?.roleDesign?.forEach((role: any,index:number) => {
      if(role.roleName === selectedRole.name){
        this.selectedRole = selectedRole;
        this.selectedRoleIdx = index;
        this.getTnCforSelectedRole();
      }
    })
    this.isFirst = true;
    this.stepIdx = 0;
  }
  getTnCforSelectedRole() {
    if (this.selectedRole) {
      this.rolesFacade
        .getTnCbyClientIdAndRole(this.clientId, this.selectedRole.name)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if (
            res &&
            res?.result &&
            res?.result?.termsAndConditions &&
            res.result?.status?.toLowerCase() == 'approved'
          ) {
            this.termsAndConditions=res.result?.termsAndConditions
          }
        });
    } 
  }
  validate(skipUserCreation:boolean = false) {
    this.validateOTP(skipUserCreation);
    setTimeout(() => {
      if (this.isValid) {
        this.stepIdx = 2;
        this.stepper.next();
      }
    }, 3000);
  }
  toggleShowCreatePassword() {
    this.showCreatePassword = !this.showCreatePassword;
  }
  getRoles() {
    this.rolesFacade
      .getPublicRoles(this.clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.rolesList = res;
          if(this.defaultRole != null ){
            this.skipRoleSeletionPage = true;
            this.rolesList?.forEach((role: any) => {
              if (role.name == this.defaultRole) {
                this.change(role);
              }
            });
          }
          if (this.rolesList?.length != 0) {
            this.showRoles = true;
          }
        }
      });
  }

  passwordPolicy() {
    this.userFacade.getPasswordPolicyByAdmin(this.tenantName);
    this.userFacade.tenantPasswordPolicy$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.password_info = res.result;
        this.isMinLengthMet = this.password_info?.length == null || this.password_info.length === 0;
        this.isMinUppercaseMet = this.password_info?.upperCase == null || this.password_info.upperCase === 0;
        this.isMinDigitsMet = this.password_info?.digits == null || this.password_info.digits === 0;
        this.isMinSpecialCharsMet = this.password_info?.specialChars == null || this.password_info.specialChars === 0;
      });
  }
  passwordPolicyRequirementsCheck(){
    if(this.password_info){
        this.isMinLengthMet = this.checkMinLength();
        this.isMinUppercaseMet = this.checkMinUppercase();
        this.isMinDigitsMet = this.checkMinDigits();
        this.isMinSpecialCharsMet = this.checkMinSpecialChars();
    }
  }

  checkMinLength(): boolean {
    if(this.password_info?.length)
      return this.signupPayload.password.length >= this.password_info?.length;
    return true;
  }

  checkMinUppercase(): boolean {
    if(this.password_info?.upperCase){
      const uppercaseMatches = this.signupPayload.password.match(/[A-Z]/g);
      return uppercaseMatches ? uppercaseMatches.length >= this.password_info?.upperCase : false;
    }
    return true;
  }

  checkMinDigits(): boolean {
    if(this.password_info?.digits){
      const digitMatches = this.signupPayload.password.match(/[0-9]/g);
      return digitMatches ? digitMatches.length >= this.password_info?.digits : false;
    }
    return true;
  }

  checkMinSpecialChars(): boolean {
    if(this.password_info?.length){
      const specialCharMatches = this.signupPayload.password.match(/[!@#$%^&*(),.?":{}|<>]/g);
      return specialCharMatches ? specialCharMatches.length >= this.password_info?.specialChars : false;
    }
    return true;
  }

}
