import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NodeGsiFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nsl-related-gsi',
  templateUrl: './nsl-related-gsi.component.html',
  styleUrls: ['./nsl-related-gsi.component.scss'],
})
export class NslRelatedGsiComponent implements OnInit, OnDestroy {
  @Input() relatedGsiData: any;
  labels: any;
  ngUnsubscribe = new Subject();
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    public translator: TranslatorService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
