import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import {
  AlertService, Attribute, AttributeEndpointService, Librarytypes,
  NodeGsiFacadeService,
  SuggestionFacadeService, TranslatorService,
  nestedEntityPayload,
  validateDefaultValue,
  isReservedCU
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-nested-attribute-modal',
  templateUrl: './node-nested-attribute-modal.component.html',
  styleUrls: ['./node-nested-attribute-modal.component.scss'],
})
export class NodeNestedAttributeModalComponent implements OnInit, OnDestroy {
  @Input() nodeValue: any;
  @Output() closeSubAttributePanel: EventEmitter<any> = new EventEmitter<any>();
  @Input() gsiData: any;
  node: any;
  selectedEntity: any;
  entityIndex: number;
  secondLevel: boolean = false;
  thirdLevel: boolean = false;
  forthLevel: boolean = false;
  emptyAttribute: any = new Attribute();
  attributeTypes: any;
  searchText: string = '';
  private ngUnsubscribe = new Subject();
  primaryAttributeIndex: number;
  primaryIndex: number;
  openUiPanel: boolean = false;
  ternaryIndex: number;
  secondaryIndex: number;
  openUiPanelForth: boolean = false;
  openUiPanelsecond: boolean = false;
  openUiPanelthird: boolean = false;
  levels: any = [];
  currentLevel: any;
  currentAttribute: any;
  labels: any;
  openUpdateOrEditEntityPanel: boolean = false;
  showAttributeExistAsEntity: boolean = false;
  existAsEntityName: any;
  forthIndex: any;
  fifthLevel: boolean = false;
  defaultOption: boolean = false;
  cu: any;
  layerName: any;
  dataToSend: any = [
    {
      entities: {
        physical: {},
        information: {},
        triggerCES: {},
      },
    },
  ];
  sentenceIndex: any;
  PhysicalSentenceIndex: number;
  PhysicalSentenceTags: any;
  InformationSentenceTags: any;
  TriggerCESSentenceTags: any;
  savedEntity: any;
  sourceVal: string;
  DefaultSourceVal: string;
  tagInd: any;
  isInit: boolean = true;
  existAsEntity: any;
  emitAttributeSubject = new Subject<any>();
  currentEmptyEntityNSLAttributes: any;
  fileUrl: any = [];
  editFileUrl: any;
  multiDefaultValue: boolean = false;
  defaultValue: any = [''];
  allDefaultValues: any = [];
  default: any = {};
  dateTypeUiControls: any = ['date', 'datetime', 'dateRange', 'time', 'yearMonth', 'year']; // For date type UI controls
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private entityFacadeService: NodeEntityFacadeService,
    private attributeEndpointService: AttributeEndpointService,
    private translator: TranslatorService,
    private alertService: AlertService,
    private suggestionFacadeService: SuggestionFacadeService
  ) {
    this.detectAttributeDetails();
    this.getAttributeTypes();
    this.detectSubAttributeInEntity();
    this.detectSavedCuData();
    this.detectGsiSave();
    this.detectExistingEntity();
    this.detectSaveEntity();
    this.detectUpdatedCU();
    this.detectLanguageChange();
    this.saveSentenceWithAvailableNestedEntity();
    this.detectNestedEntity();
    this.detectEntityToAddFromLibrary();
  }

  ngOnInit(): void {
    this.gsiFacade.openLibrary('entity');
    this.initComponent();
  }
  getAttributeTypes() {
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 62).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && res?.data) {
        this.attributeTypes = res.data;
      }
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectAttributeDetails() {
    this.entityFacadeService.sendAttrDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res && res?.node?.data?.entity) {
        this.node = res.node;
        this.gsiData = res.gsiData;
        this.selectedEntity = res.node.data.entity;
        this.cu = res.node.data?.cu;
        this.layerName = res.node.data?.layer;
        this.tagInd = res.tagIndex;
        this.selectedEntity.nslAttributes.forEach((attr: any) => {
          if (attr.name == this.node.data.attr.name) {
            attr['showColor'] = false;
            attr['show'] = true;
          } else {
            attr['show'] = false;
          }
        });
      }
    });
  }
  detectSubAttributeInEntity() {
    this.entityFacadeService.saveentityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */

      /*istanbul ignore next */
      if (this.nodeValue?.data?.name) {
        /*istanbul ignore next*/
        let foundCu = {
          ...this.gsiData.solutionLogic?.find((x: any) => x.displayName == this.nodeValue.data.displayName),
        };
        /*istanbul ignore else*/
        if (foundCu) {
          /*istanbul ignore next*/
          foundCu.layers.forEach((layer: any) => {
            const index = layer.participatingItems?.findIndex((x: any) => x.id == res?.result?.id);
            if (index != -1) {
              layer.participatingItems[index] = res.result;
            }
          });
          foundCu.tfId = foundCu.tfReferencedChangeUnit;
          foundCu.id = foundCu.referencedChangeUnit;
          this.changeunitFacade.saveDraftCu(foundCu);
        }
      }
    });
  }
  saveGsi(gsiData: any) {
    this.gsiFacade.saveGsi(gsiData);
  }
  detectSavedCuData() {
    this.changeunitFacade.cuDraftData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res && this.gsiData?.solutionLogic?.length > 0) {
        /*istanbul ignore next*/
        this.gsiData.solutionLogic.forEach((cu: any) => {
          if (cu.tfReferencedChangeUnit == res.tfId) {
            cu.layers = [...res.layers];
          }
        });
        this.saveGsi(this.gsiData);
      }
    });
  }
  detectGsiSave() {
    this.gsiFacade.gsiDraftResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (this?.nodeValue?.data?.name) {
        res.solutionLogic.forEach((cu: any) => {
          if (cu.masterId == this.node.data.masterId) {
            this.nodeValue.data.layers = [...cu.layers];
            this.nodeValue.data.cuLayers = [...cu.layers];
          }
        });
      }
      this.closeSubAttributePanel.emit(this.nodeValue);
      this.gsiFacade.expandGraph(this.nodeValue);
    });
  }
  initComponent() {
    this.selectedEntity.level = 0;
    this.levels.push(this.selectedEntity);
  }
  selectAttrType(attrType: any, attr: any) {
    this.searchText = '';
    attr.attributeType.type = attrType.value.dataType;
    this.getAttributeTypes();
  }
  ///
  detectExistingEntity() {
    this.entityFacadeService.existingEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res?.name == this.currentAttribute.name) {
        this.existAsEntity = res;
        this.showAttributeExistAsEntity = true;
      } else {
        this.isInit = false;
        this.newSubAttribute(this.currentAttribute, this.selectedEntity, 1);
      }
    });
  }
  addSameEntity(attribute: any, level: any) {
    attribute.attributeType.type = 'entity';
    attribute['show'] = true;
    if (attribute?.generalEntity) {
      let ent = this.getEntityReady(attribute.generalEntity, level);
      attribute.generalEntity = { ...ent, level: level + 1 };
    } else {
      let ent = this.getEntityReady(this.existAsEntity, level);
      attribute['generalEntity'] = { ...ent, level: level + 1 };
    }
    level = level + 1;
    this.levels[level] = attribute.generalEntity;
    this.existAsEntity = undefined;
  }

  checkExistingEntity2(attribute: any) {
    if (attribute.name == '') {
      this.alertService.showToaster('Entity name is empty. Please enter entity name to move forward.', '', 'warning');
    } else {
      this.currentAttribute = attribute;
      this.allDefaultValues = [];
      this.entityFacadeService.checkExistingEntity(attribute.name);
    }
  }
  getEntityReady(entity: any, level: number) {
    entity.nslAttributes.forEach((attribute: any) => {
      attribute.openUiPanel = false;
      attribute.existAsEntity = false;
      attribute.isRename = false;
      attribute.updatedName = '';
      attribute.show = true;
      attribute.showColor = false;
      attribute.level = level;
    });
    return entity;
  }
  detectEntityToAddFromLibrary() {
    this.entityFacadeService.entityAttributes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        let addIndex = -1;
        this.currentEmptyEntityNSLAttributes.forEach((attribute: any, index: number) => {
          if (attribute.name == '') {
            addIndex = index;
          }
        });
        if (addIndex != -1) {
          let at = this.currentEmptyEntityNSLAttributes[addIndex];
          at.name = res.name;
          if (res.name.startsWith('NSL_')||isReservedCU(res)) {
            res.dsdId = res?.id;
            // if(res?.tfId) delete res.tfId;
          }
          res = this.getEntityReady(res, at.level + 1);
          at.attributeType.type = 'entity';
          this.currentEmptyEntityNSLAttributes[addIndex] = { ...at, generalEntity: { ...res, level: at.level + 1 } };
        }
      }
    });
  }

  repetedCode(attr: any, ind: number, level: any) {
    this.levels.length = level;
    attr.attributeType.type = 'entity';
    this.primaryIndex = ind;
    if (attr?.generalEntity?.nslAttributes?.length > 0) {
      attr.generalEntity.nslAttributes.push({
        ...new Attribute(),
        openUiPanel: false,
        existAsEntity: false,
        isRename: false,
        updatedName: '',
        show: true,
        showColor: false,
        level: attr.generalEntity.level,
        parentAttribute: attr.name,
      });
    } else {
      attr['generalEntity'] = {
        dsdId: null,
        id: null,
        name: attr.name,
        level: level,
      };
      attr.generalEntity['nslAttributes'] = [
        {
          ...new Attribute(),
          openUiPanel: false,
          existAsEntity: false,
          isRename: false,
          updatedName: '',
          show: true,
          showColor: false,
          level: level,
          parentAttribute: attr.name,
        },
      ];
    }
    this.currentEmptyEntityNSLAttributes = attr.generalEntity.nslAttributes;
    this.levels[level] = attr.generalEntity;
    return attr;
  }
  newSubAttribute(attribute: any, entity: any, level: any) {
    entity.nslAttributes.forEach((attr: any, ind: any) => {
      if (attr.name === attribute.name) {
        let changedAttr = this.repetedCode(attribute, ind, level);
        entity.nslAttributes[ind] = changedAttr;
      } else {
        if (attr?.generalEntity) {
          this.newSubAttribute(attribute, attr.generalEntity, level + 1);
        }
      }
    });
  }
  showColor(attribute: any, entity: any) {
    attribute.showColor = true;
    entity.nslAttributes.forEach((attr: any, ind: any) => {
      if (attr.name != attribute.name) {
        attr.showColor = false;
      }
    });
  }
  revertEntityToAttribute(entity: any, level: number) {
    this.levels.forEach((level: any, index: any) => {
      level.nslAttributes.forEach((attr: any) => {
        if (attr.name === entity && attr?.generalEntity) {
          delete attr.generalEntity;
          attr.attributeType.type = 'string';
          attr.attributeType.uiElement = {
            dataType: 'string',
            isMulti: false,
            name: 'Text',
            uiElement: 'text',
            esDataType: 'string',
          };
        }
      });
    });
  }

  deleteLevel(attrName: any) {
    let delIndex = -1;
    this.levels.forEach((level: any, index: any) => {
      if (level.nslAttributes.length == 0) {
        delIndex = index;
      }
    });
    if (delIndex != -1 && delIndex != 0) {
      this.levels.splice(delIndex, 1);
    }
  }
  deleteAttribute(entity: any, attrIndex: number, level: any) {
    let parentEnt = entity.nslAttributes[attrIndex].parentAttribute;
    let currentAttr = entity.nslAttributes[attrIndex].name;
    /*istanbul ignore next*/
    if (entity.nslAttributes[attrIndex]?.generalEntity) {
      this.levels.length = level + 1;
    }
    entity.nslAttributes.splice(attrIndex, 1);
    /*istanbul ignore next*/
    if (entity.nslAttributes.length == 0) {
      this.revertEntityToAttribute(parentEnt, level);
      this.deleteLevel(currentAttr);
    }
    /*istanbul ignore next*/
    if (this.default?.[currentAttr]) {
      delete this.default?.[currentAttr];
    }
  }
  displayNextLevel(attribute: any, entity: any, level: any) {
    if (attribute?.generalEntity) {
      this.levels.length = level + 1;
      this.levels[level] = this.getEntityReady(attribute.generalEntity, level + 1);
    } else if (attribute?.nslAttributes) {
      /* istanbul ignore else */
      if (!attribute?.level) {
        attribute.level = level;
        this.levels.push(this.getEntityReady(attribute, level));
      } else {
        this.alertService.showToaster('Already Expanded', '', 'warning');
      }
    } else {
      this.alertService.showToaster('Nothing to display', '', 'warning');
    }
  }
  closeComponent() {
    this.gsiFacade.changeNSLComponent(Librarytypes.NSLGsi);
  }
  extractEntityAndAttributes() {
    this.getAttributesInEntity(this.selectedEntity);
  }
  getAttributesInEntity(entity: any) {
    this.dataToSend[0].entities.physical = nestedEntityPayload(entity);
  }
  formatTags(tags: any) {
    let list: any[] = [];
    let extractedProp = Object.keys(tags)[0];
    let tag = {
      name: extractedProp,
      type: tags[extractedProp]?.type ? tags[extractedProp].type : 'attribute',
      token_list: tags[extractedProp].token_list,
      attribute_count: tags[extractedProp].attribute_count ? tags[extractedProp].attribute_count : 0,
      attribute_dict: list,
    };
    if (tags[extractedProp]?.attribute_dict) {
      Object.entries(tags[extractedProp].attribute_dict).forEach(([key, value]: any) => {
        let sendObj = {
          [key]: value,
        };
        if (value.attribute_dict) {
          tag.attribute_dict.push(this.formatTags(sendObj));
        } else {
          tag.attribute_dict.push({
            name: key,
          });
        }
      });
    }
    return tag;
  }

  detectNestedEntity() {
    this.entityFacadeService.nestedEntityObservable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res?.nestedTags) {
        let nestedTags = res?.nestedTags;
        let foundCu = { ...this.gsiData.solutionLogic.find((x: any) => x.name == res.changeUnit.name) };
        let indexToAdd = this.tagInd ? this.tagInd : -1;
        let replaceIndex = -1;
        let layerIndex = -1;
        foundCu.layers.forEach((layer: any, lIndex: number) => {
          layer.participatingItems.forEach((item: any, index: number) => {
            if (item.name == this.selectedEntity.name) {
              replaceIndex = index;
              layerIndex = lIndex;
              if (replaceIndex != -1) {
                foundCu.layers[layerIndex].participatingItems[replaceIndex] = this.selectedEntity;
              }
            }
          });
        });
        let physicalTags, informationTags, triggerCESTags;
        if (this.layerName === 'physical') {
          physicalTags = this.formatTags(nestedTags.physical);
          physicalTags = { ...foundCu.sentenceTags.physical[this.tagInd], ...physicalTags, hasNestedTags: true };
          foundCu.sentenceTags.physical[this.tagInd] = physicalTags;
        } else if (this.layerName === 'information') {
          informationTags = this.formatTags(nestedTags.information);
          informationTags = {
            ...foundCu.sentenceTags.information[this.tagInd],
            ...informationTags,
            hasNestedTags: true,
          };
          foundCu.sentenceTags.information[this.tagInd] = informationTags;
        } else if (this.layerName === 'triggerCES') {
          triggerCESTags = this.formatTags(nestedTags.triggerCES);
          triggerCESTags = { ...foundCu.sentenceTags.triggerCES[this.tagInd], ...triggerCESTags, hasNestedTags: true };
          foundCu.sentenceTags.triggerCES[this.tagInd] = triggerCESTags;
        }
        this.selectedEntity.querySentence = JSON.stringify({
          physical: physicalTags,
          information: informationTags,
          triggerCES: triggerCESTags,
        });
        this.entityFacadeService.saveMainEntity(this.selectedEntity);
        if (indexToAdd != -1) {
          foundCu.tfId = foundCu.tfReferencedChangeUnit;
          foundCu.id = foundCu.referencedChangeUnit;
          this.changeunitFacade.updateChangeUnit(foundCu, false);
        }
      }
    });
  }
  saveEntity() {
    let entityToSave = this.selectedEntity.nslAttributes.find((attr: any) => attr.name == this.node.data.attr.name);
    this.entityFacadeService.saveEntity(
      entityToSave.generalEntity,
      false,
      this.cu,
      entityToSave?.nslAttributes,
      this.layerName,
      false
    );
  }
  saveEntityWithSubattribute() {
    this.saveEntity();
  }
  saveSentenceWithAvailableNestedEntity() {
    this.entityFacadeService.extractedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nested_tags: any) => {
      /* istanbul ignore else */
      if (this.savedEntity) {
        let fetchedEntity = this.savedEntity;
        this.selectedEntity.nslAttributes.forEach((attr: any) => {
          /* istanbul ignore else */
          if (attr.name == this.node.data.attr.name) {
            attr.generalEntity = fetchedEntity;
          }
        });
        this.entityFacadeService.saveNestedEntity(fetchedEntity, nested_tags, this.cu);
      }
    });
  }
  detectSaveEntity() {
    this.entityFacadeService.saveentityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res?.result) {
        this.savedEntity = res.result;
        let data = {
          entities: {
            physical: {},
          },
        };
        data['entities'][this.layerName] = nestedEntityPayload(res.result);
        this.entityFacadeService.extractNestedEntity(data);
      }
    });
  }
  detectUpdatedCU() {
    this.changeunitFacade.cuUpdatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res?.data) {
        res = res?.data;
        const index = this.gsiData.solutionLogic.findIndex((m: any) => m.tfReferencedChangeUnit === res.tfId);
        let currentCUData = this.gsiData.solutionLogic[index];
        currentCUData.sentenceTags = res.sentenceTags;
        currentCUData.referencedChangeUnit = JSON.parse(JSON.stringify(res.id));
        currentCUData.tfReferencedChangeUnit = JSON.parse(JSON.stringify(res.tfId));
        currentCUData.layers = JSON.parse(JSON.stringify(res.layers));
        this.gsiData.solutionLogic[index] = currentCUData;
        this.gsiFacade.saveGsi(this.gsiData);
      }
    });
  }

  /* For Default Values */
  addDefaultValuetoAttibute(attribute: any, value: any, isSingleDefault?: boolean) {
    if (isSingleDefault) {
      if (this.multiDefaultValue) {
        if (attribute?.defaultValue) {
          this.allDefaultValues = attribute?.allDefaultValues;
        }
        this.allDefaultValues.push(JSON.parse(JSON.stringify(value)));
        attribute['defaultValue'] = JSON.stringify(this.allDefaultValues);
        this.defaultOption = true;
      } else if (this.validateDefaultValue(attribute, value)) {
        this.allDefaultValues.push(JSON.parse(JSON.stringify(value)));
        if (this.allDefaultValues.length == 1) {
          attribute['defaultValue'] = this.allDefaultValues[0];
        } else {
          attribute['defaultValue'] = JSON.stringify(this.allDefaultValues);
        }
        this.defaultOption = true;
      }
    } else {
      attribute.attributeType.extendedProperties.sourceValues.push({
        TYPE: 'OptionValue',
        DATA: { actualValue: value, displayValue: value },
      });
    }
    // this.default[attribute.name] = this.allDefaultValues;
    attribute.allDefaultValues = this.allDefaultValues;
    this.allDefaultValues = [];
    this.sourceVal = '';
    attribute.DefaultSourceVal = '';
  }
  validateDefaultValue(attribute: any, value: any) {
    let uiControl = attribute.attributeType?.uiElement?.uiElement;
    let dataType = attribute.attributeType.type;
    let res = validateDefaultValue(
      this.dateTypeUiControls,
      uiControl,
      dataType,
      value,
      attribute.attributeType.extendedProperties.sourceValues,
      this.allDefaultValues
    );
    /* istanbul ignore next */
    if (res.message.length > 0) {
      res.message.forEach((msg: any) => {
        this.alertService.showToaster(msg.msg, '', msg.type);
      });
    }
    return res.isValid;
  }
  removeSourceValue(attribute: any, index: any) {
    attribute.attributeType.extendedProperties.sourceValues.splice(index, 1);
  }
  removeDefaultSourceValue(attribute: any, index?: any) {
    /* istanbul ignore next */
    attribute.allDefaultValues.splice(index, 1);
    attribute.defaultValue = JSON.stringify(attribute?.allDefaultValues);
    // this.defaultOption = false;
  }

  fileUploadUrl(event: any, attribute: any) {
    this.fileUrl.push(JSON.stringify(event));
    attribute.defaultValue = this.fileUrl[0];
  }

  deletedFileResponse(event: any, attribute: any) {
    this.fileUrl = event;
    attribute.defaultValue = this.fileUrl[0];
  }

  checkOfMultiDefaultValues(value: boolean, attribute: any) {
    this.multiDefaultValue = value;
    attribute.multiDefaultValue = value;
    attribute.attributeType.type = 'list';
    // this.attrDetails.patchValue({
    //   isMultiValue: value,
    // });
  }

  openEntityLibrary() {
    this.gsiFacade.openLibraryEntity(Librarytypes.Entity);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

