import { Component, Inject, OnInit } from '@angular/core';
import { TransactionFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subtransactional-cu-dialog',
  templateUrl: './subtransactional-cu-dialog.component.html',
  styleUrls: ['./subtransactional-cu-dialog.component.scss'],
})
export class SubtransactionalCuDialogComponent implements OnInit {
  labels: any;
  private unSubscribe = new Subject();
  field: any;
  constructor(
    private translator: TranslatorService,
    private transactionFacadeService: TransactionFacadeService,
    public dialogRef: MatDialogRef<SubtransactionalCuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: any,
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.field = this.dialog;
    /* istanbul ignore next */
    this.field = this.field?.data;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  upgrade() {
    //this.dialog.close();
    /* istanbul ignore next */
    let triggerCond = {
      triggerContextualID: this.field?.nslAttributeProperties?.triggerSubTransactionContextualId,
    };
    let data = {
      gsiId: Number(sessionStorage.innerGSIid),
      gsiMasterId: Number(sessionStorage.innerGSIid),
    };
    this.transactionFacadeService.saveSubtransaction(triggerCond);
    this.closeDialog();
    // this.transactionFacadeService.getListChangeUnitByGSIId(data, false, false, triggerCond
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
