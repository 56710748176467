import {
  Directive,
  HostListener,
  Renderer2,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { IAttributeStyle, ICardStyle, IStyle } from '@common-services';

@Directive({
  selector: '[attributeDesignConfig]',
})
export class AttributeDesignConfigureDirective implements OnChanges {
  htmlElement: HTMLElement;
  hoverColor: string;
  color: string = '';
  @Input() position: number;
  @Input() cardDisplay: string;
  @Input() styleAttribute: IAttributeStyle;
  @Input() index: any;
  @Input() isCard: boolean = false;
  @Input() isButton: boolean = false;
  @Input() tabSelected: string;
  @Input() styleGsi: ICardStyle = {
    color: '',
    hoverColor: '',
  };
  @Input() set isEdit(value: boolean) {
    if (value) {
      this.editStyle();
    }
  }
  @Input() set isDesign(value: boolean) {
    /*istanbul ignore next*/
    if (value) {
      if (!this.isButton) {
        this.htmlElement.style.background = 'none';
      }
    }
  }
  @Input() set _selectedDirectiveId(value: string) {
    /*istanbul ignore next*/
    if (value != this.directiveName) {
      this.isSelectedElement = false;
      this.htmlElement.style.border = 'none';
      this.htmlElement.style.outline = 'none';
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?._style) {
      if (this.isSelectedElement) {
        if (!this.isButton) {
          this.changeStyle(this._style);
        } else {
          this.buttonChangeStyle(this._style);
        }
      }
    }
    if (changes?.styleAttribute) {
      this.editStyle();
    }
    if (changes?.styleGsi) {
      this.editStyle();
    }
  }
  buttonChangeStyle(style: any) {
    switch (style.changedElementStyle) {
      case 'selectedColor':
        this.htmlElement.style.backgroundColor = style?.changedElementValue;
        this.styleGsi.color = style?.changedElementValue;
        break;
      case 'hoverBackgroundColor':
        this.styleGsi.hoverColor = style?.changedElementValue;
        break;
    }
  }
  changeStyle(style: any) {
    switch (style.changedElementStyle) {
      case 'fontFamily':
        this.htmlElement.style.fontFamily = style?.changedElementValue?.font;
        this.styleAttribute.fontFamily = style?.changedElementValue?.font;
        break;
      case 'fontSize':
        this.htmlElement.style.fontSize = style?.changedElementValue + 'px';
        this.styleAttribute.fontSize = style?.changedElementValue + 'px';
        break;
      case 'isBold':
        this.htmlElement.style.fontWeight = style?.changedElementValue ? 'bold' : 'normal';
        this.styleAttribute.isBold = style?.changedElementValue;
        break;
      case 'isItalic':
        this.htmlElement.style.fontStyle = style?.changedElementValue ? 'italic' : 'normal';
        this.styleAttribute.isItalic = style?.changedElementValue;
        break;
      case 'alignment':
        this.htmlElement.style.textAlign = style?.changedElementValue?.justify;
        this.styleAttribute.alignment = style?.changedElementValue?.justify;

        break;
      case 'selectedColor':
        this.htmlElement.style.color = style?.changedElementValue;
        this.styleAttribute.attrColor = style?.changedElementValue;

        break;
      case 'hoverBackgroundColor':
        this.styleAttribute.attHoverColor = style?.changedElementValue;
        break;
    }
  }

  @Input() _style: IStyle;
  isSelectedElement: boolean = false;
  @Output() emitSelectedElement: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitSelectedConditionElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitAddOnClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() emitInnerHtml = new EventEmitter();
  @Input() directiveId: number = -1;
  @Input() directiveName: 'attribute' | 'card' = 'attribute';
  static directiveConstant: number = 0;

  constructor(private rendrer: Renderer2, private element: ElementRef) {
    this.htmlElement = element.nativeElement;
  }
  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    event.stopPropagation();
    /*istanbul ignore next*/
    if (this.cardDisplay == 'design') {
      if (this.isSelectedElement === false) {
        this.htmlElement.style.border = '1px solid white';
        this.htmlElement.style.outline = '2px solid black';
        if (this.isCard) {
          this.htmlElement.style.border = '2px solid green';
        }
        this.isSelectedElement = true;
        this.emitSelecteddirectiveId();
      } else {
        this.isSelectedElement = false;
        this.htmlElement.style.border = 'none';
        this.htmlElement.style.outline = 'none';
      }
    } else if (this.cardDisplay == 'view') {
      this.emitAddOnClick.emit(event);
    }
  }
  emitSelecteddirectiveId(): void {
    this.emitSelectedElement.emit('attribute');
  }

  @Input() set _isCondition(value: any) {
    this.emitConditionValue(value);
  }

  @Input() set _htmlValue(value: string) {
    if (this.isSelectedElement) {
      this.htmlElement.innerHTML = value;
      this.emitInnerHtml.emit({ value: value, index: this.index });
    }
  }

  emitConditionValue(value: any) {
    if (this.isSelectedElement) {
      this.emitSelectedConditionElement.emit({ color: value, value: this.htmlElement });
    }
  }
  editStyle() {
    if (!this.isButton) {
      this.htmlElement.style.color = this.styleAttribute?.attrColor;
      this.htmlElement.style.fontFamily = this.styleAttribute?.fontFamily;
      this.htmlElement.style.fontSize = this.styleAttribute?.fontSize;
      this.htmlElement.style.fontWeight = this.styleAttribute?.isBold ? 'bold' : 'normal';
      this.htmlElement.style.fontStyle = this.styleAttribute?.isItalic ? 'italic' : 'normal';
      this.htmlElement.style.textAlign = this.styleAttribute?.alignment;
    } else {
      this.htmlElement.style.backgroundColor = this.styleGsi?.color;
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any): void {
    event.stopPropagation();
    if (!this.isButton) {
      if (this.styleAttribute?.attHoverColor) {
        this.htmlElement.style.color = this.styleAttribute?.attHoverColor;
      }
    } else {
      if (this.styleGsi?.color) {
        this.htmlElement.style.backgroundColor = this.styleGsi?.hoverColor;
      }
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: any): void {
    event.stopPropagation();
    if (!this.isButton) {
      this.htmlElement.style.color = this.styleAttribute?.attrColor;
    } else {
      this.htmlElement.style.backgroundColor = this.styleGsi?.color;
    }
  }
}