import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  EventsFacadeService,
  FieldConfig,
  TranslatorService,
  dependentAttributeValue,
  getAttributeValue,
  setAttributeValue,
  getUiControlCustomization,
  TransactionFacadeService,
  setErrorDependentAttribute,
} from '@common-services';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { DatePickerComponent } from 'ngx-datetime-picker';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

interface Day {
  name: Date;
}
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  styles: [
    // `
    //   .datedisplay {
    //     width: 100%;
    //     margin-top: 15px;
    //   }
    // `,
  ],
})
export class DateComponent implements OnInit, OnDestroy, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  minDate: Date;
  maxDate: Date;
  myFilter: any;
  dateTime: Date;
  dateVal: any;
  formattedValue: any;
  formData: any;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  dateChanged: Subject<any> = new Subject();
  requiredFormat: string = 'MM/dd/yyyy';
  requiredFormatPrime: string = 'mm/dd/yy';
  slash1 = 2;
  slash2 = 5;
  validationList: any[] = [
    'onorafterValidation',
    'afterValidation',
    'onorbeforeValidation',
    'beforeValidation',
    'equalTo',
    'notequalTo',
    'pastorpresent',
    'present',
    'past',
    'future',
    'presentorfuture',
    'pastorfuture',
  ];
  @ViewChild('dateFormatter') updateDateInput: DatePickerComponent;
  private ngUnsubscribe = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  public dateExample: any = null;
  isDisabled: boolean = true;
  mobile: boolean;
  scrWidth: number;
  buttonElement: HTMLElement;
  rightSideOffset: boolean = false;
  flagForChange: boolean = false;
  parentHeight: number;
  flagForDateChange: boolean = true;
  level: number;
  labels: any;
  foundObject: any;
  appliedClass: string = 'ui-custom-calendar ui-custom-date';
  isMulti = false;
  tempValue: any = null;
  invalidDate: boolean = false;
  disabledDays: any = [];
  bodyAppend: string = 'body';
  disableSpecificDate: Date[];
  days: Day[] = [];
  selectedDay: Day;
  calendarDateClicked: boolean = false;
  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    public dialog: MatDialog,
    private dateAdapter: DateAdapter<any>,
    private rendrer: Renderer2,
    public transactionFacadeService: TransactionFacadeService
  ) {
    this.detectLanguageChange();
  }
  ngAfterViewInit() {
    // this.updateDateInput.offClick = (event: any) => {
    //   this.rendrer.addClass(document.body, 'expand-table-date');
    //   this.updateDateInput.offClick(event);
    // };
    // if (this.field?.type == 'currentdate') {
    //   //@ts-ignore
    //   this.updateDateInput.eRef.nativeElement.getElementsByClassName('ngx-picker')[0].style.pointerEvents = 'none';
    // }
    //@ts-ignore
    this.buttonElement = this.updateDateInput?.eRef?.nativeElement?.getElementsByTagName(
      'button'
    )[0];

    this.updateDateFormat();
    /*istanbul ignore next*/
    if (this.updateDateInput?.isMobile) {
      this.isDisabled = false;
      this.mobile = this.updateDateInput?.isMobile;
    }
    //@ts-ignore
    const rect = this.updateDateInput?.eRef?.nativeElement.getBoundingClientRect();
    /* istanbul ignore next */
    if (this.scrWidth - rect?.right <= 370) {
      this.rightSideOffset = true;
    }
  }
  updateDateFormat() {
    //@ts-ignore
    if (
      this.updateDateInput &&
      this.updateDateInput?.input?.nativeElement?.value != '' &&
      !this.mobile
    ) {
      //@ts-ignore
      const stringArray = this.updateDateInput?.input?.nativeElement?.value.split(
        '/'
      );
      if (this.requiredFormat === 'dd/MM/yyyy') {
        //@ts-ignore
        this.updateDateInput.input.nativeElement.value =
          stringArray[1] + '/' + stringArray[0] + '/' + stringArray[2];
      } else if (this.requiredFormat === 'yyyy/MM/dd') {
        //@ts-ignore
        this.updateDateInput.input.nativeElement.value =
          stringArray[2] + '/' + stringArray[0] + '/' + stringArray[1];
      }
    }
  }

  closeFix(event: any) {
    /* istanbul ignore next */
    if ((<any>document.querySelector('ngx-date'))?.hidden == false) {
      document.body.classList.add('expand-table1');
    } else if ((<any>document.querySelector('ngx-date'))?.hidden == true) {
      document.body.classList.remove('expand-table1');
    }
    /* istanbul ignore next */
    if (event.target.nodeName == 'NGX-DATE')
      this.updateDateInput?.setPickerVisible(false);
    if (this.flagForChange && !this.mobile && this.level == 1) {
      //@ts-ignore
      const height = this.updateDateInput?.eRef?.nativeElement?.offsetParent
        ?.offsetHeight;
      //@ts-ignore
      this.updateDateInput.eRef.nativeElement.offsetParent.style.height =
        this.parentHeight.toString() + 'px';
      this.flagForChange = false;
      this.flagForDateChange = true;
    } else if (this.flagForChange && !this.mobile && this.level == 2) {
      //@ts-ignore
      const height = this.updateDateInput?.eRef?.nativeElement?.offsetParent
        ?.offsetParent?.offsetHeight;
      //@ts-ignore
      this.updateDateInput.eRef.nativeElement.offsetParent.offsetParent.style.height =
        this.parentHeight.toString() + 'px';
      this.flagForChange = false;
      this.flagForDateChange = true;
    }
  }

  ngOnInit(): void {
    this.isMulti =
      this.field?.type == 'date' &&
      this.field?.isMulti &&
      !this.field?.configuration?.hideMultiValueIcon &&
      !(this.field?.attribute)['isTableConfig'];
    this.formData = this.eventsService.formData;
    let mccPopup = sessionStorage.getItem('template');
    this.bodyAppend = mccPopup?.includes('cupopup')
      ? this.field.attribute['isTableConfig'] || this.field.entity_configuration?.type == 'accordion' || this.field.nestedEntityName !== ''
        ? 'body'
        : ''
      : !this.foundObject?.event || this.foundObject?.event == ''
      ? 'body'
      : '';
    /* istanbul ignore next */
    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
    /* istanbul ignore next */
    if (this.field.type === 'date') {
      /* istanbul ignore next */
      if (this.field?.configuration?.dateTimeFormat?.format) {
        /* istanbul ignore next */
        this.formattedValue = this.field?.configuration?.dateTimeFormat?.format?.toUpperCase();
      }else if(!this.field.value) {
        this.formattedValue = this.requiredFormat;
      } else {
        /* istanbul ignore next */
        this.formattedValue = moment(this.field?.value).format(
          this.field?.attributeType?.properties?.format?.toUpperCase()
        );
      }
    }
    // For yearMonth Ui control
    if (this.field.type === 'yearmonth') {
      this.requiredFormat = 'yyyy-MM';
      if (this.field?.value) {
        this.formattedValue = moment(this.field?.value).format(
          this.field?.attributeType?.properties?.format?.toUpperCase()
        );
      } else if (this.field?.attributeType?.properties?.format) {
        this.formattedValue = this.field.attributeType.properties.format;
      }
    } else {
      this.requiredFormat = 'MM/dd/yyyy';
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.dateTimeFormat?.format) {
      this.requiredFormat = this.field?.configuration?.dateTimeFormat?.format;
    } else if (this.field?.attributeType?.properties?.format) {
      this.requiredFormat = this.field.attributeType.properties.format;
    }
    if (this.requiredFormat?.toLowerCase() === 'yyyy-mm-dd') {
      this.slash1 = 4;
      this.slash2 = 7;
    } else {
      this.slash1 = 2;
      this.slash2 = 5;
    }
    /* istanbul ignore next */
    this.requiredFormatPrime = this.eventsService.convertDateFormat(
      this.requiredFormat
    );
    console.log('requiredFormat: ', this.requiredFormat);
    console.log('requiredFormatPrime: ', this.requiredFormatPrime);
    /* istanbul ignore next */
    if (this.field.type === 'currentdate') {
      this.field.readonly = true;
      let currentDateTime = new Date().setHours(0, 0, 0, 0);
      this.field.value = new Date(currentDateTime);
      this.group.controls[this.field.attribute.name].setValue(
        new Date(currentDateTime)
      );
      /* istanbul ignore next */
      if (this.field?.configuration?.dateTimeFormat?.format) {
        /* istanbul ignore next */
        this.formattedValue = this.field?.configuration?.dateTimeFormat?.format?.toUpperCase();
      } else {
        /* istanbul ignore next */
        this.formattedValue = moment(this.field?.value).format(
          this.field?.attributeType?.properties?.format?.toUpperCase()
        );
      }
    }
    if (this.field?.configuration?.showDataAsTab) {
      for (
        let i =
          0 - this.field?.configuration?.dateTabConfig?.maxNumberOfPreDates;
        i <
        this.field?.configuration?.dateTabConfig?.style -
          this.field?.configuration?.dateTabConfig?.maxNumberOfPreDates;
        i++
      ) {
        let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        currentDate.setDate(currentDate.getDate() + i);
        this.days.push({ name: currentDate });
      }
    }
    /* istanbul ignore next */
    if (this.field?.value) {
      if (Array.isArray(this.field.value)) {
        if (this.field?.value.length) {
          let value: any = [];
          this.field.value.forEach((val: any) => {
            value.push(new Date(val));
          });
          this.field.value = value;
        } else {
          this.field.value = null;
        }
      } else {
        this.field.value = new Date(this.field.value);
      }
    } else {
      this.field.value = null;
    }
    this.tempValue = this.field.value;
    if (this.group?.controls)
      this.group.controls[this.field?.attribute?.name].setValue(
        this.field.value
      );
    this.languageChange();
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    if (
      this.field?.configuration?.disabledDays &&
      this.field?.configuration?.disabledDays?.length
    ) {
      this.disabledDays = this.field?.configuration?.disabledDays;
    }
    this.appliedClass = this.field?.attribute['isTableConfig']
      ? 'datecalendar-input'
      : 'ui-custom-calendar ui-custom-date';
    this.scrWidth = window.innerWidth;
    let dateOption = getUiControlCustomization('DatePicker');
    if (dateOption) {
      this.foundObject = { event: dateOption };
      let op = dateOption.slice(-1);
      if (dateOption == 'Option 7') {
        op = 1;
      }
      this.appliedClass = `form-custom-date form-custom-date-opt${op}`;
    }
  }
  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(
          Validators.compose(validList)
        );
        this.group?.controls[
          this.field?.attribute?.name
        ]?.updateValueAndValidity();
      }
    }
  }
  openDatePicker() {
    if (
      !document
        .querySelector('.mat-datepicker-content')
        ?.classList.contains('custom-year-date')
    ) {
      document
        .querySelector('.mat-datepicker-content')
        ?.classList.add('custom-year-date');
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
        this.languageChange();
      });
  }
  languageChange() {
    this.dateAdapter.setLocale(localStorage.locale);
  }

  setMinAndMaxDate(type: any, obj: any) {
    /* istanbul ignore next */
    let min, max, date;
    max = min = this.getAfterDate(obj, this.field) || new Date(obj?.value);
    switch (type) {
      case 'onorafterValidation':
        /* istanbul ignore else */
        if (min) {
          this.minDate = min;
          this.minDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'afterValidation':
        /* istanbul ignore else */
        if (min != undefined) {
          date = new Date(min);
          date?.setDate(date?.getDate() + 1);
          this.minDate = date;
          this.minDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'onorbeforeValidation':
        /* istanbul ignore else */
        if (max) {
          this.maxDate = max;
          this.maxDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'beforeValidation':
        /* istanbul ignore else */
        if (max != undefined) {
          date = new Date(max);
          date?.setDate(date?.getDate() - 1);
          this.maxDate = date;
          this.maxDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'equalTo':
        /* istanbul ignore else */
        if (max != undefined) {
          this.maxDate = max;
        }
        /* istanbul ignore else */
        if (min != undefined) {
          this.minDate = min;
        }
        this.minDate?.setHours(0, 0, 0, 0);
        this.maxDate?.setHours(0, 0, 0, 0);
        break;
      case 'pastorpresent':
        this.maxDate = new Date();
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(
            new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        break;

      case 'present':
        this.maxDate = this.minDate = new Date();
        break;

      case 'past':
        let maxdateObj = new Date();
        maxdateObj.setDate(maxdateObj.getDate() - 1);
        this.maxDate = maxdateObj;
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(
            new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        break;

      case 'future':
        let dateObj = new Date();
        dateObj.setHours(0, 0, 0, 0);
        dateObj.setDate(dateObj.getDate() + 1);
        this.minDate = dateObj;
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(
            new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;

      case 'presentorfuture':
        let dateMin = new Date();
        dateMin.setHours(0, 0, 0, 0);
        this.minDate = dateMin;
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(
            new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;

      case 'pastorfuture':
        // this.myFilter = (d: Date): boolean => {
        //   const day = this.dateToEpoch(d);
        //   const today = this.dateToEpoch(new Date());
        //   return day !== today;
        // };
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(
            new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(
            new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000
          );
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;
    }
  }

  dateToEpoch(date: any) {
    return date.setHours(0, 0, 0, 0);
  }

  validateDependentExpressionDate(validation: any) {
    let min: Date,
      max: Date,
      flag = false;
    max = min =
      this.getAfterDate(validation, this.field) || new Date(validation?.value);
    if (max) {
      min.setHours(0, 0, 0, 0);
      max.setHours(0, 0, 0, 0);
    }
    if (
      this.group?.controls[this.field?.attribute.name]?.value != null &&
      this.group?.controls[this.field?.attribute.name]?.value != undefined &&
      this.group?.controls[this.field?.attribute.name]?.value != ''
    ) {
      switch (validation.name) {
        case 'onorafterValidation':
        case 'afterValidation':
          /* istanbul ignore else */
          if (min != undefined) {
            if (
              this.group?.controls[
                this.field?.attribute?.name
              ]?.value?.getTime() < min?.getTime()
            ) {
              setErrorDependentAttribute(validation, this.field, this.group);
              flag = true;
            }
          }
          break;
        case 'onorbeforeValidation':
        case 'beforeValidation':
          /* istanbul ignore else */
          if (max != undefined) {
            if (
              this.group?.controls[
                this.field?.attribute?.name
              ]?.value?.getTime() > max?.getTime()
            ) {
              setErrorDependentAttribute(validation, this.field, this.group);
              flag = true;
            }
          }
          break;
        case 'equalTo':
          /* istanbul ignore next */
          if (
            this.group?.controls[
              this.field?.attribute?.name
            ]?.value?.getTime() != max?.getTime()
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;
        case 'notequalTo':
          this.disableSpecificDate = [new Date(min)];
          if (
            this.group?.controls[
              this.field?.attribute?.name
            ]?.value?.getTime() == this.disableSpecificDate[0]?.getTime()
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;
        case 'pastorpresent':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate ||
            this.group?.controls[this.field?.attribute?.name]?.value >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;

        case 'present':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate ||
            this.group?.controls[this.field?.attribute?.name]?.value >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;

        case 'past':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate ||
            this.group?.controls[this.field?.attribute?.name]?.value >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;

        case 'future':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate ||
            this.group?.controls[this.field?.attribute?.name]?.value >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;

        case 'presentorfuture':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate  ||
            this.group?.controls[this.field?.attribute?.name]?.value  >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;

        case 'pastorfuture':
          if (
            this.group?.controls[this.field?.attribute?.name]?.value <
              this.minDate ||
            this.group?.controls[this.field?.attribute?.name]?.value >
              this.maxDate
          ) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
          break;
      }
    }
    this.group?.status === 'INVALID'
      ? this.transactionFacadeService.disableSubmitButtonFlag.next(true)
      : this.transactionFacadeService.disableSubmitButtonFlag.next(false);
    return flag;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return this.validateDependentExpressionDate(validation);
    }
    /* istanbul ignore next */
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return this.validateDependentExpressionDate(validation);
    }
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value)
        .toString()
        .match(validation.requiredPattern)
        ? false
        : true;
    }
  }

  remove(i: number, event: any) {
    this.field.value.splice(i, 1);
    this.registerOnChange(event, true);
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected date value
   */
  onDateChange(event: any) {
    if (!this.field.isHidden) {
      if (this.field?.type == 'currentdate') {
        this.field.value = event?.value;
      }
      this.tempValue = this.field.value;
      /* istanbul ignore next */
      this.group.controls[this.field?.attribute?.name].setValue(
        this.field.value
      );
      // this.dateChanged.next(); // commented to stop form re loading
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore next */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
      if (
        //@ts-ignore
        this.updateDateInput?.input?.nativeElement?.style?.opacity ||
        //@ts-ignore
        this.updateDateInput?.input?.nativeElement?.style?.opacity == ''
      ) {
        //@ts-ignore
        this.updateDateInput.input.nativeElement.style.opacity = 0;
      }
      setTimeout(() => {
        this.updateDateFormat();
        //@ts-ignore
        if (this.updateDateInput?.input?.nativeElement?.style?.opacity) {
          //@ts-ignore
          this.updateDateInput.input.nativeElement.style.opacity = '';
        }
      }, 500);
    }
  }
  onBlur(event?: any) {
    this.calendarDateClicked = false;
    this.invalidDate =
      this.field.value &&
      this.field.value?.length != 10 &&
      !moment(
        this.field.value,
        this.requiredFormat?.toUpperCase(),
        true
      ).isValid();
    let currentDate: any = new Date(event.currentTarget.value);
    if (JSON.stringify(this.field.value) != JSON.stringify(this.tempValue)) {
      this.registerOnChange(this.field.value, true);
    }
    if (!isNaN(Date.parse(currentDate))) {
      this.group.controls[this.field.attribute.name].setValue(currentDate);
    }
  }
  keyPress(event: any) {
    var currDate = event.target.value;
    if (event.key) {
      var numRegex = /[0-9]|[\/]/;
      if (!numRegex.test(event.key)) {
        event.preventDefault();
        return;
      }
      if (
        (event.key == '/' &&
          currDate.length != this.slash1 &&
          currDate.length != this.slash2) ||
        currDate.length > 9
      ) {
        event.preventDefault();
        return;
      }
    }
  }
  inputChange(event: any) {
    var currDate = event.currentTarget.value;
    if (event.data && event.data != '/') {
      if (currDate.length == this.slash1) {
        event.currentTarget.value = currDate + '/';
      } else if (currDate.length == this.slash2) {
        event.currentTarget.value = currDate + '/';
      }
    }
    this.invalidDate =
      currDate?.length === 10 &&
      !moment(currDate, this.requiredFormat.toUpperCase(), true).isValid();
  }
  registerOnChange(event: any, newDate?: boolean) {
    this.calendarDateClicked = false;
    if (!event || event == '' || !newDate) {
      return;
    } else {
      this.onDateChange({ value: event });
    }
    if (!newDate && this.flagForChange && !this.mobile && this.level == 1) {
      //@ts-ignore
      const height = this.updateDateInput.eRef.nativeElement.offsetParent
        .offsetHeight;
      //@ts-ignore
      this.updateDateInput.eRef.nativeElement.offsetParent.style.height =
        this.parentHeight.toString() + 'px';
      this.flagForChange = false;
      this.flagForDateChange = false;
    } else if (
      !newDate &&
      this.flagForChange &&
      !this.mobile &&
      this.level == 2
    ) {
      //@ts-ignore
      const height = this.updateDateInput.eRef.nativeElement.offsetParent
        .offsetParent.offsetHeight;
      //@ts-ignore
      this.updateDateInput.eRef.nativeElement.offsetParent.offsetParent.style.height =
        this.parentHeight.toString() + 'px';
      this.flagForChange = false;
      this.flagForDateChange = false;
    }
  }
  // For yearMonth Ui control
  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.field.value = normalizedMonth;
    /* istanbul ignore next */
    this.group?.controls[this.field?.attribute?.name]?.setValue(
      normalizedMonth
    );
    /* istanbul ignore next */
    datepicker?.close();
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  getAfterDate(object: any, field: any) {
    let dE = object?.dependentExpression;
    let formData = this.eventsService?.formData;
    /* istanbul ignore else */
    if (dE) {
      setAttributeValue();
      dependentAttributeValue(dE, formData, false, field);
      return new Date(getAttributeValue());
    }
  }

  selectedDate(day: Day) {
    this.selectedDay = day;
    this.group.controls[this.field.attribute.name].setValue(new Date(day.name));
    this.onDateChange({ value: day });
  }

  dateClicked(event: any, newDate?: boolean) {
    if (!newDate) {
      //@ts-ignore
      if (
        !this.mobile && //@ts-ignore
        this.updateDateInput?.eRef?.nativeElement?.offsetParent?.tagName ==
          'MAT-TAB-BODY' &&
        this.field.type !== 'currentdate'
      ) {
        this.level = 1;
        //@ts-ignore
        const top = this.updateDateInput.eRef.nativeElement.offsetTop;
        if (!this.parentHeight) {
          //@ts-ignore
          this.parentHeight = this.updateDateInput.eRef.nativeElement.offsetParent.offsetHeight;
        }
        if (this.flagForDateChange && this.parentHeight - top < 400) {
          this.flagForChange = true;
          //@ts-ignore
          this.updateDateInput.eRef.nativeElement.offsetParent.style.height =
            (top + 440).toString() + 'px';
        }
        this.flagForDateChange = true;
      }
      //@ts-ignore
      else if (
        !this.mobile && //@ts-ignore
        this.updateDateInput?.eRef?.nativeElement?.offsetParent?.offsetParent
          ?.tagName == 'MAT-TAB-BODY' &&
        this.field.type !== 'currentdate'
      ) {
        this.level = 2;
        //@ts-ignore
        const top = this.updateDateInput.eRef.nativeElement.offsetParent
          .offsetTop;
        if (!this.parentHeight) {
          //@ts-ignore
          this.parentHeight = this.updateDateInput.eRef.nativeElement.offsetParent.offsetParent.offsetHeight;
        }
        if (this.flagForDateChange && this.parentHeight - top < 400) {
          this.flagForChange = true;
          //@ts-ignore
          this.updateDateInput.eRef.nativeElement.offsetParent.offsetParent.style.height =
            (top + 440).toString() + 'px';
        }
        this.flagForDateChange = true;
      }
      event.stopPropagation();
    }

    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }

    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
    this.calendarDateClicked = true;
  }
  saveDateData(event: any) {
    let index = this.field?.entityName?.lastIndexOf('$');
    let entityName = this.field?.entityName?.slice(0, index);
    /* istanbul ignore else */
    if (entityName) {
      entityName = entityName + '.' + this.field?.name;
      this.eventsService?.dateMap?.set(entityName, event?.value);
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
