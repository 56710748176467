import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit, OnChanges {
  @Input() text: any;
  @Input() height: any;
  @Input() color: string = '#3875ff';
  @ViewChild('readMore') readMore: any;
  isCollapsed: boolean = false;
  isCollapsable: boolean = false;
  heightOfDiv: number;
  heightAfterView: string;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    var doc = new DOMParser().parseFromString(this.text, 'text/html');
    this.text = this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
    setTimeout(() => {
      this.computeHeight();
    }, 100);
  }
  computeHeight() {
    const font = window.getComputedStyle(this.readMore.nativeElement).font;
    const heightInPx = window.getComputedStyle(this.readMore.nativeElement).height;
    let lineHeight: any = '';
    if (font && heightInPx) {
      this.heightOfDiv = parseInt(heightInPx?.split('p')[0]);
      lineHeight = parseInt(font?.split(' ')[2]?.split('p')[0]);
      this.heightAfterView = this.heightOfDiv.toString() + 'px';
    }

    if (this.heightOfDiv > this.height) {
      this.height = Math.floor(this.height / lineHeight) * lineHeight - lineHeight;
      this.heightAfterView = this.height.toString() + 'px';
      this.isCollapsable = true;
      this.isCollapsed = false;
    }
    this.readMore.nativeElement.style.opacity = 1;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.heightAfterView = this.heightOfDiv.toString() + 'px';
    } else {
      this.heightAfterView = this.height.toString() + 'px';
    }
  }
}
